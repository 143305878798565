import React, { useState } from "react";
import CustomiserUnit from "../../../../../components/CustomiserUnit";
import PaywallElement from "../../../../../components/PaywallElement";
import Select from "@atlaskit/select";
import { langOptions } from "../../../../../utilities/variables";
import { useWorkspace } from "../../../../../contexts/WorkspaceContext";
import { Link } from "react-router-dom";
import Textfield from "@atlaskit/textfield";

/**
 * Customise the language settings of the widget
 * @component
 * @param {Object} values - current values inside the form
 * @param {Function} setFieldValue - method to set values in each field
 * @param {string} currentDefaultLang - value of the current default language
 */
export default function MiniCartLanguageSettings({
  values,
  setFieldValue,
  currentDefaultLang,
}) {
  const workspace = useWorkspace();
  const langArray = Object.keys(values.corner_cart_lang);
  // to remove "version" from language array
  const filteredLangArray = langArray.filter(
    (arrayElement) => arrayElement !== "version"
  );

  const [selectedLanguage, setSelectedLanguage] = useState(currentDefaultLang);

  const handleDefaultValueChange = (e) => {
    setSelectedLanguage(e.value);
  };

  return (
    <>
      <div className="-mx-8 p-2 desktop:px-8 desktop:py-3 border-b">
        <p className="font-bold mb-1 desktop:mb-4 text-center ">
          Select Language to Edit
        </p>
        <div className="flex justify-center">
          <Select
            isSearchable={false}
            value={langOptions.find(
              (option) => option.value === selectedLanguage
            )}
            onChange={handleDefaultValueChange}
            className="w-48 mr-2"
            classNamePrefix="react-select"
            options={langOptions.filter((item) =>
              filteredLangArray.includes(item.value)
            )}
          />
        </div>
        <p className="text-sm  text-center my-2 desktop:mt-4">
          <Link to={`/settings/language`} className="text-violet-700">
            {" "}
            Click here
          </Link>{" "}
          to add/edit the language list{" "}
        </p>
      </div>
      {!workspace.data?.feature_flag.apps?.cornercart
        ?.multi_language_support ? (
        <PaywallElement paywallPlan={13} title="Upgrade Now" description="Subscribe to a higher plan to edit various texts inside the widget" />
      ) : (
        <div>
          <CustomiserUnit
            title="Cart Title"
            description="The text displayed as the title of the cart"
          >
            <Textfield
              value={
                values.corner_cart_lang[`${selectedLanguage}`]?.cart_context_title
              }
              onChange={(e) => {
                setFieldValue(
                  `corner_cart_lang["${selectedLanguage}"].cart_context_title`,
                  e.target.value
                );
              }}
              name="basic"
            />
          </CustomiserUnit>

          <CustomiserUnit
            title="Cart Close Text"
            description="The text displayed for closing the cart"
          >
            <Textfield
              value={
                values.corner_cart_lang[`${selectedLanguage}`]?.widget_close_text
              }
              onChange={(e) => {
                setFieldValue(
                  `corner_cart_lang["${selectedLanguage}"].widget_close_text`,
                  e.target.value
                );
              }}
              name="basic"
            />
          </CustomiserUnit>

          <CustomiserUnit
            title="Cart Total Text"
            description="The text displayed beside the total value of the cart"
          >
            <Textfield
              value={values.corner_cart_lang[`${selectedLanguage}`]?.total_text}
              onChange={(e) => {
                setFieldValue(
                  `corner_cart_lang["${selectedLanguage}"].total_text`,
                  e.target.value
                );
              }}
              name="basic"
            />
          </CustomiserUnit>

          <CustomiserUnit
            title="Cart CTA Text"
            description="The text displayed inside the CTA Button"
          >
            <Textfield
              value={
                values.corner_cart_lang[`${selectedLanguage}`]?.cart_list_cta
              }
              onChange={(e) => {
                setFieldValue(
                  `corner_cart_lang["${selectedLanguage}"].cart_list_cta`,
                  e.target.value
                );
              }}
              name="basic"
            />
          </CustomiserUnit>

          <CustomiserUnit
            title="Related Items Title"
            description="The text displayed as the title of 'Related Items' section"
          >
            <Textfield
              value={
                values.corner_cart_lang[`${selectedLanguage}`]?.related_items_title
              }
              onChange={(e) => {
                setFieldValue(
                  `corner_cart_lang["${selectedLanguage}"].related_items_title`,
                  e.target.value
                );
              }}
              name="basic"
            />
          </CustomiserUnit>

          <CustomiserUnit
            title="Related Items CTA Text"
            description="Call to action for adding a related item to cart"
          >
            <Textfield
              value={
                values.corner_cart_lang[`${selectedLanguage}`]?.related_items_cta
              }
              onChange={(e) => {
                setFieldValue(
                  `corner_cart_lang["${selectedLanguage}"].related_items_cta`,
                  e.target.value
                );
              }}
              name="basic"
            />
          </CustomiserUnit>

          <CustomiserUnit
            title="Empty Cart Text"
            description="The text to display when the cart is empty"
          >
            <Textfield
              value={
                values.corner_cart_lang[`${selectedLanguage}`]?.cart_empty_state
              }
              onChange={(e) => {
                setFieldValue(
                  `corner_cart_lang["${selectedLanguage}"].cart_empty_state`,
                  e.target.value
                );
              }}
              name="basic"
            />
          </CustomiserUnit>


          <CustomiserUnit
            title="Order Note Button Text"
            description="The text displayed on the button to open the Order Note Popup"
          >
            <Textfield
              value={
                values.corner_cart_lang[`${selectedLanguage}`]?.optional_note_btn_text
              }
              onChange={(e) => {
                setFieldValue(
                  `corner_cart_lang["${selectedLanguage}"].optional_note_btn_text`,
                  e.target.value
                );
              }}
              name="basic"
            />
          </CustomiserUnit>

          <CustomiserUnit
            title="Go to Cart Button Text"
            description="The text displayed on the button to open thecart page"
          >
            <Textfield
              value={
                values.corner_cart_lang[`${selectedLanguage}`]?.cart_list_go_to_cart_page
              }
              onChange={(e) => {
                setFieldValue(
                  `corner_cart_lang["${selectedLanguage}"].cart_list_go_to_cart_page`,
                  e.target.value
                );
              }}
              name="basic"
            />
          </CustomiserUnit>
          <CustomiserUnit
            title="Continue Shopping Button Text"
            description="The text displayed on the button displayed below cta button which closes the cart widget"
          >
            <Textfield
              value={
                values.corner_cart_lang[`${selectedLanguage}`]?.cart_list_continue_shopping
              }
              onChange={(e) => {
                setFieldValue(
                  `corner_cart_lang["${selectedLanguage}"].cart_list_continue_shopping`,
                  e.target.value
                );
              }}
              name="basic"
            />
          </CustomiserUnit>

          <CustomiserUnit
            title="Order Note Popup Title"
            description="The text displayed as the title of the 'Order Note' popup"
          >
            <Textfield
              value={
                values.corner_cart_lang[`${selectedLanguage}`]?.optional_note_title
              }
              onChange={(e) => {
                setFieldValue(
                  `corner_cart_lang["${selectedLanguage}"].optional_note_title`,
                  e.target.value
                );
              }}
              name="basic"
            />
          </CustomiserUnit>

          <CustomiserUnit
            title="Order Note Popup CTA"
            description="The text displayed as the CTA on the 'Order Note' popup"
          >
            <Textfield
              value={
                values.corner_cart_lang[`${selectedLanguage}`]?.optional_note_cta
              }
              onChange={(e) => {
                setFieldValue(
                  `corner_cart_lang["${selectedLanguage}"].optional_note_cta`,
                  e.target.value
                );
              }}
              name="basic"
            />
          </CustomiserUnit>

          <CustomiserUnit
            title="Order Note Popup Cancel"
            description="The text displayed in the 'Order Note' Cancel button"
          >
            <Textfield
              value={
                values.corner_cart_lang[`${selectedLanguage}`]?.optional_note_cancel
              }
              onChange={(e) => {
                setFieldValue(
                  `corner_cart_lang["${selectedLanguage}"].optional_note_cancel`,
                  e.target.value
                );
              }}
              name="basic"
            />
          </CustomiserUnit>


          
        </div>
      )}
    </>
  );
}
