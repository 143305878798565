import React, { useState } from "react";
import { useWorkspace } from "../contexts/WorkspaceContext";
import Button from "./Button";
import CocaPricingModal from "./CocaPricingModal";

/**
 * An UI element for paywalling
 * @component
 * @param {Number} paywallPlan - Which plan to show on the paywall
 * @param {String} title - The title to show under the paywall
 * @param {String} description - The mesage to show under the paywall
 * @param {"horizontal"||"vertical"} type - the layout style
 */
const PaywallElement = ({ paywallPlan, title, description, type }) => {
  const [isBillingModalOpen, setIsBillingModalOpen] = useState(false);
  const workspace = useWorkspace();
  const availablePlans = workspace.data.allAvailablePlans;
  const requiredPlan =
    paywallPlan &&
    availablePlans &&
    availablePlans.find((plan) => plan.id === paywallPlan);

  const renderPricingModal = () => {
    return (
      <CocaPricingModal
        hightlightPlan={13}
        title={`Upgrade to a higher plan to unlock this feature`}
        isOpen={isBillingModalOpen}
        onClose={() => setIsBillingModalOpen(false)}
      />
    );
  };

  const formatPrice = (price) => {
    var priceAsString = price.toString();
    var splitPrice = priceAsString.split(".");
    return (
      <div className="flex  leading-none">
        <span className="text-lg self-center">$</span>
        <span className="text-3xl m-0 font-extrabold self-center">
          {splitPrice[0]}
        </span>
        <span className="text-lg self-end text-sm">
          {splitPrice[1] && `.${splitPrice[1]}`}
        </span>
      </div>
    );
  };

  return (
    <>
      <div className="flex justify-center">
        <div
          className={`${
            type === "horizontal" && "flex"
          } m-3 rounded w-92 rounded-lg overflow-hidden border `}
        >
          <div className="flex justify-center bg-violet-100 ">
            <div className="text-center text-gray-700 p-6 ">
              {requiredPlan && formatPrice(requiredPlan.billing_amount)}
              <div className="text-xs m-0">/month</div>
            </div>
          </div>
          <div
            className={` ${
              type === "horizontal" ? "text-left" : "text-center"
            }  p-3`}
          >
            <p className="font-bold text-base ">{title ? title : " Upgrade"}</p>
            <p className="text-base text-gray-600 ">
              {description
                ? description
                : " Subscribe to a higher plan to unlock this feature"}
            </p>
            <div
              className={` ${
                type === "horizontal" ? "justify-start" : "justify-center"
              }  flex
            mt-3`}
            >
              <Button
                type="primary"
                onClick={() => setIsBillingModalOpen(true)}
              >
                Upgrade
              </Button>
            </div>
          </div>
        </div>
      </div>
      {paywallPlan && renderPricingModal()}
    </>
  );
};

export default PaywallElement;
