import React, { useState } from "react";
import { ResourcePicker } from "@shopify/app-bridge/actions";
import { useAppBridge } from "@shopify/app-bridge-react";
import Button from "./Button";
/**
 * Shopify Product Picker Component
 * @component
 * @param {Array} products - list of already selected products
 * @param {Function} onChange - function passed to handle the onChange of product picker
 * @returns Shopify Product Picker
 */
const ShopifyProductPicker = ({ products = [], onChange }) => {
  const app = useAppBridge();

  const productPicker = ResourcePicker.create(app, {
    resourceType: ResourcePicker.ResourceType.Product,
    options: {
      selectMultiple: true,
      showHidden: false,
      showVariants: false,
      initialSelectionIds: products,
    },
  });

  const openProductPicker = async () => {
    await productPicker.dispatch(ResourcePicker.Action.OPEN);
  };

  // const closePicker = productPicker.dispatch(ResourcePicker.Action.CANCEL);

  productPicker.subscribe(ResourcePicker.Action.SELECT, ({ selection }) => {
    console.log("selection", selection);
    if (onChange) {
      onChange(selection);
    }
  });

  return (
    <div>
      <Button onClick={() => openProductPicker()}>Select Products</Button>
    </div>
  );
};

export default ShopifyProductPicker;
