import React, { useState } from "react";
import Button from "./Button";
import Textfield from "@atlaskit/textfield";

/**
 * Component for collecting a single URL
 * @param {string} value - initial URL value if any
 * @param {function} updateProdutHandle - method to update the product handle
 * @param {function} removeURL - method to remove the URL
 */
const SingleProductURLCollector = ({
  value,
  updateProdutHandle,
  removeURL,
}) => {
  const [urlValue, seturlValue] = useState("");

  const handleHomePageUrl = () => {
    try {
      const url = new URL(urlValue);
      if (url) {
        if (url.pathname.includes("/product")) {
          const productHandle = url.pathname.substring(
            url.pathname.lastIndexOf("/") + 1
          );
          seturlValue("");
          updateProdutHandle(productHandle);
        } else {
          window.alert("Please enter valid product url");
        }
      }
    } catch (err) {
      console.log(err);
      window.alert("Invalid URL");
    }
  };

  return value ? (
    <div className="flex items-center">
      <div className="mr-6 w-full">
        <p className="text-tiny">{value}</p>
      </div>
      <Button onClick={() => removeURL()} type="ghost" className="min-w-max">
        Remove
      </Button>
    </div>
  ) : (
    <div className="flex items-center">
      <div className="mr-6 w-full">
        <Textfield
          value={urlValue}
          onChange={(e) => seturlValue(e.target.value)}
          name="basic"
          placeholder="Product URL to showcase"
        />
      </div>
      <Button onClick={handleHomePageUrl} type="ghost" className="min-w-max">
        Add URL
      </Button>
    </div>
  );
};

export default SingleProductURLCollector;
