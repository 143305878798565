import React, { useState, useEffect } from "react";
import { useQuery, useMutation, useQueryCache } from "react-query";
import {
  RiFileWarningFill,
  RiEyeFill,
  RiEyeOffFill,
  RiTranslate,
  RiPaletteFill,
  RiListSettingsFill,
  RiUser4Fill,
  RiNotificationBadgeFill,
  RiSpeedMiniFill,
} from "react-icons/ri";
import Button from "../../../../components/Button";
import TabRadioButton from "../../../../components/TabRadioButton";
import {
  queryWidgetSettings,
  updateWidgetSettings,
} from "../../../../api/widgets";
import toaster from "toasted-notes";
import { useGraphQl } from "../../../../contexts/GraphqlClient";
import PageViewContainer from "../../../../components/structure/PageViewContainer";
import HorizontalTabs from "../../../../components/HorizontalTabs";
import MiniCartOpenSettings from "./OpenSettings";
import MiniCartAdvancedSettings from "./AdvancedSettings";
import MiniCartCloseSettings from "./CloseSettings";
import MiniCartColorSettings from "./ColorSettings";
import { Formik } from "formik";
import WidgetPreview from "../WidgetPreview";
import MiniCartLanguageSettings from "./LanguageSettings";

//convert screen sticky bar
export default function MiniCart(props) {
  const graphQL = useGraphQl();
  const [currentTab, setCurrentTab] = useState("colors");
  // Manually change the following state to true in code to access the theme creatorMode. In them,e creator mode you'll get a botton to export the theme JSOn to console
  const [themeCreatorMode, setThemeCreatorMode] = useState(false);
  const [initialValues, setInitialValues] = useState();

  const queryCache = useQueryCache();
  const { data, refetch } = useQuery("queryWidgetSettings", () =>
    graphQL(queryWidgetSettings)
  );

  const updateWidget = async (values) => {
    await graphQL(updateWidgetSettings, {
      widgetSettingsId: values.id,
      widgetSettingsInput: values.config,
    });
    refetch();
  };

  const [updateWidgetMutation] = useMutation(updateWidget, {
    onSuccess: () => {
      queryCache.invalidateQueries("queryWidgetSettings");
      toaster.notify("Widget settings saved successfully", {
        duration: 2000,
      });
    },
  });

  const WidgetStates = [
    {
      label: (
        <div className="flex items-center">
          <RiEyeFill className="flex-none  mr-2 text-lg" /> Widget Enabled{" "}
        </div>
      ),
      value: true,
    },
    {
      label: (
        <div className="flex items-center">
          <RiEyeOffFill className="flex-none mr-2 text-lg" /> Widget Disabled{" "}
        </div>
      ),
      value: false,
    },
  ];

  const handleIsActiveChange = (value) => {
    updateWidgetMutation({
      id: { id: data.widgets_cornerwidget[0].id },
      config: {
        cart_config: {
          ...data.widgets_cornerwidget[0].cart_config,
          general: {
            ...data.widgets_cornerwidget[0].cart_config.general,
            isActive: value,
          },
        },
      },
    });
  };

  const renderCurrentTab = (values, setFieldValue) => {
    switch (currentTab) {
      case "colors":
        return (
          <MiniCartColorSettings
            values={values}
            setFieldValue={setFieldValue}
          />
        );
      case "open":
        return (
          <MiniCartOpenSettings values={values} setFieldValue={setFieldValue} />
        );
      case "close":
        return (
          <MiniCartCloseSettings
            values={values}
            setFieldValue={setFieldValue}
          />
        );
      case "advanced":
        return (
          <MiniCartAdvancedSettings
            values={values}
            setFieldValue={setFieldValue}
          />
        );
      case "language":
        return (
          <MiniCartLanguageSettings
            values={values}
            setFieldValue={setFieldValue}
            currentDefaultLang={data?.workspaces[0]?.source?.default_language}
          />
        );
    }
  };

  const tabItems = [
    { label: "Edit Colors", id: "colors", icon: <RiPaletteFill /> },
    { label: "Closed State", id: "close", icon: <RiUser4Fill /> },
    { label: "Opened State", id: "open", icon: <RiNotificationBadgeFill /> },
    {
      label: "Advanced Settings",
      id: "advanced",
      icon: <RiListSettingsFill />,
    },
    { label: "Language Settings", id: "language", icon: <RiTranslate /> },
  ];

  useEffect(() => {
    if (data) setInitialValues(data.widgets_cornerwidget[0]);
  }, [data]);

  return (
    <PageViewContainer
      title="Cart Widget"
      action={
        <div className="flex items-center">
          {/* <p className="mr-3 font-bold text-base ">Widget Status : </p> */}
          {data && (
            <TabRadioButton
              defaultValue={
                data.widgets_cornerwidget[0].cart_config.general.isActive
              }
              options={WidgetStates}
              onChange={(selectedValue) => {
                handleIsActiveChange(selectedValue);
              }}
            />
          )}
        </div>
      }
    >
      <div className="flex flex-col h-full">
        {data && !data.widgets_cornerwidget[0]?.cart_config?.general?.isActive && (
          <div className="bg-amber-300 text-amber-800 text-center py-2 flex items-center justify-center">
            <RiFileWarningFill className="text-xl mr-3" />
            <p className="text-sm">
              The widget is currently in draft mode. Publish it on your store
              from the drop down menu (top right)
            </p>
          </div>
        )}
        <HorizontalTabs
          items={tabItems}
          showSeperatorIcons
          onChange={(selectedTab) => {
            setCurrentTab(selectedTab);
          }}
        />
        {initialValues && (
          <Formik
            enableReinitialize
            initialValues={initialValues}
            onSubmit={(values) => {
              updateWidgetMutation({
                id: { id: values.id },
                config: {
                  cart_config: values.cart_config,
                  cart_launcher_config: values.cart_launcher_config,
                  corner_cart_lang: values.corner_cart_lang,
                },
              });
            }}
          >
            {({ values, dirty, submitForm, setFieldValue, resetForm }) => {
              return (
                <>
                  {dirty && (
                    <div className="z-30 sticky top-0 flex justify-between border-b bg-amber-100 items-center py-3 desktop:px-8 px-4 ">
                      <p>You've unsaved changes</p>
                      <div className="flex items-center">
                        <Button
                          onClick={() => {
                            resetForm();
                          }}
                          type="link"
                        >
                          Discard Changes
                        </Button>
                        <Button
                          onClick={() => {
                            submitForm();
                          }}
                          type="primary"
                        >
                          Save Changes
                        </Button>
                        {/* The following button is only for dev purpose. Specifically for widget theme creation */}
                        {themeCreatorMode && (
                          <Button
                            className="ml-3"
                            onClick={() => {
                              console.log("themeValue", {
                                name: "",
                                type: "light",
                                themeObject: {
                                  colors: values.cart_config.colors,
                                  banner: values.cart_config.banner,
                                  customCss:
                                    values.cart_config.general.customCss,
                                },
                              });
                            }}
                            type="outline"
                          >
                            get Theme Values
                          </Button>
                        )}
                      </div>
                    </div>
                  )}
                  <div className=" h-full flex flex-row items-stretch min-h-0">
                    <div className="desktop:max-w-lg laptop:max-w-md mobile:w-full px-8 overflow-y-auto pb-12">
                      <div>{renderCurrentTab(values, setFieldValue)}</div>
                    </div>
                    <div className="grow flex-wrap h-full bg-gray-100 border-l mobile:hidden laptop:flex items-center justify-center overflow-y-auto">
                      <div className=" origin-top scale-60 desktop:scale-70">
                        <WidgetPreview
                          cartConfig={values.cart_config}
                          cartLauncherConfig={values.cart_launcher_config}
                          cornerCartWidgetLang={values.corner_cart_lang}
                        />
                      </div>
                    </div>
                  </div>
                </>
              );
            }}
          </Formik>
        )}
      </div>
    </PageViewContainer>
  );
}
