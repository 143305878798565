import React from "react";
import CustomiserUnit from "../../../../components/CustomiserUnit";
import Textfield from "@atlaskit/textfield";
import { Formik } from "formik";
import Button from "../../../../components/Button";

const SEOOptions = (props) => {
  const initialValues = props.value;

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      onSubmit={(values) => {
        let output = {
          seo_title: values.seo_title,
          seo_description: values.seo_description,
        };
        props.onSubmit(output);
        props.onClose();
      }}
    >
      {(formikProps) => {
        return (
          <div className="p-6">
            <div className="pb-8">
              <h1 className="mb-2"> SEO Settings</h1>
              <p>You can customize the SEO settings for this article here.</p>
            </div>
            <div>
              <CustomiserUnit
                title="Meta Title"
                description="The SEO meta title for this article"
              >
                <Textfield
                  id="seo_title"
                  placeholder="SEO Title"
                  autoFocus={true}
                  name="basic"
                  defaultValue={initialValues.seo_title}
                  onChange={(e) => {
                    formikProps.setFieldValue("seo_title", e.target.value);
                  }}
                />
              </CustomiserUnit>
              <CustomiserUnit
                title="Meta Description"
                description="The SEO meta description for this article. Upto 160 characters."
              >
                <Textfield
                  id="seo_description"
                  placeholder="SEO Description"
                  autoFocus={true}
                  name="basic"
                  defaultValue={initialValues.seo_description}
                  onChange={(e) => {
                    formikProps.setFieldValue(
                      "seo_description",
                      e.target.value
                    );
                  }}
                />
              </CustomiserUnit>
            </div>
            <div className="flex justify-end items-center">
              <Button
                className="ml-3"
                type="link"
                onClick={() => {
                  formikProps.resetForm();
                  props.onClose();
                }}
              >
                {" "}
                Discard changes
              </Button>
              <Button
                className="ml-3"
                type="primary"
                onClick={() => formikProps.submitForm()}
              >
                {" "}
                Save
              </Button>
            </div>
          </div>
        );
      }}
    </Formik>
  );
};

export default SEOOptions;
