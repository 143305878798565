import React, { useState } from "react";
import TakeOverDrawer from "../../../../../../components/TakeOverDrawer";
import CustomiserUnit from "../../../../../../components/CustomiserUnit";
import CocaPricingModal from "../../../../../../components/CocaPricingModal";
import Button from "../../../../../../components/Button";
import { useWorkspace } from "../../../../../../contexts/WorkspaceContext";
import { RiCodeSSlashFill, RiCheckboxCircleFill,RiLinkM } from "react-icons/ri";

import { Controlled } from "react-codemirror2";
import "codemirror/lib/codemirror.css";
import "codemirror/theme/duotone-light.css";
import "codemirror/theme/material.css";
require("codemirror/theme/neat.css");
require("codemirror/mode/javascript/javascript");
require("codemirror/mode/gfm/gfm");
require("codemirror/mode/htmlmixed/htmlmixed");
require("codemirror/mode/xml/xml");
require("codemirror/lib/codemirror");

/**
 * Customise the color palette of the widget
 * @component
 * @param {Object} values - values required to render the initial preview
 */
export default function ScriptEditor({ values, setFieldValue }) {
  const workspace = useWorkspace();
  const [isScriptEditorVisible, setIsScriptEditorVisible] = useState(false);
  const [isBillingModalOpen, setIsBillingModalOpen] = useState(false);
  const [tempValue, setTempValue] = useState(
    values.stickybar_config.general.customJs ||
      "// Write your Javascript code here"
  );

  return (
    <>
      {/* Theme Picker */}
      <CustomiserUnit
        paywall={!workspace.data?.feature_flag.apps?.cornercart?.dev_options}
        paywallPlan={13}
        title={<span className="flex"> Add Custom JS Scripts</span>}
        description="Customise the behaviour of sticky bar further with Javascript"
      >
        <Button
          onClick={() => {
            setIsScriptEditorVisible(!isScriptEditorVisible);
          }}
          type="ghost"
          className="mt-3"
          icon={<RiCodeSSlashFill />}
        >
          Open Corner Script Editor
        </Button>
      </CustomiserUnit>
      <TakeOverDrawer
        title={
          <div className="flex items-center">
            <RiCodeSSlashFill />
            <span className="ml-3 flex">Script Editor</span>
          </div>
        }
        actions={
          <div className="flex items-center">
            <Button
              onClick={() => {
                setIsScriptEditorVisible(!isScriptEditorVisible);
              }}
              className="mr-2"
              type="ghost"
            >
              Cancel
            </Button>

            <Button
              onClick={() => {
                setFieldValue("stickybar_config.general.customJs", tempValue);
                setIsScriptEditorVisible(!isScriptEditorVisible);
              }}
              icon={<RiCheckboxCircleFill />}
            >
              Apply & Continue
            </Button>
          </div>
        }
        isOpen={isScriptEditorVisible}
        onClose={() => {
          setIsScriptEditorVisible(!isScriptEditorVisible);
        }}
      >
        <div className="h-full flex flex-row items-stretch min-h-0">
          <div className="grow flex-wrap h-full bg-gray-100 border-l flex border-r items-center justify-center overflow-y-auto ">
            <Controlled
              value={tempValue}
              options={{
                mode: "javascript",
                lineNumbers: true,
                highlightFormatting: true,
                theme: "duotone-light",
              }}
              onBeforeChange={(editor, data, value) => {
                console.log(value);
                setTempValue(value);
              }}
              className=" cursor-text border-gray-400 w-full h-full  grow bg-gray-100 overflow-hidden"
            />
          </div>
          <div className="hidden laptop:block desktop:max-w-xl laptop:max-w-md overflow-y-auto pb-12 ">
            <h2 className="flex items-center text-primary-500 font-bold px-6 desktop:px-8 py-5 border-b">
              <RiCodeSSlashFill className="mr-2" /> Script Editor Docs
            </h2>
            <div className="px-6 py-6 desktop:px-8">
              <h3 className=" font-bold">How it works</h3>
              <p className="mt-3">
                The Javascript code you write here will be imported while
                rendering the Cart Widget. You can write the Javascript code
                directly you dont have to write inside teh script tag. The code
                you add here will be automatically removed if you uninstall our
                app
              </p>
            </div>
            <div className="px-6 border-t desktop:px-8">
              <h2 className="mt-3 font-bold text-center text-primary-500">
                Web SDK: Corner Functions
              </h2>
              <p className="mt-3">
                Our Web SDK is a set of Javascript functions that let you
                control the Cart Widget from your JavaScript code. This lets you
                listen to various events, as well as open, close the cart or do
                fancy stuff with the it.
              </p>
              <a
                target="_blank"
                className="mt-5 border p-3 rounded-lg hover:border-primary-500 text-primary-500 font-bold flex items-center"
                href="https://help.cornercart.io/en/article/how-to-use-web-sdk-1oa6ivv/?bust=1659090275215"
              >
                {" "}
                <RiLinkM className="mr-3" />
                Learn More about our Web SDK functions
              </a>
            </div>
          </div>
        </div>
      </TakeOverDrawer>
      <CocaPricingModal
        hightlightPlan={13}
        title={`Upgrade to a higher plan to unlock Pro themes`}
        isOpen={isBillingModalOpen}
        onClose={() => setIsBillingModalOpen(false)}
      />
    </>
  );
}
