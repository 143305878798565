import React, { useState } from "react";
import { RiFileCopyLine } from "react-icons/ri";
import { useWorkspace } from "../../../../../../contexts/WorkspaceContext";
import Select from "@atlaskit/select";
import Textfield from "@atlaskit/textfield";
import { Checkbox } from "@atlaskit/checkbox";
import TabRadioButton from "../../../../../../components/TabRadioButton";
import CustomiserUnit from "../../../../../../components/CustomiserUnit";
import ColorPicker from "../../../../../../components/ColorPicker";
import PaywallElement from "../../../../../../components/PaywallElement";

/**
 * Component for rendering Convert Screen Sticky Bar Design Settings
 * @param {object} values - values passed from formik
 * @param {function} setFieldValue - method to update the formik values object
 */
export default function StickyBarDesignSettings({ values, setFieldValue }) {
  const workspace = useWorkspace();
  const [viewState, setViewState] = useState("desktop");

  const convertRGB = (rgb) => `rgb(${rgb.r},${rgb.g},${rgb.b})`;

  const stickyBarPostionOptions = [
    { label: "Top", value: "top" },
    { label: "Bottom", value: "bottom" },
  ];

  const visibilityOptions = [
    { label: "Show", value: true },
    { label: "Hide", value: false },
  ];

  const buttonAnimationOptions = [
    { label: "None", value: "none" },
    { label: "Pulse", value: "pulse" },
    { label: "Shake", value: "shake" },
  ];

  /**
   * A UI Component for copy style from desktop to mobile
   * @param {[object]} targetFormikStringArr - array of formik strings which needed to be equalised
   */
  const StyleEqualiser = ({ targetFormikStringArr }) => {
    return (
      <>
        {workspace.data?.feature_flag.apps?.cornercart?.stickybar
          .stickybar_mobile_specific_customisations && (
          <div className="flex items-center transition-all mb-2">
            <span className={`mr-2 text-primary-500`}>
              <RiFileCopyLine />
            </span>

            <button
              className="text-sm text-primary-500"
              onClick={() => {
                targetFormikStringArr.forEach((item) => {
                  item.targets.forEach((formikField) => {
                    setFieldValue(formikField, item.value);
                  });
                });
              }}
            >
              Apply the changes to{" "}
              {viewState === "desktop" ? "mobile" : "desktop"} settings also
            </button>
          </div>
        )}
      </>
    );
  };

  const selectedDeviceValue =
    viewState === "desktop"
      ? values.stickybar_config.design.desktop
      : values.stickybar_config.design.mobile;

  return (
    <>
      <div className="flex border-b">
        <button
          onClick={() => setViewState("desktop")}
          className={`flex-1 desktop:text-base text-sm text-center desktop:py-4 py-3 focus:outline-none ${
            viewState === "desktop" &&
            "border-b-2 border-violet-700 text-violet-700 font-bold"
          } `}
        >
          Desktop
        </button>
        <button
          onClick={() => setViewState("mobile")}
          className={`flex-1 desktop:text-base text-sm  text-sm text-center desktop:py-4 py-3 focus:outline-none ${
            viewState === "mobile" &&
            "border-b-2 border-violet-700 text-violet-700 font-bold"
          } `}
        >
          Mobile
        </button>
      </div>

      <div className="flex grow flex-col">
        {/* Visibility */}
        <CustomiserUnit
          title={"Visibility"}
          description={`Show or hide the Add to CartBar on ${viewState}`}
        >
          {values.stickybar_config.design.desktop.isActive !==
            values.stickybar_config.design.mobile.isActive && (
            <StyleEqualiser
              targetFormikStringArr={[
                {
                  targets: [
                    "stickybar_config.design.desktop.isActive",
                    "stickybar_config.design.mobile.isActive",
                  ],
                  value: selectedDeviceValue.isActive,
                },
              ]}
            />
          )}
          <TabRadioButton
            defaultValue={selectedDeviceValue.isActive}
            options={visibilityOptions}
            onChange={(selectedValue) => {
              setFieldValue(
                viewState === "desktop"
                  ? "stickybar_config.design.desktop.isActive"
                  : "stickybar_config.design.mobile.isActive",
                selectedValue
              );
            }}
          />
        </CustomiserUnit>

        {workspace.data?.feature_flag.apps?.cornercart?.stickybar
          .stickybar_mobile_specific_customisations === false &&
        viewState === "mobile" ? (
          <PaywallElement
            paywallPlan={13}
            title="Upgrade Now"
            description="Subscribe to a higer plan to unlock more mobile specific customisations"
          />
        ) : (
          <>
            {/*  Position */}
            <CustomiserUnit
              title="Position"
              description={`Position of the Add to CartBar on ${viewState}`}
            >
              {values.stickybar_config.design.desktop.position !==
                values.stickybar_config.design.mobile.position && (
                <StyleEqualiser
                  targetFormikStringArr={[
                    {
                      targets: [
                        "stickybar_config.design.desktop.position",
                        "stickybar_config.design.mobile.position",
                      ],
                      value: selectedDeviceValue.position,
                    },
                  ]}
                />
              )}
              <TabRadioButton
                defaultValue={selectedDeviceValue.position}
                options={stickyBarPostionOptions}
                onChange={(selectedValue) => {
                  setFieldValue(
                    viewState === "desktop"
                      ? "stickybar_config.design.desktop.position"
                      : "stickybar_config.design.mobile.position",
                    selectedValue
                  );
                }}
              />
            </CustomiserUnit>
            {/* Animation */}
            <CustomiserUnit
              title="Call to Action Button Animation"
              description="Choose an animation for your call to action button on mobile"
            >
              {values.stickybar_config.design.desktop.ctaButtonAnimation !==
                values.stickybar_config.design.mobile.ctaButtonAnimation && (
                <StyleEqualiser
                  targetFormikStringArr={[
                    {
                      targets: [
                        "stickybar_config.design.desktop.ctaButtonAnimation",
                        "stickybar_config.design.mobile.ctaButtonAnimation",
                      ],
                      value: selectedDeviceValue.ctaButtonAnimation,
                    },
                  ]}
                />
              )}
              <Select
                isSearchable={false}
                value={buttonAnimationOptions.find(
                  (option) =>
                    option.value === selectedDeviceValue.ctaButtonAnimation
                )}
                onChange={(e) => {
                  setFieldValue(
                    viewState === "desktop"
                      ? "stickybar_config.design.desktop.ctaButtonAnimation"
                      : "stickybar_config.design.mobile.ctaButtonAnimation",
                    e.value
                  );
                }}
                className="w-full"
                classNamePrefix="react-select"
                options={buttonAnimationOptions}
              />
            </CustomiserUnit>
            {/* Sticky Bar Color */}
            <CustomiserUnit
              title="Sticky Bar Color"
              description="Select the color to match your brand's identity"
            >
              {values.stickybar_config.design.desktop.colors.cardBgColor !==
                values.stickybar_config.design.mobile.colors.cardBgColor ||
              values.stickybar_config.design.desktop.colors.cardFgColor !==
                values.stickybar_config.design.mobile.colors.cardFgColor ? (
                <StyleEqualiser
                  targetFormikStringArr={[
                    {
                      targets: [
                        "stickybar_config.design.desktop.colors.cardBgColor",
                        "stickybar_config.design.mobile.colors.cardBgColor",
                      ],
                      value: selectedDeviceValue.colors.cardBgColor,
                    },
                    {
                      targets: [
                        "stickybar_config.design.desktop.colors.cardFgColor",
                        "stickybar_config.design.mobile.colors.cardFgColor",
                      ],
                      value: selectedDeviceValue.colors.cardFgColor,
                    },
                  ]}
                />
              ) : null}
              <div className="flex flex-wrap border  rounded-lg py-3">
                <div className="max-w-sm flex border-r  px-4 items-center w-1/2 ">
                  <ColorPicker
                    color={selectedDeviceValue.colors.cardBgColor}
                    onChange={(selectedColor) =>
                      setFieldValue(
                        viewState === "desktop"
                          ? "stickybar_config.design.desktop.colors.cardBgColor"
                          : "stickybar_config.design.mobile.colors.cardBgColor",
                        convertRGB(selectedColor.rgb)
                      )
                    }
                  />
                  <p className="ml-3 text-sm desktop:text-base">Background Color</p>
                </div>
                <div className="max-w-sm flex px-4 items-center w-1/2">
                  <ColorPicker
                    color={selectedDeviceValue.colors.cardFgColor}
                    onChange={(selectedColor) =>
                      setFieldValue(
                        viewState === "desktop"
                          ? "stickybar_config.design.desktop.colors.cardFgColor"
                          : "stickybar_config.design.mobile.colors.cardFgColor",
                        convertRGB(selectedColor.rgb)
                      )
                    }
                  />
                  <p className="ml-3 text-sm desktop:text-base">Text Color </p>
                </div>
              </div>
            </CustomiserUnit>
            {/* CTA Color */}
            <CustomiserUnit
              title="Call to Action Button Color"
              description="Select the color to match your brand's identity"
            >
              {values.stickybar_config.design.desktop.colors.ctaBgColor !==
                values.stickybar_config.design.mobile.colors.ctaBgColor ||
              values.stickybar_config.design.desktop.colors.ctaFgColor !==
                values.stickybar_config.design.mobile.colors.ctaFgColor ? (
                <StyleEqualiser
                  targetFormikStringArr={[
                    {
                      targets: [
                        "stickybar_config.design.desktop.colors.ctaBgColor",
                        "stickybar_config.design.mobile.colors.ctaBgColor",
                      ],
                      value: selectedDeviceValue.colors.ctaBgColor,
                    },
                    {
                      targets: [
                        "stickybar_config.design.desktop.colors.ctaFgColor",
                        "stickybar_config.design.mobile.colors.ctaFgColor",
                      ],
                      value: selectedDeviceValue.colors.ctaFgColor,
                    },
                  ]}
                />
              ) : null}
              <div className="flex flex-wrap border  rounded-lg py-3">
                <div className="max-w-sm flex border-r  px-4 items-center w-1/2 ">
                  <ColorPicker
                    color={selectedDeviceValue.colors.ctaBgColor}
                    onChange={(selectedColor) =>
                      setFieldValue(
                        viewState === "desktop"
                          ? "stickybar_config.design.desktop.colors.ctaBgColor"
                          : "stickybar_config.design.mobile.colors.ctaBgColor",
                        convertRGB(selectedColor.rgb)
                      )
                    }
                  />
                  <p className="ml-3 text-sm desktop:text-base">Background Color</p>
                </div>
                <div className="max-w-sm flex px-4 items-center w-1/2">
                  <ColorPicker
                    color={selectedDeviceValue.colors.ctaFgColor}
                    onChange={(selectedColor) =>
                      setFieldValue(
                        viewState === "desktop"
                          ? "stickybar_config.design.desktop.colors.ctaFgColor"
                          : "stickybar_config.design.mobile.colors.ctaFgColor",
                        convertRGB(selectedColor.rgb)
                      )
                    }
                  />
                  <p className="ml-3 text-sm desktop:text-base">Text Color </p>
                </div>
              </div>
            </CustomiserUnit>
            {/* Oultine Clr */}
            <CustomiserUnit
              title="Product Image Outline Color"
              description="Select the color to match your brand's identity"
            >
              {values.stickybar_config.design.desktop.colors.imgOutlineColor !==
                values.stickybar_config.design.mobile.colors
                  .imgOutlineColor && (
                <StyleEqualiser
                  targetFormikStringArr={[
                    {
                      targets: [
                        "stickybar_config.design.desktop.colors.imgOutlineColor",
                        "stickybar_config.design.mobile.colors.imgOutlineColor",
                      ],
                      value: selectedDeviceValue.colors.imgOutlineColor,
                    },
                  ]}
                />
              )}
              <div className="flex justify-center flex-wrap border  rounded-lg py-3">
                <div className="max-w-sm flex px-4 items-center  ">
                  <ColorPicker
                    color={selectedDeviceValue.colors.imgOutlineColor}
                    onChange={(selectedColor) =>
                      setFieldValue(
                        viewState === "desktop"
                          ? "stickybar_config.design.desktop.colors.imgOutlineColor"
                          : "stickybar_config.design.mobile.colors.imgOutlineColor",
                        convertRGB(selectedColor.rgb)
                      )
                    }
                  />
                  <p className="ml-3 text-sm desktop:text-base">Image Outline Color</p>
                </div>
              </div>
            </CustomiserUnit>
            {/* Elements Visibility */}
            <CustomiserUnit
              title="Elements Visibility"
              description="Select elements to display on the Add to Cart Bar"
            >
              {JSON.stringify(
                values.stickybar_config.design.desktop.elements
              ) !==
                JSON.stringify(
                  values.stickybar_config.design.mobile.elements
                ) && (
                <StyleEqualiser
                  targetFormikStringArr={[
                    {
                      targets: [
                        "stickybar_config.design.desktop.elements",
                        "stickybar_config.design.mobile.elements",
                      ],
                      value: selectedDeviceValue.elements,
                    },
                  ]}
                />
              )}
              <div className="flex flex-wrap">
                {/* Product image */}
                <div className="w-3/6">
                  <Checkbox
                    onChange={(e) => {
                      setFieldValue(
                        viewState === "desktop"
                          ? "stickybar_config.design.desktop.elements.productImg"
                          : "stickybar_config.design.mobile.elements.productImg",
                        e.target.checked
                      );
                    }}
                    label="Product Image"
                    name="checkbox-basic"
                    isChecked={selectedDeviceValue.elements.productImg}
                  />
                </div>
                {/* Product Title */}
                <div className="w-3/6">
                  <Checkbox
                    onChange={(e) => {
                      setFieldValue(
                        viewState === "desktop"
                          ? "stickybar_config.design.desktop.elements.productName"
                          : "stickybar_config.design.mobile.elements.productName",
                        e.target.checked
                      );
                    }}
                    label="Product Title"
                    name="checkbox-basic"
                    isChecked={selectedDeviceValue.elements.productName}
                  />
                </div>
                {/* Product Price */}
                <div className="w-3/6">
                  <Checkbox
                    onChange={(e) => {
                      setFieldValue(
                        viewState === "desktop"
                          ? "stickybar_config.design.desktop.elements.price"
                          : "stickybar_config.design.mobile.elements.price",
                        e.target.checked
                      );
                    }}
                    label="Price"
                    name="checkbox-basic"
                    isChecked={selectedDeviceValue.elements.price}
                  />
                </div>
                {/* Compare at price */}
                <div className="w-3/6">
                  <Checkbox
                    onChange={(e) => {
                      setFieldValue(
                        viewState === "desktop"
                          ? "stickybar_config.design.desktop.elements.compareAtPrice"
                          : "stickybar_config.design.mobile.elements.compareAtPrice",
                        e.target.checked
                      );
                    }}
                    label="Compare at price"
                    name="checkbox-basic"
                    isChecked={selectedDeviceValue.elements.compareAtPrice}
                  />
                </div>
                {/* Qty */}
                <div className="w-3/6">
                  <Checkbox
                    onChange={(e) => {
                      setFieldValue(
                        viewState === "desktop"
                          ? "stickybar_config.design.desktop.elements.quantity"
                          : "stickybar_config.design.mobile.elements.quantity",
                        e.target.checked
                      );
                    }}
                    label="Quantity"
                    name="checkbox-basic"
                    isChecked={selectedDeviceValue.elements.quantity}
                  />
                </div>
                {/* Variants */}
                <div className="w-3/6">
                  <Checkbox
                    onChange={(e) => {
                      setFieldValue(
                        viewState === "desktop"
                          ? "stickybar_config.design.desktop.elements.variants"
                          : "stickybar_config.design.mobile.elements.variants",
                        e.target.checked
                      );
                    }}
                    label="Variants"
                    name="checkbox-basic"
                    isChecked={selectedDeviceValue.elements.variants}
                  />
                </div>
                {/* variantsLabel */}
                <div className="w-3/6">
                  <Checkbox
                    onChange={(e) => {
                      setFieldValue(
                        viewState === "desktop"
                          ? "stickybar_config.design.desktop.elements.variantsLabel"
                          : "stickybar_config.design.mobile.elements.variantsLabel",
                        e.target.checked
                      );
                    }}
                    label="Variants Label"
                    name="checkbox-basic"
                    isChecked={selectedDeviceValue.elements.variantsLabel}
                  />
                </div>
                <div className="w-3/6">
                  <Checkbox
                    onChange={(e) => {
                      setFieldValue(
                        viewState === "desktop"
                          ? "stickybar_config.design.desktop.elements.priceOnCta"
                          : "stickybar_config.design.mobile.elements.priceOnCta",
                        e.target.checked
                      );
                    }}
                    label="Price on CTA buton"
                    name="checkbox-basic"
                    isChecked={selectedDeviceValue.elements.priceOnCta}
                  />
                </div>
                <div className="w-3/6">
                  <Checkbox
                    onChange={(e) => {
                      setFieldValue(
                        viewState === "desktop"
                          ? "stickybar_config.design.desktop.elements.compareAtPriceOnCta"
                          : "stickybar_config.design.mobile.elements.compareAtPriceOnCta",
                        e.target.checked
                      );
                    }}
                    label="Compare at Price on CTA buton"
                    name="checkbox-basic"
                    isChecked={selectedDeviceValue.elements.compareAtPriceOnCta}
                  />
                </div>
              </div>
            </CustomiserUnit>
            {/* Scroll depth */}
            <CustomiserUnit
              title="Page Scroll Depth"
              description="Customize how far down the page the user needs to scroll down, for the Add To Cart Bar to appear"
            >
              {values.stickybar_config.design.desktop.scrollDepth !==
                values.stickybar_config.design.mobile.scrollDepth && (
                <StyleEqualiser
                  targetFormikStringArr={[
                    {
                      targets: [
                        "stickybar_config.design.desktop.scrollDepth",
                        "stickybar_config.design.mobile.scrollDepth",
                      ],
                      value: selectedDeviceValue.scrollDepth,
                    },
                  ]}
                />
              )}
              <div className="w-2/5">
                <Textfield
                  value={selectedDeviceValue.scrollDepth}
                  onChange={(e) => {
                    setFieldValue(
                      viewState === "desktop"
                        ? "stickybar_config.design.desktop.scrollDepth"
                        : "stickybar_config.design.mobile.scrollDepth",
                      e.target.value
                    );
                  }}
                  name="basic"
                  className="w-3/6"
                  type="number"
                  placeholder="1px"
                />
              </div>
            </CustomiserUnit>
            {/* Z-index */}
            <CustomiserUnit
              title="Z-index"
              description="Controls z-index of the Add to Cart Widget. A website element with a higher z-index is always shown in front of an element with lower z-index"
            >
              {values.stickybar_config.design.desktop.zIndex !==
                values.stickybar_config.design.mobile.zIndex && (
                <StyleEqualiser
                  targetFormikStringArr={[
                    {
                      targets: [
                        "stickybar_config.design.desktop.zIndex",
                        "stickybar_config.design.mobile.zIndex",
                      ],
                      value: selectedDeviceValue.zIndex,
                    },
                  ]}
                />
              )}
              <div className="w-2/5">
                <Textfield
                  value={selectedDeviceValue.zIndex}
                  onChange={(e) => {
                    setFieldValue(
                      viewState === "desktop"
                        ? "stickybar_config.design.desktop.zIndex"
                        : "stickybar_config.design.mobile.zIndex",
                      e.target.value
                    );
                  }}
                  name="basic"
                  className="w-40"
                  type="number"
                  placeholder="99999"
                />
              </div>
            </CustomiserUnit>
          </>
        )}
      </div>
    </>
  );
}
