import React, { useState } from "react";
import { RiQuestionFill, RiHandCoinFill } from "react-icons/ri";
import { useWorkspace } from "../../../../contexts/WorkspaceContext";
import Tooltip from "@atlaskit/tooltip";

const ProfitCalculator = (props) => {
  const workspace = useWorkspace();
  const [numberOfReferrers, setNumberOfReferrers] = useState(10);
  const [friendCount, setFriendCount] = useState(5);
  // Initially Average Order Value is set as minOrderValue+20
  const [averageOrderValue, setAverageOrderValue] = useState(
    props.min_order_value + 20
  );

  // Discount value is always a -ve value thats why in the following formulas it is multiplied by -1
  let rewardDiscount =
    props.reward_config.rewardDimension === "percentage"
      ? ((props.reward_config.rewardValue * -1) / 100) * averageOrderValue
      : props.reward_config.rewardValue * -1;

  const giftDiscount =
    props.gift_config.discountType === "fixedAmount"
      ? friendCount * (props.gift_config.discountValue * -1)
      : friendCount *
        (((props.gift_config.discountValue * -1) / 100) * averageOrderValue);

  const totalGiftDiscount = numberOfReferrers * giftDiscount;

  const totalRewardDiscount = numberOfReferrers * rewardDiscount;

  const totalDiscountGiven = totalGiftDiscount + totalRewardDiscount;

  // Total Revenue = Revenue from referees + revenue from referrers (from the purchase they made to get the reward, this is only applicable if the reward type is coupon)
  const totalRevenueGenerated =
    props.reward_type === "coupon"
      ? friendCount * averageOrderValue * numberOfReferrers +
        numberOfReferrers * averageOrderValue
      : friendCount * averageOrderValue * numberOfReferrers;

  const profitGenerated = totalRevenueGenerated - totalDiscountGiven;

  //ROI
  const roi = (profitGenerated / totalDiscountGiven) * 100;
  const getCalculatedValues = {
    profitGenerated: profitGenerated.toFixed(2),
    totalDiscountGiven: totalDiscountGiven.toFixed(2),
    totalRevenueGenerated: totalRevenueGenerated.toFixed(2),
    roi: roi.toFixed(2),
  };

  const NumberInput = ({ setter, value, postfix }) => {
    return (
      <div className="inline-block border mx-1 desktop:mx-2 border-gray-300 bg-white rounded-lg my-1">
        <div className="flex item-center ">
          <button
            className="px-1 desktop:px-2 focus:outline-none hover:bg-gray-200 rounded-lg transition-all"
            onClick={() => {
              if (value > 1) setter(value - 1);
            }}
          >
            {" "}
            -{" "}
          </button>
          <span className="px-1 desktop:px-2 text-lg  font-bold">
            {value} <span className="text-xs">{postfix && postfix}</span>
          </span>
          <button
            className="px-1 desktop:px-2   focus:outline-none hover:bg-gray-200 rounded-lg transition-all"
            onClick={() => {
              setter(value + 1);
            }}
          >
            {" "}
            +{" "}
          </button>
        </div>
      </div>
    );
  };

  return (
    <div className="block  mt-4 border rounded-lg  bg-white w-max overflow-hidden ">
      <div className=" px-6 py-3 grow-0">
        <h2 className="font-bold mb-4 flex items-center">
          {" "}
          <RiHandCoinFill className="mr-2" /> Profit Calculator
        </h2>
        <p className="leading-10 text-tiny">
          Assuming your average order value is{" "}
          <NumberInput
            setter={(value) => setAverageOrderValue(value)}
            value={averageOrderValue}
          />
        </p>

        <p className="leading-10 text-tiny">
          and if
          <NumberInput
            setter={(value) => setNumberOfReferrers(value)}
            value={numberOfReferrers}
          />
          customers refer atleast{" "}
          <NumberInput
            setter={(value) => setFriendCount(value)}
            value={friendCount}
          />
          friend
          {friendCount > 1 && "s"}
        </p>
      </div>
      {averageOrderValue > props.min_order_value ? (
        <div className=" border border-violet-600 p-2 m-3 rounded-lg">
          <div className="flex ">
            <div className="text-gray-600 p-3  cursor-help">
              <div className="text-xs flex items-center">
                Revenue Generated
                <Tooltip
                  position="top"
                  content={props.reward_type === "coupon"?"Revenue from referers + Revenue referees":"Revenue from referees"}
                >
                  <span className="cursor-help text-xs ">
                    <RiQuestionFill />
                  </span>
                </Tooltip>
              </div>
              <div className="font-bold text-lg">
                {getCalculatedValues.totalRevenueGenerated}{" "}
                <span className="text-sm">{workspace.data.storeCurrency}</span>
              </div>
            </div>

            <div className=" p-3 text-gray-600 ">
              <div className="text-xs flex items-center ">
                Total Discounts Given
                <Tooltip
                  position="top"
                  content="Gift discounts + Referrer rewards"
                >
                  <span className="cursor-help text-xs ">
                    <RiQuestionFill />
                  </span>
                </Tooltip>
              </div>
              <div className="font-bold text-lg">
                {getCalculatedValues.totalDiscountGiven}{" "}
                <span className=" text-sm">{workspace.data.storeCurrency}</span>
              </div>
            </div>

            <div className=" p-3 text-gray-600 ">
              <div className="text-xs flex items-center ">
                Net Profit
                <Tooltip position="top" content="Revenue - Discounts given">
                  <span className="cursor-help text-xs ">
                    <RiQuestionFill />
                  </span>
                </Tooltip>
              </div>
              <div className="font-bold text-lg">
                {getCalculatedValues.profitGenerated}{" "}
                <span className=" text-sm">{workspace.data.storeCurrency}</span>
              </div>
            </div>
          </div>
          <div className="py-2 bg-violet-400 rounded-lg text-violet-800 font-bold text-center">
            <Tooltip position="top" content="Return on investment">
              <p className="text-sm cursor-help">
                ROI: <span>{getCalculatedValues.roi}</span>%
              </p>
            </Tooltip>
          </div>
        </div>
      ) : (
        <div className="flex items-center shrink-0 grow  mb-3 desktop:my-3 px-6">
          <div className="text-center p-4 rounded-lg bg-red-100 text-red-700 font-bold text-sm w-56">
            The average order value is less than the minimum order value (
            {props.min_order_value} {workspace.data.storeCurrency}) of this
            campaign
          </div>
        </div>
      )}
    </div>
  );
};

export default ProfitCalculator;
