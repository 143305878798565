import React, { useState, useEffect } from "react";
import { useQuery, useMutation } from "react-query";
import Select from "@atlaskit/select";
import Button from "../../../components/Button";
import {
  queryWidgetSettings,
  updateWidgetSettings,
} from "../../../api/widgets";
import { updateSourceDetails } from "../../../api/workspace";
import { useGraphQl } from "../../../contexts/GraphqlClient";
import { useWorkspace } from "../../../contexts/WorkspaceContext";
import { langOptions } from "../../../utilities/variables";

export default function LanguageSelector(props) {
  const workspace = useWorkspace();
  const [languageCode, setlanguageCode] = useState("en");
  const graphQL = useGraphQl();
  const { data } = useQuery("queryWidgetSettings", () =>
    graphQL(queryWidgetSettings)
  );

  const updateWidget = async (values) => {
    await graphQL(updateWidgetSettings, {
      widgetSettingsId: values.id,
      widgetSettingsInput: values.config,
    });
  };
  const updateOnboarding = async () => {
    await graphQL(updateSourceDetails, {
      sourceId: { id: workspace.data.sourceid },
      sourceInput: {
        onboarding_progress: 100,
        default_language: languageCode,
        translated_languages: [languageCode],
      },
    });
  };

  const [updateWidgetMutation] = useMutation(updateWidget);
  const [updateOnboardingProgressMutation] = useMutation(updateOnboarding, {
    onSuccess: () => {
      window.location.href = "/support/faq/page/";
    },
  });

  const initialLauncherMappingValues = {
    id: data && data.widgets_cornerwidget[0].id,
    launcherMaping:
      data && data.widgets_cornerwidget[0].launcher_config.launcherMaping,
    cornerButton:
      data && data.widgets_cornerwidget[0].launcher_config.cornerButton,
    sideTab: data && data.widgets_cornerwidget[0].launcher_config.sideTab,
  };

  const findInitialLauncherText = (textRequested) => {
    let faqText
    let offerText
    if (initialLauncherMappingValues.launcherMaping.assistant === "cornerButton")
    faqText = initialLauncherMappingValues.cornerButton.text;
    else if (initialLauncherMappingValues.launcherMaping.assistant === "sideTab")
    faqText = initialLauncherMappingValues.sideTab.text;
    else 
    faqText = "HelpDesk";

    if (initialLauncherMappingValues.launcherMaping.offers === "cornerButton")
    offerText = initialLauncherMappingValues.cornerButton.text;
    else if (initialLauncherMappingValues.launcherMaping.offers === "sideTab")
    offerText = initialLauncherMappingValues.sideTab.text;
    else
    offerText = "Offer";

    if (textRequested === "offers")
    return offerText
    else
    return faqText
  }


  const handleSubmit = async () => {
    let cornerLang =
      await require(`../../../i18n/default-values/corner-widget/${languageCode}.json`);
    let faqLang =
      await require(`../../../i18n/default-values/faq-page/${languageCode}.json`);
    updateWidgetMutation({
      id: { id: data && data.widgets_cornerwidget[0].id },
      config: {
        launcher_config: {
          launcherMaping: {
            assistant: "sideTab",
            offers:
              initialLauncherMappingValues.launcherMaping.offers ===
              "sideTab"
                ? "cornerButton"
                : initialLauncherMappingValues.launcherMaping.offers,
          },
          cornerButton: {...initialLauncherMappingValues.cornerButton,text:findInitialLauncherText("offers")},
            sideTab: {...initialLauncherMappingValues.sideTab, text:findInitialLauncherText("assistant")},
        },
        faq_lang: { [`${languageCode}`]: faqLang },
        corner_lang: { [`${languageCode}`]: cornerLang },
      },
    });
    updateOnboardingProgressMutation();
  };

  return (
    <div className="laptop:flex mobile:block items-center h-full w-full mt-8">
      <div className="p-8 shrink grow max-w-xl">
        <div className="mb-8">
          <h1 className="font-bold text-gray-700 mb-3">
            Select your default language
          </h1>
          <p>
            Based on this default language the text in the copy will be
            initialised.
          </p>
        </div>
        <div className="max-w-s mb-8">
          {data && (
            <Select
            isSearchable={false}
              value={langOptions.find(
                (option) => option.value === languageCode
              )}
              onChange={(e) => {
                setlanguageCode(e.value);
              }}
              className="w-48 mr-2"
              classNamePrefix="react-select"
              options={langOptions}
            />
          )}
        </div>
        <div className="flex justify-end px-8">
          <Button type="primary" onClick={() => handleSubmit()}>
            Save & set it live
          </Button>
        </div>
      </div>
    </div>
  );
}
