import React, { useState, useEffect, useRef } from "react";
import { createPortal } from "react-dom";
import { Checkbox } from "@atlaskit/checkbox";
import { RiArrowUpDownFill } from "react-icons/ri";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

//Here there are two type defenitions becuase 'selectedItemsId' is only required if you need selection but if you need selection you need both 'selectedItemsId' & 'onSelectionChange'
/* type Props = { */
/*   draggable?: undefined | boolean; */
/*   onDrag?: undefined | Function; */
/*   disableSelectAll?: boolean; */
/*   items: any; */
/*   header?: React.ReactNode; */
/*   render: Function; */
/*   selectedItemsId?: number[]; */
/*   onSelectionChange?: Function; */
/*   emptyState?: React.ReactNode; */
/* }; */

const List = (props) => {
  //to know why this hook is created refer https://github.com/DucktorDanny/react-beautiful-dnd-example
  const useDraggableInPortal = () => {
    const self = useRef({}).current;

    useEffect(() => {
      if (props.draggable) {
        const div = document.createElement("div");
        div.style.position = "absolute";
        div.style.pointerEvents = "none";
        div.style.top = "0";
        div.style.width = "100%";
        div.style.height = "100%";
        self.elt = div;
        document.body.appendChild(div);
        return () => {
          document.body.removeChild(div);
        };
      }
    }, [self]);

    return (render) =>
      (provided, ...args) => {
        const element = render(provided, ...args);
        if (provided.draggableProps.style.position === "fixed") {
          return createPortal(element, self.elt);
        }
        return element;
      };
  };

  const [listItems, setListItems] = useState({ data: props.items });
  const renderDraggable = useDraggableInPortal();

  function onDragEnd(result) {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    // get the items array
    const newItems = [...listItems.data];
    // get the draggedItems
    const draggedItem = newItems[result.source.index];
    // delete the item from source position and insert it to the destination positon
    newItems.splice(result.source.index, 1);
    newItems.splice(result.destination.index, 0, draggedItem);
    // create new data
    const newData = {
      data: newItems,
    };
    // update state

    setListItems(newData);

    props.onDrag && props.onDrag(newItems);
  }

  useEffect(() => {
    setListItems({ data: props.items });
  }, [props.items]);

  if (props.draggable)
    return (
      <ul>
        <li className="flex items-center justify-center border-b">
          {/* if its selectable a checkbox is rendered in the header to handle select all functionality */}
          {props.selectedItemsId && !props.disableSelectAll && (
            <div>
              <Checkbox
                onChange={(event) => {
                  // this first if is only put to make sure type script error doesnt happen
                  if (props.onSelectionChange) {
                    if (event.target.checked) {
                      const allSelectedArray = listItems.data.map(
                        (item) => item.id
                      );
                      props.onSelectionChange(allSelectedArray);
                    } else {
                      props.onSelectionChange([]);
                    }
                  }
                }}
              />
            </div>
          )}

          {/* if its dragggable an invisible drag icon is rendered this is redered just to keep the alignment in the list items and header same */}
          {props.draggable && props.header && (
            <div className="px-4">
              <h1 className="opacity-0">
                <RiArrowUpDownFill />
              </h1>
            </div>
          )}
          {/* Rest of the header elements are rendered from  */}
          {props.header && <div className="flex-1">{props.header}</div>}
        </li>
        {props.emptyState && listItems.data.length === 0 && props.emptyState}
        {listItems.data.length > 0 && (
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable type="TASK" droppableId="list">
              {(provided) => {
                return (
                  <div ref={provided.innerRef} {...provided.droppableProps}>
                    {listItems.data.map((item, index) => (
                      <Draggable
                        key={item.id}
                        draggableId={`${item.id}`}
                        index={index}
                      >
                        {renderDraggable((provided, snapshot) => (
                          <li
                            key={`${item.id}`}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            className={`flex border-b hover:bg-gray-100 transition-all items-center  ${
                              snapshot.isDragging &&
                              "bg-white shadow-sm rounded-md border"
                            }`}
                          >
                            {/* Checkbox if list is selectable */}
                            {props.selectedItemsId && (
                              <div>
                                <Checkbox
                                  isChecked={props.selectedItemsId.includes(
                                    item.id
                                  )}
                                  onChange={(event) => {
                                    // this first if-statement is only put to make sure type script error doesnt happen saying onSelectionChange could be undefined
                                    if (
                                      props.onSelectionChange &&
                                      props.selectedItemsId
                                    )
                                      if (event.target.checked)
                                        props.onSelectionChange([
                                          ...props.selectedItemsId,
                                          item.id,
                                        ]);
                                      else {
                                        const updatedSelectedItems =
                                          props.selectedItemsId.filter(
                                            (selectedItem) =>
                                              selectedItem !== item.id
                                          );
                                        props.onSelectionChange(
                                          updatedSelectedItems
                                        );
                                      }
                                  }}
                                />
                              </div>
                            )}
                            {/* Drag Icon if list is draggabel */}
                            {props.draggable && (
                              // provided.dragHandleProps defines which is handle for draging the element
                              <div
                                {...provided.dragHandleProps}
                                className="px-4"
                              >
                                <h1 className="text-gray-400 cursor-move">
                                  <RiArrowUpDownFill />
                                </h1>
                              </div>
                            )}
                            <div className="flex-1">
                              {props.render(item, index)}
                            </div>
                          </li>
                        ))}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                );
              }}
            </Droppable>
          </DragDropContext>
        )}
      </ul>
    );
  else
    return (
      <ul>
        <li className="flex items-center justify-center border-b">
          {/* if its selectable a checkbox is rendered in the header to handle select all functionality */}
          {props.selectedItemsId && !props.disableSelectAll && (
            <div>
              <Checkbox
                onChange={(event) => {
                  // this first if is only put to make sure type script error doesnt happen
                  if (props.onSelectionChange) {
                    if (event.target.checked) {
                      const allSelectedArray = listItems.data.map(
                        (item) => item.id
                      );
                      props.onSelectionChange(allSelectedArray);
                    } else {
                      props.onSelectionChange([]);
                    }
                  }
                }}
              />
            </div>
          )}

          {/* Rest of the header elements are rendered from  */}
          {props.header && <div className="flex-1">{props.header}</div>}
        </li>
        {props.emptyState && listItems.data.length === 0 && props.emptyState}
        {listItems.data.length > 0 && (
          <div>
            {listItems.data.map((item, index) => (
              <li
                key={`${item.id}${Math.random()}`}
                className={`flex border-b hover:bg-gray-100 transition-all items-center`}
              >
                {/* Checkbox if list is selectable */}
                {props.selectedItemsId && (
                  <div>
                    <Checkbox
                      isChecked={props.selectedItemsId.includes(item.id)}
                      onChange={(event) => {
                        // this first if-statement is only put to make sure type script error doesnt happen saying onSelectionChange could be undefined
                        if (props.onSelectionChange && props.selectedItemsId)
                          if (event.target.checked)
                            props.onSelectionChange([
                              ...props.selectedItemsId,
                              item.id,
                            ]);
                          else {
                            const updatedSelectedItems =
                              props.selectedItemsId.filter(
                                (selectedItem) => selectedItem !== item.id
                              );
                            props.onSelectionChange(updatedSelectedItems);
                          }
                      }}
                    />
                  </div>
                )}
                <div className="flex-1">{props.render(item, index)}</div>
              </li>
            ))}
          </div>
        )}
      </ul>
    );
};
export default List;
