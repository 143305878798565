import React from "react";
import { useLocation, useRouteMatch } from "react-router-dom";
import { RiEyeLine, RiEyeOffFill } from "react-icons/ri";
import toaster from "toasted-notes";
import CornerButton from "./LauncherSettings/CornerButton";
import SideTab from "./LauncherSettings/SideTab";
import PageViewContainer from "../../../../components/structure/PageViewContainer";
import CustomiserUnit from "../../../../components/CustomiserUnit";
import TabRadioButton from "../../../../components/TabRadioButton";
import Button from "../../../../components/Button";
import LoadingSpinner from "../../../../components/LoadingSpinner";
import { Formik,FieldArray } from "formik";
import LauncherPreview from "../Previews/LauncherPreview";
import { useQuery, useMutation, useQueryCache } from "react-query";
import { useGraphQl } from "../../../../contexts/GraphqlClient";
import { useWorkspace } from "../../../../contexts/WorkspaceContext";
import ArrayInput from "../../../../components/ArrayInput";
import {
  queryWidgetSettings,
  updateWidgetSettings,
} from "../../../../api/widgets";
import Select from "@atlaskit/select";
import Textfield from "@atlaskit/textfield";

const Launcher = () => {
  const { path } = useRouteMatch();
  const location = useLocation();
  const pageRoute = location.pathname.split("/")[3];
  const graphQL = useGraphQl();
  const queryCache = useQueryCache();
  const workspace = useWorkspace();
  const { data, refetch } = useQuery("queryWidgetSettings", () =>
    graphQL(queryWidgetSettings)
  );
  const updateWidget = async (values) => {
    await graphQL(updateWidgetSettings, {
      widgetSettingsId: values.id,
      widgetSettingsInput: values.config,
    });
    refetch();
  };
  const [updateWidgetMutation] = useMutation(updateWidget, {
    onSuccess: () => {
      queryCache.invalidateQueries("queryWidgetSettings");
      toaster.notify("Launcher settings saved successfully", {
        duration: 2000,
      });
    },
  });

  const getTitle = () => {
    switch (pageRoute) {
      case "offers":
        return "Offers Launcher";
      case "faq":
        return "FAQ Launcher";
      default:
        return "Look & Feel";
    }
  };



  // The following 2 functions (handleOfferLauncherChange & handleAssistantLauncherChange) will take care of two things
  // 1. Asigns the launcherMapping Values
  // 2. Exchanges the tab text between two launchers so that when launcher mapping is changed each launcher pretains the text respective to the function they are handling

  const handleAssistantLauncherChange = (e, props) => {
    if (e === "cornerButton") {
      if (props.values.launcherMaping.assistant === "sideTab") {
        let cornerButtonText = props.values.cornerButton.text;
        let sideTabText = props.values.sideTab.text;
        props.setFieldValue("cornerButton.text", sideTabText);
        props.setFieldValue("sideTab.text", cornerButtonText);
      } else if (props.values.launcherMaping.assistant === "hide") {
        props.setFieldValue("cornerButton.text", "HelpDesk");
        props.setFieldValue("sideTab.text", "Offer");
      }
      props.setFieldValue("launcherMaping.assistant", "cornerButton");
      props.setFieldValue("launcherMaping.offers", "sideTab");
    } else if (e === "sideTab") {
      if (props.values.launcherMaping.assistant === "cornerButton") {
        let cornerButtonText = props.values.cornerButton.text;
        let sideTabText = props.values.sideTab.text;
        props.setFieldValue("cornerButton.text", sideTabText);
        props.setFieldValue("sideTab.text", cornerButtonText);
      } else if (props.values.launcherMaping.assistant === "hide") {
        props.setFieldValue("sideTab.text", "HelpDesk");
        props.setFieldValue("cornerButton.text", "Offer");
      }
      props.setFieldValue("launcherMaping.assistant", "sideTab");
      props.setFieldValue("launcherMaping.offers", "cornerButton");
    } else if (e === "hide") {
      props.setFieldValue("launcherMaping.assistant", "hide");
    }
  };

  const widgetSelector = [
    {
      label: "Side Tab",
      value: "sideTab",
    },
    {
      label: "Corner Button",
      value: "cornerButton",
    },
    {
      label: "Hide Launcher",
      value: "hide",
    },
  ];

  const renderLauncherSettings = (props, value, currentContext) => {
    switch (value) {
      case "sideTab":
        return <SideTab {...props} hideOnclick={currentContext === "offers"} />;
      case "cornerButton":
        return <CornerButton {...props} />;
      default:
        return (
          <div className="flex justify-center mt-12 text-gray-300">
            <div>
              <h2 className="flex justify-center mb-2 text-4xl">
                <RiEyeOffFill />
              </h2>
              <h2 className="text-center ">Launcher Hidden</h2>
            </div>
          </div>
        );
    }
  };
  const initialValues = {
    id: data && data.widgets_cornerwidget[0].id,
    alignment: data && data.widgets_cornerwidget[0].common_config.alignment,
    blockedUrls: data && data.widgets_cornerwidget[0].common_config.blockedUrls,
    zIndex: data && data.widgets_cornerwidget[0].common_config.zIndex,
    launcherMaping:
      data && data.widgets_cornerwidget[0].launcher_config.launcherMaping,
    cornerButton:
      data && data.widgets_cornerwidget[0].launcher_config.cornerButton,
    sideTab: data && data.widgets_cornerwidget[0].launcher_config.sideTab,
  };
  if (data)
    return (
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={(values) => {
          const launcherConfig = {
            launcher_config: {
              launcherMaping: values.launcherMaping,
              cornerButton: values.cornerButton,
              sideTab: values.sideTab,
            },
            common_config: {
              ...data.widgets_cornerwidget[0].common_config,
              alignment: values.alignment,
              blockedUrls: values.blockedUrls,
              zIndex: values.zIndex,
            },
          };

          updateWidgetMutation({
            id: { id: values.id },
            config: launcherConfig,
          });
        }}
      >
        {(props) => (
          <PageViewContainer
            title={getTitle()}
            action={
              <div className="flex">
                {props.dirty ? (
                  <Button
                    type="primary"
                    icon={<RiEyeLine />}
                    onClick={() => props.submitForm()}
                  >
                    Save Settings
                  </Button>
                ) : (
                  <Button
                    className="mr-2"
                    type="white-ghost"
                    onClick={() => {
                      if (workspace.data)
                        window.open(`https://${workspace.data.storeUrl}`);
                    }}
                    icon={<RiEyeLine />}
                  >
                    View it on my store
                  </Button>
                )}
              </div>
            }
          >
            <div className="flex w-full h-full ">
              <div className="laptop:max-w-xl mobile:w-full h-full shrink-0 grow px-8 overflow-y-auto">
                <div>
                  <CustomiserUnit
                    title="Choose FAQ widget Launcher"
                    description="A launcher is a small sticky widget that opens the FAQ widget."
                  >
                    <TabRadioButton
                      defaultValue={props.values.launcherMaping.assistant}
                      options={widgetSelector}
                      onChange={(e) => {
                        handleAssistantLauncherChange(e, props);
                      }}
                    />
                  </CustomiserUnit>

                  

                  {renderLauncherSettings(
                    props,
                    props.values.launcherMaping.assistant,
                    "assistant"
                  )}

             
                </div>
              </div>
              <div className="grow h-full bg-gray-100 border-l mobile:hidden laptop:flex items-center justify-center overflow-hidden">
                <LauncherPreview
                  cornerButtonConfig={props.values.cornerButton}
                  sideTabConfig={props.values.sideTab}
                  installedApps={workspace.data.installedApps}
                  showAssistantWidget={workspace.data.installedApps.find(
                    (item) => item.appid === 1
                  )}
                  showOfferWidget={
                    workspace.data.installedApps.find(
                      (item) => item.appid === 2
                    ) &&
                    workspace.data.feature_flag.apps?.offerscorner
                      ?.corner_widget
                  }
                  // the following code makes sures the offers launcher will not be visible if they dont have that option in the feature flag
                  launcherMaping={props.values.launcherMaping}
                  alignment={props.values.alignment}
                  themeColor={
                    data.widgets_cornerwidget[0].common_config.bgEndColor
                  }
                />
              </div>
            </div>
          </PageViewContainer>
        )}
      </Formik>
    );
  else return <LoadingSpinner />;
};

export default Launcher;
