import React, { useState, useEffect } from "react";
import { useQuery, useMutation, useQueryCache } from "react-query";
import Modal, { ModalTransition } from "@atlaskit/modal-dialog";
import {
  RiDeleteBin2Fill,
  RiPlayListAddFill,
} from "react-icons/ri";
import toaster from "toasted-notes";
import { Link } from "react-router-dom";
import List from "../../../../components/List";
import Button from "../../../../components/Button";
import LoadingSpinner from "../../../../components/LoadingSpinner";
import { useGraphQl } from "../../../../contexts/GraphqlClient";
import {
  queryAllAssistantArticles,
  queryAccordionArticles,
  deleteAccordionArticle,
  updateAccordionArticle,
  insertAccordionArticles,
} from "../../../../api/support";

const AccordionListEdit = (props) => {
  const [selectedArticles, setSelectedArticles] = useState([]);
  const [articleList, setArticleList] = useState([]);
  const [isAddArticleModalOpen, setIsAddArticleModalOpen] = useState(false);
  const [listInitialised, setListInitialised] = useState(false);
  const graphQL = useGraphQl();
  const queryCache = useQueryCache();
  const { data: articleData } = useQuery("queryAllAssistantArticles", () =>
    graphQL(queryAllAssistantArticles)
  );

  const { data, isLoading, isFetching } = useQuery(
    `queryAccordionArticles${props.accordionId}`,
    () =>
      graphQL(queryAccordionArticles, {
        accordionsid: props.accordionId,
      })
  );

  const deleteAccordionArticles = async (input) => {
    await graphQL(deleteAccordionArticle, {
      accordionArticleId: input.id,
    });
  };

  const [deleteAccordionArticleMutation] = useMutation(
    deleteAccordionArticles,
    {
      onSuccess: () => {
        toaster.notify("Article deleted", {
          duration: 2000,
        });
        queryCache.invalidateQueries(
          `queryAccordionArticles${props.accordionId}`
        );
      },
    }
  );
  const bulkInsertArticles = async (input) => {
    await graphQL(insertAccordionArticles, {
      accordionArticlesInput: input,
    });
  };

  const [insertAccordionArticlesMutation] = useMutation(bulkInsertArticles, {
    onSuccess: () => {
      toaster.notify("Articles inserted", {
        duration: 2000,
      });
      queryCache.invalidateQueries(
        `queryAccordionArticles${props.accordionId}`
      );
      setSelectedArticles([]);
    },
  });

  const updateAccordionArticles = async (input) => {
    await graphQL(updateAccordionArticle, {
      accordionsArticleId: { id: input.id },
      accordionsArticleInput: input.values,
    });
  };
  const [updateAccordionArticlesMutation] = useMutation(
    updateAccordionArticles,
    {
      onSuccess: () => {
        queryCache.invalidateQueries(
          // Here we are attaching the accordions ID with the query key. This will make sure old data will not be loaded while fetching for new data when we switch pages
          `queryAccordionArticles${props.accordionId}`
        );
      },
    }
  );

  // When user drags an article, the whole new articleList array which is aranged in the new order is passed on to this fuction. Here we update each artcle's position_rank with its array index
  const handleDrag = (updatedList) => {
    updatedList.map((item, index) => {
      updateAccordionArticlesMutation({
        id: item.id,
        values: {
          position_rank: index,
        },
      });
    });
  };

  const handleBulkArticleAddtion = () => {
    let inputArray = selectedArticles.map((articleId, index) => {
      return {
        accordionsid: props.accordionId,
        articlesid: articleId,
        position_rank: data.accordion_articles.length + index,
      };
    });
    insertAccordionArticlesMutation(inputArray);
  };

  // each time anything is updated this functio makes sure the updated article list is sorted.
  const initialiseList = () => {
    const arrangedArticleList = data.accordion_articles.sort(
      (a, b) => a.position_rank - b.position_rank
    );
    setArticleList(arrangedArticleList);
    setListInitialised(true);
  };

  useEffect(() => {
    if (data) initialiseList();
  }, [data]);

  if (isLoading) {
    return <LoadingSpinner />;
  }else 
    return (
      <div className="flex justify-center w-full py-3">
        <div className="laptop:w-3/4 mobile:w-full mx-3 border rounded-lg">
          {articleData && listInitialised && (
            <List
              draggable
              onDrag={handleDrag}
              items={articleList}
              header={
                <div className="flex justify-between items-center">
                  <p className="font-bold text-gray-600">Accordion</p>
                  <Button
                    onClick={() => setIsAddArticleModalOpen(true)}
                    className="mx-3"
                    type="primary"
                    icon={<RiPlayListAddFill />}
                  >
                    Add question
                  </Button>
                </div>
              }
              emptyState={
                <div className="p-8">
                  <div className="flex justify-center my-8">
                    <img
                      src="https://assets.cornercart.io/coca-v2/images/empty-states/file.png"
                      alt=""
                    />
                  </div>
                  <div className="text-center">
                    <h3 className="font-bold text-gray-700 mb-3">
                      Empty Accordion{" "}
                    </h3>
                    <p className="text-gray-800">
                      You currently have no articles in this accordion. Click
                      the button below to add one
                    </p>
                    <div className="flex justify-center mt-8">
                    <Button
                    onClick={() => setIsAddArticleModalOpen(true)}
                    className="mx-3"
                    type="ghost"
                    icon={<RiPlayListAddFill />}
                  >
                    Add question
                  </Button>
                    </div>
                  </div>
                </div>
              }
              render={(item, index) => {
                return (
                  <div className="grid grid-cols-12 items-center justify-center py-5">
                    <div className="col-span-10 laptop:px-7 mobile:px-3">
                      <p className="font-bold text-gray-700">
                        {item.assistant_article.title}
                      </p>
                    </div>
                    <div className="col-span-1">
                      <Link
                        onClick={(e) => {
                          e.stopPropagation();
                          e.nativeEvent.stopImmediatePropagation();
                        }}
                        to={`/support/faq/articles/${item.assistant_article.id}/edit`}
                      >
                        <Button type="link">Edit</Button>
                      </Link>
                    </div>
                    <div className="col-span-1">
                      <Button
                        onClick={() => {
                          deleteAccordionArticleMutation({
                            id: item.id,
                          });
                        }}
                        type="danger-link"
                      >
                        <RiDeleteBin2Fill />
                      </Button>
                    </div>
                  </div>
                );
              }}
            />
          )}
        </div>
        <div>
          <ModalTransition>
            {isAddArticleModalOpen && articleData && (
              <Modal
                actions={[
                  {
                    text: "Confirm",
                    onClick: () => {
                      handleBulkArticleAddtion();
                      setIsAddArticleModalOpen(false);
                    },
                  },
                  {
                    text: "Cancel",
                    onClick: () => {
                      setIsAddArticleModalOpen(false);
                    },
                  },
                ]}
                onClose={() => {
                  setIsAddArticleModalOpen(false);
                }}
                heading="Select Articles"
              >
                <List
                  //the following logic makes sure articles already existing in this accordion is not shown in this list
                  items={articleData.assistant_articles.filter(
                    (article) =>
                      !articleList
                        .map((item) => item.assistant_article.id)
                        .includes(article.id)
                  )}
                  selectedItemsId={selectedArticles}
                  onSelectionChange={setSelectedArticles}
                  header={
                    <div className="grid grid-cols-12 items-center justify-center ">
                      <div className="col-span-12 px-7">
                        <p className="font-bold text-gray-600">Title</p>
                      </div>
                    </div>
                  }
                  emptyState={
                    <div>
                      <div className="flex justify-center my-8">
                        <img
                          src="https://assets.cornercart.io/coca-v2/images/empty-states/slides.png"
                          alt=""
                        />
                      </div>
                      <div className="text-center">
                        <h3 className="font-bold text-gray-700 mb-3">
                          Create an FAQ article{" "}
                        </h3>
                        <p className="text-gray-800">
                          You currently have no FAQ articles. Go to articles
                          page to create an article
                        </p>
                      </div>
                    </div>
                  }
                  render={(item) => (
                    <div className="grid grid-cols-12 items-center justify-center py-5">
                      <div className="col-span-12 px-7">
                        <p className="font-bold text-gray-700 hover:text-violet-700">
                          {item.title}
                        </p>
                        <p className="text-gray-700"></p>
                      </div>
                    </div>
                  )}
                />
              </Modal>
            )}
          </ModalTransition>
        </div>
      </div>
    );
};

export default AccordionListEdit;
