/* -------------- Queries ---------- */

export const queryUsers = `
query queryUsers  {
  users {
    id
    full_name
    first_name
    email
    last_name
    preferred_email
    meta
  }
}
`;

export const queryWorkspaceProps = `
query queryWorkspaceProps  {
  billing_plans {
    id
    appid
    billing_amount
    billing_schedule
  }
  installations {
    appid
    status
  }
  feature_access {
    feature_flag
  }
  workspaces {
    accountid
    id
    userid
    sourceid
    user {
      email
      full_name
    }
    source {
      source_meta(args:{hasura_session:{}})
      source_domain
      onboarding_progress
      offer_onboarding
      cornercart_onboarding
      default_language
      currency_format
      tracking_pixels
    }
  }
}
`;

/* --------------- Mutations ------------------- */
export const updateSourceDetails = `
  mutation updateSourceDetails ($sourceId:sources_pk_columns_input!, $sourceInput:sources_set_input){
    update_sources_by_pk(pk_columns: $sourceId, _set:$sourceInput) {
      id
    }
  }
`;
export const updateUserDetails = `
  mutation updateUserDetails ($userId:users_pk_columns_input!, $userInput:users_set_input){
    update_users_by_pk(pk_columns: $userId, _set:$userInput) {
      id
    }
  }
`;
