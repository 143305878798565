import React from "react";
import AuthorisedApp from "./AuthorisedApp";
import UnauthorisedApp from "./UnauthorisedApp";
import { useAuth } from "./contexts/AuthenticationContext";
import { useWorkspace } from "./contexts/WorkspaceContext";
import ThankyouPage from "./previews/ThankyouPage";
// import ThankyouPage from "./previews/ThankyouPage";
import { Switch, Route, BrowserRouter as Router } from "react-router-dom";
import LoadingSpinner from "./components/LoadingSpinner";

function App() {
  const authState = useAuth();
  const workspace = useWorkspace();

  /**
   * redirects to backend respective to each app
   * @param {Number} appId - app id
   * @returns {ReactNode}
   */
  const ManageRedirection = ({ appId }) => {
    console.log("Corner redirect", window.location.href);
    switch (appId) {
      case 1: {
        window.location.assign(
          `${process.env.REACT_APP_STARLINK_URL}/shopify/install/supportcorner${window.location.search}`
        );
        break;
      }
      case 2: {
        window.location.assign(
          `${process.env.REACT_APP_STARLINK_URL}/shopify/install/offerscorner${window.location.search}`
        );
        break;
      }
      case 3: {
        window.location.assign(
          `${process.env.REACT_APP_STARLINK_URL}/shopify/install/cornercart${window.location.search}`
        );
        break;
      }
    }
    return <LoadingSpinner />;
  };

  return (
    <Router>
      <Switch>
        <Route
          exact
          path="/shopify/install/supportcorner"
          component={() => <ManageRedirection appId={1} />}
        />
        <Route
          exact
          path="/shopify/install/offerscorner"
          component={() => <ManageRedirection appId={2} />}
        />
        <Route
          exact
          path="/shopify/install/cornercart"
          component={() => <ManageRedirection appId={3} />}
        />
        <Route exact path="/preview/thankyou-page" component={ThankyouPage} />
        {authState.status === "in" && workspace.data ? (
          <Route path="/" component={AuthorisedApp} />
        ) : (
          <Route path="/" component={UnauthorisedApp} />
        )}
      </Switch>
    </Router>
  );
}
export default App;
