import React, { useState, useEffect } from "react";
import { useQuery, useMutation } from "react-query";
import Textfield from "@atlaskit/textfield";
import Button from "../../../components/Button";
import { queryUsers, updateUserDetails } from "../../../api/workspace";
import { useGraphQl } from "../../../contexts/GraphqlClient";
import { useWorkspace } from "../../../contexts/WorkspaceContext";
import { useHistory } from "react-router-dom";
import { Formik } from "formik";

export default function LanguageSelector() {
  let history = useHistory();
  const workspace = useWorkspace();
  const graphQL = useGraphQl();
  const { data: userData } = useQuery("queryUsers", () => graphQL(queryUsers));

  const updateUserDetail = async (values) => {
    await graphQL(updateUserDetails, {
      userId: values.id,
      userInput: values.config,
    });
  };

  const [updateUserDetailMutation] = useMutation(updateUserDetail, {
    onSuccess: () => {
      history.push("/get-started/3/");
    },
    onError: () => {},
  });

  return (
    <div className="laptop:flex mobile:block items-center h-full w-full mt-8 bg-white rounded-lg shadow-lg">
      {userData && (
        <Formik
          enableReinitialize
          initialValues={userData.users[0]}
          onSubmit={(values) => {
            updateUserDetailMutation({
              id: { id: values.id },
              config: {
                first_name: values.first_name,
                last_name: values.last_name,
                preferred_email: values.preferred_email,
              },
            });
          }}
        >
          {({ values, dirty, submitForm, setFieldValue, resetForm }) => {
            return (
              <div className="p-8 shrink grow max-w-xl">
                <div className="mb-8">
                  <h1 className="font-bold text-center text-gray-700 mb-3">
                    Tell us about yourself
                  </h1>
                  <p>The following information helps us setup your workspace</p>
                </div>
                <div className="my-5">
                  <div className="flex">
                    <div className="flex-grow mr-4">
                      <label htmlFor="First name" className="text-xs font-bold">
                        First Name
                      </label>
                      <Textfield
                        defaultValue={values.first_name}
                        onChange={(e) =>
                          setFieldValue("first_name", e.target.value)
                        }
                        name="basic"
                      />
                    </div>

                    <div className="flex-grow">
                      <label htmlFor="Last name" className="text-xs font-bold">
                        Last Name
                      </label>
                      <Textfield
                        defaultValue={values.last_name}
                        onChange={(e) =>
                          setFieldValue("last_name", e.target.value)
                        }
                        name="basic"
                      />
                    </div>
                  </div>
                  <div className=" mt-4">
                    <label htmlFor="Last name" className="text-xs font-bold">
                      Email ID
                    </label>
                    <Textfield
                      defaultValue={values.preferred_email}
                      onChange={(e) =>
                        setFieldValue("preferred_email", e.target.value)
                      }
                      name="basic"
                    />
                  </div>
                </div>
                <div className="flex justify-center px-8">
                  <Button
                    type="primary"
                    onClick={() => {
                      submitForm();
                    }}
                  >
                    Continue
                  </Button>
                </div>
              </div>
            );
          }}
        </Formik>
      )}
    </div>
  );
}
