import React, { useState } from "react";
import { RiSpeedMiniFill } from "react-icons/ri";

/**
 * A component to show the horizontal tabs
 * @param {[Objects]} items - array of object which have the label and the link
 * @param {function} onChange - the onchange function
 * @param {boolean} showSeperatorIcons - If this is true an icon with seperators will be visible between each tabs
 */
const HorizontalTabs = ({ items, onChange, showSeperatorIcons }) => {
  const [currentTab, setCurrentTab] = useState(items[0].id);
  const TabButton = ({ tabID, name, icon }) => {
    return (
      <button
        onClick={() => {
          setCurrentTab(tabID);
          onChange(tabID);
        }}
        className={`flex items-center mx-3 px-0 py-4 desktop:p-5 text-sm desktop:text-base ${
          currentTab === tabID &&
          "border-b-2 border-violet-700 text-violet-700 font-bold "
        }`}
      >
        {icon && <span className="mr-3 text-xs">{icon}</span>}
        <span>{name}</span>
      </button>
    );
  };

  return (
    <div className="flex items-center border-b justify-center grow-0">
      {items.map((item, index) => {
        return (
          <>
            {index !== 0 && showSeperatorIcons && (
              <div className=" desktop:block hidden text-gray-300">
                <RiSpeedMiniFill />
              </div>
            )}
            <TabButton name={item.label} tabID={item.id} icon={item.icon} />
          </>
        );
      })}
    </div>
  );
};

export default HorizontalTabs;
