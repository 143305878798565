import React from "react";
import { useQuery, useMutation, useQueryCache } from "react-query";
import ArticleList from "../../../../components/ArticleList";
import PageViewContainer from "../../../../components/structure/PageViewContainer";
import Button from "../../../../components/Button";
import { useGraphQl } from "../../../../contexts/GraphqlClient";
import { useWorkspace } from "../../../../contexts/WorkspaceContext";
import {
  insertAssistantArticle,
  queryAllAssistantArticles,
} from "../../../../api/support";
import { RiFileAddLine } from "react-icons/ri";

{/* type Article = { */}
{/*   id: string; */}
{/*   title: string; */}
{/*   views: number; */}
{/*   likes: number; */}
{/*   dislikes: number; */}
{/* }; */}

const ArticlesList = (historyProps) => {
  const graphQL = useGraphQl();
  const workspace= useWorkspace();
  const queryCache = useQueryCache();
  const { data } = useQuery("queryAllAssistantArticles", () =>
    graphQL(queryAllAssistantArticles)
  );
  const defaultArticleValue = {
    title: "untitled",
    body: "<p>Write your article here</p>",
    is_published: false,
    is_featured: true,
    lang: "en",
    statistics: { likes: 1, views: 1 },
    accountid: workspace.data && workspace.data.accountid,
    sourceid: workspace.data && workspace.data.sourceid,
    categoryid:null,
    position_rank: 0,
    visibility_rules:{ viewInAll: false, product: [], tags: [] },
    seo_options:{seo_title: "", seo_description: ""},
  };
  const insertArticle = async () => {
    return await graphQL(insertAssistantArticle, {
      articleInput: defaultArticleValue,
    });
  };

  const [insertArticleMutation] = useMutation(insertArticle, {
    onSuccess: (data) =>
      historyProps.history.push(
        `/support/faq/articles/${data.insert_assistant_articles_one.id}/edit`
      ),
  });

  return (
    <PageViewContainer
      title="Articles"
      action={
        <div className="flex">
          <Button
            onClick={async () => insertArticleMutation()}
            type="primary"
            icon={<RiFileAddLine />}
          >
            Add Article
          </Button>
        </div>
      }
    >
      <div className="desktop:px-8 px-3 pt-4 h-full overflow-y-auto">
        {data && (
          <ArticleList
            data={data}
            hideLimitWarning
            showImportExport
            onUpdate={() =>
              queryCache.invalidateQueries("queryAllAssistantArticles")
            }
            emptyState={
              <div>
                <div className="flex justify-center desktop:my-8 my-4">
                  <img
                    src="https://assets.cornercart.io/coca-v2/images/empty-states/talk.png"
                    alt=""
                  />
                </div>
                <div className="text-center">
                  <h3 className="font-bold text-gray-700 mb-3 text-base desktop:text-lg">
                    Create an FAQ article{" "}
                  </h3>
                  <p className="text-gray-800 text-tiny desktop:text-base">
                    You currently have no FAQ articles. Click the the button
                    below to add one
                  </p>
                  <div className="flex justify-center mt-8">
                    <Button
                      onClick={async () => insertArticleMutation()}
                      type="ghost"
                      icon={<RiFileAddLine />}
                    >
                      Add New Article
                    </Button>
                  </div>
                </div>
              </div>
            }
          />
        )}
      </div>
    </PageViewContainer>
  );
};

export default ArticlesList;
