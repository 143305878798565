import React, { useState } from "react";
import {
  RiChatSmile3Line,
  RiChatSmile3Fill,
  RiBookMarkLine,
  RiBookMarkFill,
  RiAwardLine,
  RiAwardFill,
  RiSettings4Fill,
  RiSettings4Line,
  RiShoppingCartLine,
  RiShoppingCartFill,
} from "react-icons/ri";
import { NavLink, useRouteMatch } from "react-router-dom";
import { useWorkspace } from "../../contexts/WorkspaceContext";

{
  /* interface BtnProps { */
}
{
  /*   title: string; */
}
{
  /*   normalIcon: React.ReactNode; */
}
{
  /*   focusedIcon: React.ReactNode; */
}
{
  /*   link: string; */
}
{
  /* } */
}

const CategoryNavBtn = (props) => {
  let match = useRouteMatch(props.link);
  const isSelected = match?.path === props.link;
  return (
    <NavLink to={props.link} strict>
      <div
        className={`text-gray-700 flex flex-col items-center justify-center py-5 cursor-pointer ${
          isSelected &&
          "border-l-2 border-violet-700"
        }  `}
      >
        <span className="text-xl">
          {isSelected ? props.focusedIcon : props.normalIcon}
        </span>
        <small className={`text-xs ${isSelected ? "font-bold" : ""}`}>
          {props.title}
        </small>
        {props.newTag && (
          <div
            style={{ fontSize: "8px" }}
            className="text-center top-0 right-0 px-1 rounded-lg text-white r-2 bg-red-500 font-bold"
          >
            NEW
          </div>
        )}
      </div>
    </NavLink>
  );
};

const CategoryNavButtons = () => {
  const workspace = useWorkspace();

  return (
    <div className="flex flex-col w-full">
      {workspace.data.installedApps.find((item) => item.appid === 2) && (
        <CategoryNavBtn
          title="Offers"
          normalIcon={<RiAwardLine size="20px" />}
          focusedIcon={<RiAwardFill size="20px" />}
          link="/offers/"
        />
      )}
      {workspace.data.installedApps.find((item) => item.appid === 3) && (
        <CategoryNavBtn
          title="Cart"
          normalIcon={<RiShoppingCartLine size="20px" />}
          focusedIcon={<RiShoppingCartFill size="20px" />}
          link="/cart/"
        />
      )}
      {workspace.data.installedApps.find((item) => item.appid === 1) && (
        <CategoryNavBtn
          title="Support"
          normalIcon={<RiBookMarkLine size="20px" />}
          focusedIcon={<RiBookMarkFill size="20px" />}
          link="/support/"
        />
      )}
      <CategoryNavBtn
        title="Settings"
        normalIcon={<RiSettings4Line size="20px" />}
        focusedIcon={<RiSettings4Fill size="20px" />}
        link="/settings/"
      />
    </div>
  );
};

export default CategoryNavButtons;
