import React, { useState } from "react";
import { useQuery, useMutation, useQueryCache } from "react-query";
import Modal, { ModalTransition } from "@atlaskit/modal-dialog";
import Button from "./Button";
import { useGraphQl } from "../contexts/GraphqlClient";
import { useWorkspace } from "../contexts/WorkspaceContext";
import { queryBillingInfo, activateFreePlan, choosePlan } from "../api/billing";

const OfcoPricingModal = (props) => {
  const workspace = useWorkspace();
  const graphQL = useGraphQl();
  const queryCache = useQueryCache();
  const { data } = useQuery("queryBillingInfoOFCO", () =>
    graphQL(queryBillingInfo, {
      appId: `${2}`, // hardcoded app because this particular modal only needs OFCO billing info
      sourceId: `${workspace.data?.sourceid}`,
    }),
    { enabled: workspace.data.installedApps.find((item) => item.appid === 2) }
  );
  const [revenue, setRevenue] = useState(100);
  const [isLoading, setIsLoading] = useState(false);

  const freePlanActivation = async () => {
    await graphQL(activateFreePlan, {
      appId: `${2}`,
      planId: `${10}`,
      sourceId: `${workspace.data?.sourceid}`,
    });
  };

  const [freePlanActivationMutation] = useMutation(freePlanActivation, {
    onError: (err) => {
      console.error("Error in ofco freePlanActivationMutation", err);
      window.Rollbar.error("Error in ofco freePlanActivationMutation", err);
    },
    onSuccess: () => {
      window.location.href = "/offers/referral/campaigns";
      queryCache.invalidateQueries("queryBillingInfoOFCO");
    },
  });

  const paidPlanActivation = async (input) => {
    const returnData = await graphQL(choosePlan, {
      appId: `${2}`,
      planId: `${input.id}`,
      sourceId: `${workspace.data?.sourceid}`,
    });

    return returnData;
  };

  const [paidPlanActivationMutation] = useMutation(paidPlanActivation, {
    onError: (err) => {
      console.log("error", err);
      window.Rollbar.error("Error in ofco paidPlanActivationMutation ", err);
    },
    onSuccess: (data) => {
      window.location.href = data?.choosePlan?.confirmationUrl;
    },
    onMutate: () => {
      setIsLoading(true);
    },
  });

  const handleButtonClick = (type) => {
    if (type === "free") freePlanActivationMutation();
    else
      paidPlanActivationMutation({
        id: 9,
      });
  };

  const BulletPoint = ({ text }) => (
    <div className="flex mt-5 items-center">
      <div className="w-4 h-4 mr-2 rounded-md bg-blue-200 shrink-0" />
      <p className="text-sm">{text}</p>
    </div>
  );

  const getProPricing = (value) => {
    if (value <= 100) return 0;
    else if (value > 100 && value <= 500) return 19;
    else if (value > 500 && value <= 2000) return 49;
    else if (value > 2000) return 129;
  };

  return (
    <ModalTransition>
      {data && props.isOpen && (
        <Modal
          width="1024px"
          onClose={() => {
            props.nonClosable && props.onClose(false);
          }}
        >
          <div className="mx-5 my-10">
            <div className="flex justify-center ">
              <div className="max-w-3xl text-center">
                <h1 className="font-bold mb-3 mx-auto">{props.title}</h1>
              </div>
            </div>
            <div
              className={`flex items-stretch justify-center w-full ${
                props.title && "mt-8"
              }`}
            >
              {/* Price card Free Plan only shown if user is not in a paid plan --start-- */}
              {data.billingInfo?.planId !== 9 && (
                <div className="flex flex-col border w-80 border-gray-400 p-8 rounded-md mr-3 ">
                  {/* Price card Content --start-- */}
                  <div className="grow">
                    <h1 className="font-bold text-violet-700">Free Plan</h1>
                    <h2 className="text-gray-700">
                      {" "}
                      <b>$0</b> / month
                    </h2>
                    <div className="mt-10">
                      <BulletPoint text="Upto* $100 in referred revenue each month" />
                      <BulletPoint text="Unlimited Referers" />
                      <BulletPoint text="Unlimited Campaigns" />
                      <BulletPoint text="Access to all features " />
                    </div>
                    <p className="text-xs text-gray-500 mt-5">
                      Once you reach $100 in referred revenue all campaigns will
                      be paused until the next billing cycle{" "}
                    </p>
                  </div>
                  {/* Price card Content --end-- */}

                  {/* Price card CTA --start-- */}
                  {data.billingInfo?.planId === 8 ? (
                    <div className=" text-center w-full border border-gray-600 text-violet-600 rounded-lg py-4 mt-5">
                      Current plan
                    </div>
                  ) : (
                    <button
                      onClick={() => {
                        handleButtonClick("free");
                      }}
                      className={`w-full  border-violet-600 text-violet-700 rounded-lg py-4 mt-5 ${
                        data.billingInfo?.planId !== 9 && "border"
                      }`}
                    >
                      {data.billingInfo?.planId === 9
                        ? "Downgrade to Free plan"
                        : "Continue with Free Plan"}
                    </button>
                  )}
                  {/* Price card CTA --end-- */}
                </div>
              )}
              {/* Price card Free Plan --end-- */}

              {/* Price card Pro Plan --start-- */}
              <div className="flex flex-col border grow shrink-0 border-gray-400 p-8 rounded-md mr-3 ">
                {/* Price card Content --start-- */}
                <div className="grow">
                  <h1 className="font-bold text-violet-700">Pro Plan</h1>
                  <h2 className="text-gray-700">
                    {" "}
                    <b>${getProPricing(revenue)}</b> / month{" "}
                    <span className="text-xs text-gray-600">
                      {getProPricing(revenue) === 0 &&
                        "( Up to $100 in referred revenue )"}
                      {getProPricing(revenue) === 19 &&
                        "( Up to $500 in referred revenue )"}
                      {getProPricing(revenue) === 49 &&
                        "( Up to $2000 in referred revenue )"}
                      {getProPricing(revenue) === 129 &&
                        "( If referred revenue is greater than $2000)"}
                    </span>
                  </h2>
                  <div className="relative mt-12   ">
                    <div
                      style={{
                        left: `calc(${(revenue / 3000) * 100}% + ${
                          9 - 0.86 * (revenue / 3000) * 100
                        }px)`,
                      }}
                      className="absolute bottom-9 px-2 py-1 rounded-lg bg-violet-700 text-white font-bold"
                    >
                      ${revenue}
                      <div className=" lol absolute -bottom-1  rotate-45 right-1/2 -mr-1 h-2 w-2 bg-violet-700" />
                    </div>
                    <div className="flex items-center z-20">
                      <p className="shrink-0 font-bold text-gray-500 mr-3">
                        {" "}
                        0
                      </p>
                      <div className="w-full">
                        <input
                          type="range"
                          defaultValue={revenue}
                          min="0"
                          max="3000"
                          class="appearance-none w-full h-2 focus:outline-none focus:ring-0 focus:shadow-none bg-gray-200 rounded-full "
                          id="customRange1"
                          onChange={(e) => {
                            setRevenue(e.target.value);
                          }}
                        />
                        <div className="flex border-violet-700">
                          <div
                            style={{ width: "5%" }}
                            className={`h-2 border-l border-r mx-1 border-b ${
                              getProPricing(revenue) === 0 && "border-blue-700"
                            }`}
                          ></div>
                          <div
                            style={{ width: "10%" }}
                            className={`h-2 border-l border-r mx-1 border-b ${
                              getProPricing(revenue) === 19 && "border-blue-700"
                            }`}
                          ></div>
                          <div
                            style={{ width: "47%" }}
                            className={`h-2 border-l border-r mx-1 border-b ${
                              getProPricing(revenue) === 49 && "border-blue-700"
                            }`}
                          ></div>
                          <div
                            className={`h-2 border-l grow mx-1 border-b ${
                              getProPricing(revenue) === 129 &&
                              "border-blue-700"
                            }`}
                          ></div>
                        </div>
                      </div>
                      <p className="shrink-0 font-bold text-gray-500 ml-3">
                        {" "}
                        3k+
                      </p>
                    </div>
                  </div>
                  <p className="text-center text-sm font-bold text-gray-500 mt-3">
                    {" "}
                    Drag the above slider to change the projected extra revenue
                    generated{" "}
                  </p>
                  <div className="mt-4">
                    <BulletPoint text="Pay us only when you generate extra revenue from referrals" />
                    <BulletPoint text="Access to all our features (including future updates)" />
                    <BulletPoint text="Unlimited referrers & campaigns" />
                    <BulletPoint text="Priority Support" />
                  </div>
                </div>
                {/* Price card Content --end-- */}

                {/* Price card CTA --start-- */}

                {data.billingInfo?.planId !== 9 && (
                  <button
                    onClick={() => {
                      handleButtonClick("paid");
                    }}
                    className=" w-full border bg-violet-700 text-white rounded-lg py-4 mt-5"
                  >
                    Upgrade PRO Plan {isLoading && "( Loading ... )"}
                  </button>
                )}

                {/* Price card CTA --end-- */}
              </div>
              {/* Price card Pro Plan --end-- */}
            </div>
            {!props.nonClosable && (
              <div className="flex justify-end mt-4">
                <Button onClick={() => props.onClose()} type="primary">
                  Cancel
                </Button>
              </div>
            )}
          </div>
        </Modal>
      )}
    </ModalTransition>
  );
};

export default OfcoPricingModal;
