import React from "react";
import { AuthenticationProvider } from "./contexts/AuthenticationContext";
import { WorkspaceProvider } from "./contexts/WorkspaceContext";
import { GraphqlClientProvider } from "./contexts/GraphqlClient";
import { BrowserRouter as Router } from "react-router-dom";

function AppContextProviders({ children }) {
  return (
    <AuthenticationProvider>
      <GraphqlClientProvider>
        <WorkspaceProvider>{children}</WorkspaceProvider>
      </GraphqlClientProvider>
    </AuthenticationProvider>
  );
}

export default AppContextProviders;
