import React, { useState, useEffect } from "react";
import { useQuery, useMutation, useQueryCache } from "react-query";
import { Formik } from "formik";
import { useGraphQl } from "../../../../../contexts/GraphqlClient";
import {
  queryCornerConvertWidgets,
  updatCornerStickybarWidget,
} from "../../../../../api/widgets";
import {
  RiFileWarningFill,
  RiEyeFill,
  RiEyeOffFill,
  RiTranslate,
  RiPaletteFill,
  RiListSettingsFill,
  RiSpeedMiniFill,
} from "react-icons/ri";
import PageViewContainer from "../../../../../components/structure/PageViewContainer";
import Button from "../../../../../components/Button";
import TabRadioButton from "../../../../../components/TabRadioButton";
import LoadingSpinner from "../../../../../components/LoadingSpinner";
import HorizontalTabs from "../../../../../components/HorizontalTabs";
import StickyBarGeneralSettings from "./GeneralSettings";
import StickyBarDesignSettings from "./DesignSettings";
import StickyBarLanguageSettings from "./LanguageSettings";
import toaster from "toasted-notes";
import WidgetPreview from "../../WidgetPreview";

/**
 * Componet for Rendering the Entire Stickybar settings
 */
export default function StickyBar() {
  const graphQL = useGraphQl();
  const queryCache = useQueryCache();
  const [isWidgetActive, setIsWidgetActive] = useState("");
  const [currentTab, setCurrentTab] = useState("design");

  const { data, refetch } = useQuery("queryCornerConvertWidgets", () =>
    graphQL(queryCornerConvertWidgets)
  );

  useEffect(() => {
    if (data) {
      setIsWidgetActive(data.widget_stickybar[0].is_active);
    }
  }, [data]);

  const WidgetStates = [
    {
      label: (
        <div className="flex items-center">
          <RiEyeFill className="flex-none  mr-2 text-lg" /> Widget Enabled{" "}
        </div>
      ),
      value: true,
    },
    {
      label: (
        <div className="flex items-center">
          <RiEyeOffFill className="flex-none mr-2 text-lg" /> Widget Disabled{" "}
        </div>
      ),
      value: false,
    },
  ];

  /**
   * Render function each tab inside the convert screen section
   * @param {object} values - formik values
   * @param {funtion} setFieldValue - formik method for setting form field values
   */
  const renderTabContent = (values, setFieldValue) => {
    switch (currentTab) {
      case "general":
        return (
          <StickyBarGeneralSettings
            values={values}
            setFieldValue={setFieldValue}
          />
        );
      case "design":
        return (
          <StickyBarDesignSettings
            values={values}
            setFieldValue={setFieldValue}
          />
        );
      case "language":
        return (
          <StickyBarLanguageSettings
            values={values}
            setFieldValue={setFieldValue}
            currentDefaultLang={data?.workspaces[0]?.source?.default_language}
          />
        );
    }
  };

  const updateStickyBarWidget = async (values) => {
    await graphQL(updatCornerStickybarWidget, {
      stickyBarId: values.id,
      stickyBarInput: values.stickybarDetails,
    });
    refetch();
  };

  const [updatCornerStickybarMutation] = useMutation(updateStickyBarWidget, {
    onSuccess: () => {
      toaster.notify("Widget settings updated successfully", {
        duration: 2000,
      });
    },
    onError: () => {},
  });

  const handleSubmitForm = (formValues) => {
    updatCornerStickybarMutation({
      id: { id: data.widget_stickybar[0].id },
      stickybarDetails: {
        stickybar_config: formValues.stickybar_config,
        sticky_atc_lang: formValues.sticky_atc_lang,
      },
    });
  };

  const handleStickyBarControl = (value) => {
    updatCornerStickybarMutation({
      id: { id: data.widget_stickybar[0].id },
      stickybarDetails: { is_active: value },
    });
  };

  const tabItems = [
    { label: "Design Settings", id: "design", icon: <RiPaletteFill /> },
    { label: "General Settings", id: "general", icon: <RiListSettingsFill /> },
    { label: "Language Settings", id: "language", icon: <RiTranslate /> },
  ];

  if (data) {
    return (
      <PageViewContainer
        title="Add to Cart Bar"
        action={
          <div className="flex">
            <TabRadioButton
              defaultValue={isWidgetActive}
              options={WidgetStates}
              onChange={(selectedValue) => {
                handleStickyBarControl(selectedValue);
              }}
            />
          </div>
        }
      >
        <div className="flex flex-col h-full">
          {!isWidgetActive && (
            <div className="bg-amber-300 text-amber-800 text-center py-2 flex items-center justify-center">
              <RiFileWarningFill className="text-xl mr-3" />
              <p className="text-sm">
                This widget is in draft mode. Publish it to make it visible in
                your store
              </p>
            </div>
          )}
          <HorizontalTabs
            items={tabItems}
            showSeperatorIcons
            onChange={(selectedTab) => {
              setCurrentTab(selectedTab);
            }}
          />

          <Formik
            enableReinitialize
            initialValues={data.widget_stickybar[0]}
            onSubmit={(values) => {
              handleSubmitForm(values);
            }}
          >
            {({ values, dirty, setFieldValue, resetForm, submitForm }) => {
              return (
                <>
                  {dirty && (
                    <div className="z-20 sticky top-0 flex justify-between border-b bg-amber-100 items-center py-3 desktop:px-8 px-4 ">
                      <p>You've unsaved changes</p>
                      <div className="flex items-center">
                        <Button
                          onClick={() => {
                            resetForm();
                          }}
                          type="link"
                        >
                          Discard Changes
                        </Button>
                        <Button
                          onClick={() => {
                            submitForm();
                          }}
                          type="primary"
                        >
                          Save Changes
                        </Button>
                      </div>
                    </div>
                  )}
                  <div className="grow flex flex-row min-h-0">
                    <div className="desktop:max-w-lg laptop:max-w-md mobile:w-full desktop:px-8 px-4 overflow-y-auto">
                      <div className="w-full h-full">
                        {renderTabContent(values, setFieldValue)}
                      </div>
                    </div>
                    <div className="grow flex-wrap h-full bg-gray-100 border-l mobile:hidden laptop:flex items-center justify-center overflow-y-auto">
                      <div className=" origin-top scale-60 desktop:scale-70">
                        <WidgetPreview
                          stickybarConfig={values.stickybar_config}
                          stickyAtcWidgetLang={values.sticky_atc_lang}
                          infoMessage="Scroll down the preview to view the Sticky add to cart bar"
                        />
                      </div>
                    </div>
                  </div>
                </>
              );
            }}
          </Formik>
        </div>
      </PageViewContainer>
    );
  } else {
    return <LoadingSpinner />;
  }
}
