import React, { useEffect, useState } from "react";
import { ChromePicker } from "react-color";
import Popup from "@atlaskit/popup";
import { RiPaintFill } from "react-icons/ri";

/**
 * Color Picker Component returns a color object with rgb array, hex code
 * @param {string} color - the rgb value (eg:rgb(255, 255, 255))
 * @param {function} onChange - the onchange function
 */
const ColorPicker = (props) => {
  const [colorPickerVisibility, setColorPickerVisibility] = useState(false);
  const [foreground, setForeground] = useState("white");
  const calculateForeground = (rgb) => {
    // Based on the R, G, B values foreground color is calculated (either black or white)
    const calculatedForeground =
      rgb.r * 0.299 + rgb.g * 0.587 + rgb.b * 0.114 > 186
        ? "rgba(0, 0, 0, 0.6)"
        : "white";
    setForeground(calculatedForeground);
  };
  useEffect(() => {
    let rgbArray = props.color.replace(/[^\d,]/g, "").split(",");
    let rgb = { r: rgbArray[0], g: rgbArray[1], b: rgbArray[2] };
    calculateForeground(rgb);
  }, []);
  return (
    <Popup
      isOpen={colorPickerVisibility}
      onClose={() => setColorPickerVisibility(false)}
      content={() => (
        <ChromePicker
          color={props.color}
          disableAlpha
          onChange={(color) => {
            calculateForeground(color.rgb)
            props.onChange(color);
          }}
        />
      )}
      trigger={(triggerProps) => (
        <button
          {...triggerProps}
          onClick={() => setColorPickerVisibility(!colorPickerVisibility)}
          style={{ background: props.color }}
          className="w-8 h-8 rounded-md flex items-center justify-center border"
        >
          <div style={{ color: `${foreground}` }}>
            <RiPaintFill />
          </div>
        </button>
      )}
    />
  );
};

export default ColorPicker;
