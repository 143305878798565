import React, { useState } from "react";
import Layout from "./components/structure/Layout";
import SucoPricingModal from "./components/SucoPricingModal";
import OfcoPricingModal from "./components/OfcoPricingModal";
import CocaPricingModal from "./components/CocaPricingModal";
import TakeOverDrawer from "./components/TakeOverDrawer";
import Button from "./components/Button";
import LoadingSpinner from "./components/LoadingSpinner";
import {
  Switch,
  Route,
  Redirect,
  useLocation,
  useHistory,
} from "react-router-dom";
import AssistantRouter from "./pages/support/AssistantRouter";
import OffersRouter from "./pages/offers/OffersRouter";
import SettingsRouter from "./pages/settings/SettingsRouter";
import PaymentsLoader from "./pages/misc/PaymentsLoader";
import { useWorkspace } from "./contexts/WorkspaceContext";
import { useAuth } from "./contexts/AuthenticationContext";
import { useGraphQl } from "./contexts/GraphqlClient";
import { useQuery } from "react-query";
import { queryBillingInfo } from "./api/billing";
import toaster from "toasted-notes";
import SucoOnboarding from "./pages/onboard/SucoOnboarding";
import OfcoOnboarding from "./pages/onboard/OfcoOnboarding";
import CocaOnboarding from "./pages/onboard/CocaOnboarding";
import CartRouter from "./pages/cart/CartRouter";
import useLanguageVersionManager from "./utilities/languageVersionManager";
// import ThankyouPage from "./previews/ThankyouPage";

import {
  Provider as ShopifyAppBridgeProvider,
  useAppBridge,
} from "@shopify/app-bridge-react";

function AuthorisedApp() {
  const workspace = useWorkspace();
  const location = useLocation();
  const history = useHistory();
  const graphQL = useGraphQl();
  const authState = useAuth();
  const [isSettingsDrawerVisible, setIsSettingsDrawerVisible] = useState(false);
  // the following useQuery hooks only fires if the respective app is installed
  const { data: sucoBillingInfo } = useQuery(
    "querySucoBillingInfo",
    () =>
      graphQL(queryBillingInfo, {
        appId: `${1}`, //  getting billing status for SUCO
        sourceId: `${workspace.data?.sourceid}`,
      }),
    {
      enabled: workspace.data.installedApps.find((item) => item.appid === 1),
    }
  );
  const { data: ofcoBillingInfo } = useQuery(
    "queryOfcoBillingInfo",
    () =>
      graphQL(queryBillingInfo, {
        appId: `${2}`, // getting billing status for OFCO
        sourceId: `${workspace.data?.sourceid}`,
      }),
    {
      enabled: workspace.data.installedApps.find((item) => item.appid === 2),
    }
  );
  const { data: cocaBillingInfo } = useQuery(
    "queryCocoBillingInfo",
    () =>
      graphQL(queryBillingInfo, {
        appId: `${3}`, // getting billing status for COCA
        sourceId: `${workspace.data?.sourceid}`,
      }),
    {
      enabled: workspace.data.installedApps.find((item) => item.appid === 3),
    }
  );

  const renderPricingModals = () => {
    if (workspace.data.currentAppID === 1 && sucoBillingInfo) {
      return (
        <SucoPricingModal
          title="Select a plan to start"
          // This modal only opens in routes other than payment-loader plus if the plansID is null
          isOpen={
            (!sucoBillingInfo?.billingInfo.planId ||
              sucoBillingInfo?.billingInfo.planId === 1) &&
            location.pathname !== "/payment-loader"
          }
          onClose={() =>
            toaster.notify("You have to select a plan to continue", {
              duration: 2000,
            })
          }
          nonClosable
        />
      );
    } else if (
      workspace.data.currentAppID === 2 &&
      location.pathname.search("get-started") === -1 &&
      ofcoBillingInfo
    ) {
      return (
        <OfcoPricingModal
          title="Choose a plan to continue."
          // This modal only opens in routes other than payment-loader plus if the plansID is null
          isOpen={
            (!ofcoBillingInfo?.billingInfo.planId ||
              ofcoBillingInfo?.billingInfo.planId === 7) &&
            location.pathname !== "/payment-loader"
          }
          onClose={() =>
            toaster.notify("You have to select a plan to continue", {
              duration: 2000,
            })
          }
          nonClosable
        />
      );
    } else if (
      workspace.data.currentAppID === 3 &&
      location.pathname.search("get-started") === -1 &&
      cocaBillingInfo
    ) {
      return (
        <CocaPricingModal
          title="Choose a plan to complete the setup"
          isOpen={
            (!cocaBillingInfo?.billingInfo.planId ||
              cocaBillingInfo?.billingInfo.planId === 11) &&
            location.pathname !== "/payment-loader"
          }
          onClose={() =>
            toaster.notify("You have to select a plan to continue", {
              duration: 2000,
            })
          }
          nonClosable
        />
      );
    }
  };

  const isOnboardingVisible = () => {
    if (
      (workspace.data.currentAppID === 1 &&
        workspace.data.suco_onboarding_progress === 0) ||
      (workspace.data.currentAppID === 2 &&
        workspace.data.ofco_onboarding_progress === 0) ||
      (workspace.data.currentAppID === 3 &&
        workspace.data.coca_onboarding_progress === 0)
    )
      return true;
    else return false;
  };

  if (workspace.data) {
    window.pendo.initialize({
      visitor: {
        id: workspace.data.userid,
      },

      account: {
        id: workspace.data.accountid,
      },
    });
    if (window.$crisp) {
      const $crisp = window.$crisp;
      const {
        data: {
          storeUrl,
          user: { email },
        },
      } = workspace;
      $crisp.push(["set", "session:data", [[["store_url", storeUrl]]]]);
      switch (workspace.data.currentAppID) {
        case 1: {
          $crisp.push([
            "set",
            "session:data",
            [[["app_name", "SupportCorner"]]],
          ]);
          break;
        }
        case 2: {
          $crisp.push([
            "set",
            "session:data",
            [[["app_name", "OffersCorner"]]],
          ]);
          break;
        }
        case 3: {
          $crisp.push(["set", "session:data", [[["app_name", "CornerCart"]]]]);
          break;
        }
      }

      $crisp.push(["set", "user:email", [email]]);
    }
  }

  // This hook makes sure the language JSONs are uptodate in the right version.
  useLanguageVersionManager();

  // This function finds the default page to load based on the currentAppID Value
  const findDefaultPage = () => {
    switch (workspace.data.currentAppID) {
      case 1:
        return <Redirect to="/support/" />;
      case 2:
        return <Redirect to="/offers/" />;
      case 3:
        return <Redirect to="/cart/" />;
    }
  };

  if (workspace.data && workspace.data.currentAppID) {
    let apiKey;
    switch (workspace.data.currentAppID) {
      case 1: {
        apiKey = `${process.env.REACT_APP_SUCO_API_KEY}`;
        break;
      }
      case 2: {
        apiKey = `${process.env.REACT_APP_OFCO_API_KEY}`;
        break;
      }
      case 3: {
        apiKey = `${process.env.REACT_APP_COCA_API_KEY}`;
        break;
      }
    }

    return (
      <ShopifyAppBridgeProvider
        config={{ apiKey: apiKey, host: authState.host, forceRedirect: true }}
      >
        {renderPricingModals()}
        <Switch>
          <Route exact path="/payment-loader" component={PaymentsLoader} />
          {isOnboardingVisible() ? (
            <>
              <Route path="/">
                <Redirect to="/get-started/" />
              </Route>
              <Route
                path="/get-started"
                component={() => {
                  switch (workspace.data.currentAppID) {
                    case 1:
                      return <SucoOnboarding />;
                    case 2:
                      return <OfcoOnboarding />;
                    case 3:
                      return <CocaOnboarding />;
                  }
                }}
              />
            </>
          ) : (
            <>
              <Layout>
                <Switch>
                  <Route path="/support" component={AssistantRouter} />
                  <Route path="/settings" component={SettingsRouter} />
                  <Route path="/cart" component={CartRouter} />
                  <Route path="/offers" component={OffersRouter} />
                  <Route path="/">{findDefaultPage}</Route>
                </Switch>
              </Layout>
            </>
          )}
        </Switch>
      </ShopifyAppBridgeProvider>
    );
  } else return <LoadingSpinner />;
}

export default AuthorisedApp;
