import React, { useState } from "react";
import toaster from "toasted-notes";
import CustomiserUnit from "../../../../components/CustomiserUnit";
import CollectionProductExtractor from "../../../../components/CollectionProductExtractor";
import TextEditor from "../../../../components/TextEditor/index";
import Textfield from "@atlaskit/textfield";
import { useWorkspace } from "../../../../contexts/WorkspaceContext";
import Datetime from "react-datetime";
import moment from "moment";


/**
 * Settings Tab
 * @component
 * @param {Object} values - Contains all formik values
 * @param {Function} setFieldValue - setFieldValue funtion of formik
 */
const Settings = ({ values, setFieldValue }) => {


  return (
    <div className="w-full py-3 px-8">
      <CustomiserUnit
        title="Campaign Name"
        description="Give this campaign a name for internal tracking"
      >
        <div>
          <Textfield
            value={values.name}
            onChange={(onChangeEvent) => {
              setFieldValue("name", onChangeEvent.target.value);
            }}
            name="basic"
          />
        </div>
      </CustomiserUnit>

      <CustomiserUnit
        title="Blocked Collection"
        description="To prevent this campaign popup from rendering for products in certain collection add the collection handle here."
      >
        <CollectionProductExtractor
          value={values.blocked_collections}
          onChange={(changedValue) => {
            setFieldValue("blocked_collections", changedValue);
          }}
        />
        {/* <FieldArray
          name="blocked_collections"
          render={(arrayHelpers) => (
            <ArrayInput
              addButtonText="Add Collection Handles"
              onAddition={(value) => {
                console.log(arrayHelpers);
                arrayHelpers.push(value);
              }}
              onDeletion={(index) => arrayHelpers.remove(index)}
              arrayItems={
                values.blocked_collections ? values.blocked_collections : []
              }
            />
          )}
        /> */}
      </CustomiserUnit>

      <CustomiserUnit
        title="Campaign Duration"
        description="How long will this campaign last"
      >
        <div className="flex items-center justify-between max-w-lg">
          <div className=" mr-5">
            <small className="text-xs ">START DATE</small>
            <div>
              <Datetime
                dateFormat="DD/MM/YYYY"
                value={new Date(values.starts_at)}
                onChange={(onChangeEvent) => {
                  if (moment(onChangeEvent).isBefore(moment(values.expires_at)))
                    setFieldValue("starts_at", onChangeEvent.toISOString());
                  else
                    toaster.notify(
                      "Please choose a date which comes before the End Date",
                      {
                        duration: 5000,
                      }
                    );
                }}
                inputProps={{
                  className:
                    "desktop:p-3 laptop:p-2 mobile:p-1 bg-gray-200 border rounded-md text-sm z-50",
                  readOnly: true,
                }}
              />
            </div>
          </div>
          <div className=" mr-5">
            <small className="text-xs ">END DATE</small>
            <div>
              <Datetime
                dateFormat="DD/MM/YYYY"
                value={new Date(values.expires_at)}
                onChange={(onChangeEvent) => {
                  if (moment(onChangeEvent).isAfter(moment(values.starts_at)))
                    setFieldValue("expires_at", onChangeEvent.toISOString());
                  else
                    toaster.notify(
                      "Please choose a date which comes after the Start Date",
                      {
                        duration: 5000,
                      }
                    );
                }}
                inputProps={{
                  className:
                    "desktop:p-3 laptop:p-2 mobile:p-1 bg-gray-200 border rounded-md text-sm z-50",
                  readOnly: true,
                }}
              />
            </div>
          </div>
        </div>
      </CustomiserUnit>

      <CustomiserUnit
        title="Offer Terms"
        description="This is the place where you can write offer terms if you have any."
      >
        <div className="border p-3 rounded-lg">
          <TextEditor
            basicEditor
            content={values.terms_config}
            onChange={(htmlValue) => setFieldValue("terms_config", htmlValue)}
          />
        </div>
      </CustomiserUnit>

      {values.campaign && (
        <CustomiserUnit
          title="Campaign Priority"
          description="Campaign with the higher priority number will be shown as the active campaign in the website. This setting is used if you have multiple active referral campaigns"
        >
          <div className="w-24">
            <Textfield
              value={values.campaign.priority}
              onChange={(onChangeEvent) => {
                if (parseInt(onChangeEvent.target.value, 10) > 0)
                  setFieldValue(
                    "campaign.priority",
                    parseInt(onChangeEvent.target.value, 10)
                  );
              }}
              type="number"
              name="basic"
            />
          </div>
        </CustomiserUnit>
      )}
    </div>
  );
};

export default Settings;
