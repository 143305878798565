const langOptions = [
  { label: "Arabic", value: "ar" },
  { label: "Danish", value: "da" },
  { label: "German", value: "de" },
  { label: "English", value: "en" },
  { label: "Espanol", value: "es" },
  { label: "Finnish", value: "fi" },
  { label: "French", value: "fr" },
  { label: "Hindi", value: "hi" },
  { label: "Hungarian", value: "hu" },
  { label: "Italian", value: "it" },
  { label: "Japanese", value: "ja" },
  { label: "Korean", value: "ko" },
  { label: "Dutch", value: "nl" },
  { label: "Polish", value: "pl" },
  { label: "Portuguese", value: "pt-BR" },
  { label: "Swedish", value: "sv" },
  { label: "Thai", value: "th" },
  { label: "Ukrainian", value: "uk" },
  { label: "Chinese", value: "zh-CN" },
  { label: "Taiwanese", value: "zh-TW" },
];

// Update these values whenever language versions are updated
const latestLangVersions = {
  cornerLang: 2.0,
  faqLang: 2.0,
  offerPageLang: 2.0,
  offerWidgetLang: 2.0,
  cartCowiLang: 2.0,
  stickybarLang: 3.0,
};

const suportedCurrencies = [
  {
    code: "AED",
    label: "United Arab Emirates Dirham (AED)",
    value: "AED {{amount}}",
  },
  {
    code: "AFN",
    label: "Afghan Afghani (AFN)",
    value: "؋ {{amount}}",
  },
  {
    code: "ALL",
    label: "Albanian Lek (ALL)",
    value: "Lek {{amount}}",
  },
  {
    code: "AMD",
    label: "Armenian Dram (AMD)",
    value: "դր. {{amount}}",
  },
  {
    code: "ANG",
    label: "Netherlands Antillean Guilder (ANG)",
    value: "ANG {{amount}}",
  },
  {
    code: "AOA",
    label: "Kwanza (AOA)",
    value: "AOA {{amount}}",
  },
  {
    code: "ARS",
    label: "Argentine Peso (ARS)",
    value: "$ {{amount}}",
  },
  {
    code: "AUD",
    label: "Australian Dollar (AUD)",
    value: "$ {{amount}}",
  },
  {
    code: "AWG",
    label: "Aruban Florin (AWG)",
    value: "AWG {{amount}}",
  },
  {
    code: "AZN",
    label: "Azerbaijani Manat (AZN)",
    value: "ман. {{amount}}",
  },
  {
    code: "BAM",
    label: "Bosnia-Herzegovina Convertible Mark (BAM)",
    value: "KM {{amount}}",
  },
  {
    code: "BBD",
    label: "Barbados Dollar (BBD)",
    value: "BBD {{amount}}",
  },
  {
    code: "BDT",
    label: "Bangladeshi Taka (BDT)",
    value: "৳ {{amount}}",
  },
  {
    code: "BGN",
    label: "Bulgarian Lev (BGN)",
    value: "лв. {{amount}}",
  },
  {
    code: "BIF",
    label: "Burundian Franc (BIF)",
    value: "FBu {{amount}}",
  },
  {
    code: "BMD",
    label: "Bermudian Dollar (BMD)",
    value: "BMD {{amount}}",
  },
  {
    code: "BND",
    label: "Brunei Dollar (BND)",
    value: "$ {{amount}}",
  },
  {
    code: "BOB",
    label: "Bolivian Boliviano (BOB)",
    value: "Bs {{amount}}",
  },
  {
    code: "BRL",
    label: "Brazilian Real (BRL)",
    value: "R$ {{amount}}",
  },
  {
    code: "BSD",
    label: "Bahamian Dollar (BSD)",
    value: "BSD {{amount}}",
  },
  {
    code: "BWP",
    label: "Botswanan Pula (BWP)",
    value: "P {{amount}}",
  },
  {
    code: "BZD",
    label: "Belize Dollar (BZD)",
    value: "$ {{amount}}",
  },
  {
    code: "CAD",
    label: "Canadian Dollar (CAD)",
    value: "$ {{amount}}",
  },
  {
    code: "CDF",
    label: "Congolese Franc (CDF)",
    value: "FrCD {{amount}}",
  },
  {
    code: "CHF",
    label: "Swiss Franc (CHF)",
    value: "CHF {{amount}}",
  },
  {
    code: "CLP",
    label: "Chilean Peso (CLP)",
    value: "$ {{amount}}",
  },
  {
    code: "CNY",
    label: "Chinese Yuan (CNY)",
    value: "CN¥ {{amount}}",
  },
  {
    code: "COP",
    label: "Colombian Peso (COP)",
    value: "$ {{amount}}",
  },
  {
    code: "CRC",
    label: "Costa Rican Colón (CRC)",
    value: "₡ {{amount}}",
  },
  {
    code: "CVE",
    label: "Cape Verdean Escudo (CVE)",
    value: "CV$ {{amount}}",
  },
  {
    code: "CZK",
    label: "Czech Republic Koruna (CZK)",
    value: "Kč {{amount}}",
  },
  {
    code: "DJF",
    label: "Djiboutian Franc (DJF)",
    value: "Fdj {{amount}}",
  },
  {
    code: "DKK",
    label: "Danish Krone (DKK)",
    value: "kr {{amount}}",
  },
  {
    code: "DOP",
    label: "Dominican Peso (DOP)",
    value: "RD$ {{amount}}",
  },
  {
    code: "DZD",
    label: "Algerian Dinar (DZD)",
    value: "DA {{amount}}",
  },
  {
    code: "EGP",
    label: "Egyptian Pound (EGP)",
    value: "EGP {{amount}}",
  },
  {
    code: "ETB",
    label: "Ethiopian Birr (ETB)",
    value: "Br {{amount}}",
  },
  {
    code: "EUR",
    label: "Euro (EUR)",
    value: "€ {{amount}}",
  },
  {
    code: "FJD",
    label: "Fiji Dollar (FJD)",
    value: "FJD {{amount}}",
  },
  {
    code: "FKP",
    label: "Falkland Islands Pound (FKP)",
    value: "FKP {{amount}}",
  },
  {
    code: "GBP",
    label: "British Pound Sterling (GBP)",
    value: "£ {{amount}}",
  },
  {
    code: "GEL",
    label: "Georgian Lari (GEL)",
    value: "GEL {{amount}}",
  },
  {
    code: "GIP",
    label: "GIP",
    value: "GIP {{amount}}",
  },
  {
    code: "GMD",
    label: "GMD",
    value: "GMD {{amount}}",
  },
  {
    code: "GNF",
    label: "Guinean Franc (GNF)",
    value: "FG {{amount}}",
  },
  {
    code: "GTQ",
    label: "Guatemalan Quetzal (GTQ)",
    value: "Q {{amount}}",
  },
  {
    code: "GYD",
    label: "GYD",
    value: "GYD {{amount}}",
  },
  {
    code: "HKD",
    label: "Hong Kong Dollar (HKD)",
    value: "$ {{amount}}",
  },
  {
    code: "HNL",
    label: "Honduran Lempira (HNL)",
    value: "L {{amount}}",
  },
  {
    code: "HRK",
    label: "Croatian Kuna (HRK)",
    value: "kn {{amount}}",
  },
  {
    code: "HTG",
    label: "Gourde (HTG)",
    value: "HTG {{amount}}",
  },
  {
    code: "HUF",
    label: "Hungarian Forint (HUF)",
    value: "Ft {{amount}}",
  },
  {
    code: "IDR",
    label: "Indonesian Rupiah (IDR)",
    value: "Rp {{amount}}",
  },
  {
    code: "ILS",
    label: "Israeli New Sheqel (ILS)",
    value: "₪ {{amount}}",
  },
  {
    code: "INR",
    label: "Indian Rupee (INR)",
    value: "₹ {{amount}}",
  },
  {
    code: "ISK",
    label: "Icelandic Króna (ISK)",
    value: "kr {{amount}}",
  },
  {
    code: "JMD",
    label: "Jamaican Dollar (JMD)",
    value: "$ {{amount}}",
  },
  {
    code: "JPY",
    label: "Japanese Yen (JPY)",
    value: "￥ {{amount}}",
  },
  {
    code: "KES",
    label: "Kenyan Shilling (KES)",
    value: "Ksh {{amount}}",
  },
  {
    code: "KGS",
    label: "Som (KGS)",
    value: "KGS {{amount}}",
  },
  {
    code: "KHR",
    label: "Cambodian Riel (KHR)",
    value: "៛ {{amount}}",
  },
  {
    code: "KMF",
    label: "Comorian Franc (KMF)",
    value: "FC {{amount}}",
  },
  {
    code: "KRW",
    label: "South Korean Won (KRW)",
    value: "₩ {{amount}}",
  },
  {
    code: "KYD",
    label: "KYD",
    value: "KYD {{amount}}",
  },
  {
    code: "KZT",
    label: "Kazakhstani Tenge (KZT)",
    value: "тңг. {{amount}}",
  },
  {
    code: "LAK",
    label: "Lao Kip (LAK)",
    value: "LAK {{amount}}",
  },
  {
    code: "LBP",
    label: "Lebanese Pound (LBP)",
    value: "L.L. {{amount}}",
  },
  {
    code: "LKR",
    label: "Sri Lankan Rupee (LKR)",
    value: "SL Re {{amount}}",
  },
  {
    code: "LRD",
    label: "Liberian Dollar (LRD)",
    value: "LRD {{amount}}",
  },
  {
    code: "LSL",
    label: "Loti (LSL)",
    value: "LSL {{amount}}",
  },
  {
    code: "MAD",
    label: "Moroccan Dirham (MAD)",
    value: "د.م.‏ {{amount}}",
  },
  {
    code: "MDL",
    label: "Moldovan Leu (MDL)",
    value: "MDL {{amount}}",
  },
  {
    code: "MGA",
    label: "Malagasy Ariary (MGA)",
    value: "MGA {{amount}}",
  },
  {
    code: "MKD",
    label: "Macedonian Denar (MKD)",
    value: "MKD {{amount}}",
  },
  {
    code: "MMK",
    label: "Myanma Kyat (MMK)",
    value: "K {{amount}}",
  },
  {
    code: "MNT",
    label: "Tugrik (MNT)",
    value: "MNT {{amount}}",
  },
  {
    code: "MOP",
    label: "Macanese Pataca (MOP)",
    value: "MOP$ {{amount}}",
  },
  {
    code: "MUR",
    label: "Mauritian Rupee (MUR)",
    value: "MURs {{amount}}",
  },
  {
    code: "MVR",
    label: "Rufiyaa (MVR)",
    value: "MVR {{amount}}",
  },
  {
    code: "MWK",
    label: "Malawi Kwacha (MWK)",
    value: "MWK {{amount}}",
  },
  {
    code: "MXN",
    label: "Mexican Peso (MXN)",
    value: "$ {{amount}}",
  },
  {
    code: "MYR",
    label: "Malaysian Ringgit (MYR)",
    value: "RM {{amount}}",
  },
  {
    code: "MZN",
    label: "Mozambican Metical (MZN)",
    value: "MTn {{amount}}",
  },
  {
    code: "NAD",
    label: "Namibian Dollar (NAD)",
    value: "N$ {{amount}}",
  },
  {
    code: "NGN",
    label: "Nigerian Naira (NGN)",
    value: "₦ {{amount}}",
  },
  {
    code: "NIO",
    label: "Nicaraguan Córdoba (NIO)",
    value: "C$ {{amount}}",
  },
  {
    code: "NOK",
    label: "Norwegian Krone (NOK)",
    value: "kr {{amount}}",
  },
  {
    code: "NPR",
    label: "Nepalese Rupee (NPR)",
    value: "नेरू {{amount}}",
  },
  {
    code: "NZD",
    label: "New Zealand Dollar (NZD)",
    value: "$ {{amount}}",
  },
  {
    code: "PAB",
    label: "Panamanian Balboa (PAB)",
    value: "B/. {{amount}}",
  },
  {
    code: "PEN",
    label: "Peruvian Nuevo Sol (PEN)",
    value: "S/. {{amount}}",
  },
  {
    code: "PGK",
    label: "Kina (PGK)",
    value: "PGK {{amount}}",
  },
  {
    code: "PHP",
    label: "Philippine Peso (PHP)",
    value: "₱ {{amount}}",
  },
  {
    code: "PKR",
    label: "Pakistani Rupee (PKR)",
    value: "₨ {{amount}}",
  },
  {
    code: "PLN",
    label: "Polish Zloty (PLN)",
    value: "zł {{amount}}",
  },
  {
    code: "PYG",
    label: "Paraguayan Guarani (PYG)",
    value: "₲ {{amount}}",
  },
  {
    code: "QAR",
    label: "Qatari Rial (QAR)",
    value: "QR {{amount}}",
  },
  {
    code: "RON",
    label: "Romanian Leu (RON)",
    value: "RON {{amount}}",
  },
  {
    code: "RSD",
    label: "Serbian Dinar (RSD)",
    value: "дин. {{amount}}",
  },
  {
    code: "RUB",
    label: "Russian Ruble (RUB)",
    value: "₽. {{amount}}",
  },
  {
    code: "RWF",
    label: "Rwandan Franc (RWF)",
    value: "FR {{amount}}",
  },
  {
    code: "SAR",
    label: "Saudi Riyal (SAR)",
    value: "SR ‏ {{amount}}",
  },
  {
    code: "SBD",
    label: "SBD",
    value: "SBD {{amount}}",
  },
  {
    code: "SCR",
    label: "SCR",
    value: "SCR {{amount}}",
  },
  {
    code: "SEK",
    label: "Swedish Krona (SEK)",
    value: "kr {{amount}}",
  },
  {
    code: "SGD",
    label: "Singapore Dollar (SGD)",
    value: "$ {{amount}}",
  },
  {
    code: "SHP",
    label: "Saint Helena Pound (SHP)",
    value: "SHP {{amount}}",
  },
  {
    code: "SLL",
    label: "Leone (SLL)",
    value: "SLL {{amount}}",
  },
  {
    code: "SRD",
    label: "Surinam Dollar (SRD)",
    value: "SRD {{amount}}",
  },
  {
    code: "STD",
    label: "Dobra (STD)",
    value: "STD {{amount}}",
  },
  {
    code: "SZL",
    label: "Lilangeni (SZL)",
    value: "SZL {{amount}}",
  },
  {
    code: "THB",
    label: "Thai Baht (THB)",
    value: "฿ {{amount}}",
  },
  {
    code: "TJS",
    label: "Somoni (TJS)",
    value: "TJS {{amount}}",
  },
  {
    code: "TOP",
    label: "Tongan Paʻanga (TOP)",
    value: "T$ {{amount}}",
  },
  {
    code: "TRY",
    label: "Turkish Lira (TRY)",
    value: "TL {{amount}}",
  },
  {
    code: "TTD",
    label: "Trinidad and Tobago Dollar (TTD)",
    value: "$ {{amount}}",
  },
  {
    code: "TWD",
    label: "New Taiwan Dollar (TWD)",
    value: "NT$ {{amount}}",
  },
  {
    code: "TZS",
    label: "Tanzanian Shilling (TZS)",
    value: "TSh {{amount}}",
  },
  {
    code: "UAH",
    label: "Ukrainian Hryvnia (UAH)",
    value: "₴ {{amount}}",
  },
  {
    code: "UGX",
    label: "Ugandan Shilling (UGX)",
    value: "USh {{amount}}",
  },
  {
    code: "USD",
    label: "US Dollar (USD)",
    value: "$ {{amount}}",
  },
  {
    code: "UYU",
    label: "Uruguayan Peso (UYU)",
    value: "$ {{amount}}",
  },
  {
    code: "UZS",
    label: "Uzbekistan Som (UZS)",
    value: "UZS {{amount}}",
  },
  {
    code: "VND",
    label: "Vietnamese Dong (VND)",
    value: "₫ {{amount}}",
  },
  {
    code: "VUV",
    label: "Vatu (VUV)",
    value: "VUV {{amount}}",
  },
  {
    code: "WST",
    label: "Tala (WST)",
    value: "WST {{amount}}",
  },
  {
    code: "XAF",
    label: "CFA Franc BEAC (XAF)",
    value: "FCFA {{amount}}",
  },
  {
    code: "XCD",
    label: "East Caribbean Dollar (XCD)",
    value: "XCD {{amount}}",
  },
  {
    code: "XOF",
    label: "CFA Franc BCEAO (XOF)",
    value: "CFA {{amount}}",
  },
  {
    code: "XPF",
    label: "CFP Franc (XPF)",
    value: "XPF {{amount}}",
  },
  {
    code: "YER",
    label: "Yemeni Rial (YER)",
    value: "YR {amount}}",
  },
  {
    code: "ZAR",
    label: "South African Rand (ZAR)",
    value: "R {{amount}}",
  },
  {
    code: "ZMW",
    label: "Zambian Kwacha (ZMW)",
    value: "ZMW {{amount}}",
  },
];

const cornerWidgetThemes = [
  {
    name: "Sharp Edge",
    type: "light",
    themeObject: {
      colors: {
        primaryType: "rgb(44,54,57)",
        outlines: "rgb(220,215,214)",
        accentBg: "rgb(5,5,6)",
        accentFg: "rgb(255,255,255)",
        secondaryType: "rgb(63,78,79)",
        cardBg: "rgb(255,255,255)",
        widgetBg: "rgb(247,247,247)",
      },
      banner: {
        gradientStart: "rgb(147,183,255)",
        gradientEnd: "rgb(52,59,82)",
        imageUrl:
          "https://imagedelivery.net/QI2wC6Vls23vJd9vFFaomg/e11aea3c-53a7-4138-451a-bd6e30552c00/public",
        type: "image",
      },
      customCss:
        ".widgets-by-corner.corner-widget #corner-cowi-open-wrapper * {border-radius:0px!important}",
    },
  },
  {
    name: "Poseidon",
    type: "dark",
    themeObject: {
      colors: {
        primaryType: "rgb(173,222,253)",
        outlines: "rgb(65,80,140)",
        accentBg: "rgb(11,242,255)",
        accentFg: "rgb(4,45,22)",
        secondaryType: "rgb(152,166,197)",
        cardBg: "rgb(5,21,47)",
        widgetBg: "rgb(8,32,68)",
      },
      banner: {
        gradientStart: "rgb(63,83,146)",
        gradientEnd: "rgb(63,83,146)",
        imageUrl:
          "https://imagedelivery.net/QI2wC6Vls23vJd9vFFaomg/b394f672-7fce-4ec9-23c6-999cd7d8f900/public",
        type: "image",
      },
      customCss: "#cowi-corner-header-context-title{color:white!important}",
    },
  },
  {
    name: "Sand Dunes",
    type: "light",
    themeObject: {
      colors: {
        primaryType: "rgb(44,54,57)",
        outlines: "rgb(212,208,187)",
        accentBg: "rgb(162,123,92)",
        accentFg: "rgb(255,255,255)",
        secondaryType: "rgb(63,78,79)",
        cardBg: "rgb(255,253,249)",
        widgetBg: "rgb(230,227,221)",
      },
      banner: {
        gradientStart: "rgb(147,183,255)",
        gradientEnd: "rgb(52,59,82)",
        imageUrl:
          "https://imagedelivery.net/QI2wC6Vls23vJd9vFFaomg/e4ccee5c-c84c-493a-bdb7-2fb3eeedf800/public",
        type: "image",
      },
      customCss: "",
    },
  },
  {
    name: "Bubblegum",
    type: "light",
    themeObject: {
      colors: {
        primaryType: "rgb(59,62,86)",
        outlines: "rgb(189,197,178)",
        accentBg: "rgb(255,145,157)",
        accentFg: "rgb(92,11,48)",
        secondaryType: "rgb(156,168,180)",
        cardBg: "rgb(255,255,255)",
        widgetBg: "rgb(255,241,246)",
      },
      banner: {
        gradientStart: "rgb(147,183,255)",
        gradientEnd: "rgb(63,105,255)",
        imageUrl:
          "https://imagedelivery.net/QI2wC6Vls23vJd9vFFaomg/e95c4721-55e8-472f-9413-d18e30ecee00/public",
        type: "image",
      },
      customCss:
        ".widgets-by-corner .bg-cowi-accent-bg{box-shadow:1px -10px 20px -8px #e28da1!important;} .corner-widget .rounded-lg{border-radius:100px !important;} .widgets-by-corner .rounded-xl {border-radius: 1.95rem !important;} .widgets-by-corner .drop-shadow {box-shadow: 4px 5px 5px 0px rgba(226, 193, 199, .19), -4px -4px 8px #ffffff63 !important;}",
    },
  },
  {
    name: "Cape Town",
    type: "light",
    themeObject: {
      colors: {
        primaryType: "rgb(9,27,107)",
        outlines: "rgb(230,230,235)",
        accentBg: "rgb(179,57,57)",
        accentFg: "rgb(255,255,255)",
        secondaryType: "rgb(86,100,113)",
        cardBg: "rgb(255,255,255)",
        widgetBg: "rgb(246,241,238)",
      },
      banner: {
        gradientStart: "rgb(185,59,15)",
        gradientEnd: "rgb(216,0,0)",
        imageUrl:
          "https://imagedelivery.net/QI2wC6Vls23vJd9vFFaomg/971fc57c-19f3-466c-1e20-d625fcd0e800/public",
        type: "image",
      },
      customCss: "",
    },
  },
  {
    name: "Blackout",
    type: "dark",
    themeObject: {
      colors: {
        primaryType: "rgb(237,241,243)",
        outlines: "rgb(65,65,65)",
        accentBg: "rgb(255,255,255)",
        accentFg: "rgb(10,10,10)",
        secondaryType: "rgb(63,78,79)",
        cardBg: "rgb(24,24,24)",
        widgetBg: "rgb(0,0,0)",
      },
      banner: {
        gradientStart: "rgb(147,183,255)",
        gradientEnd: "rgb(52,59,82)",
        imageUrl:
          "https://imagedelivery.net/QI2wC6Vls23vJd9vFFaomg/0a8f19e2-8450-4667-2646-b8e8798db100/public",
        type: "image",
      },
      customCss: "#cowi-corner-header-context-title{color:white!important}",
    },
  },
  {
    name: "Urban Underground",
    type: "dark",
    themeObject: {
      colors: {
        primaryType: "rgb(233,227,234)",
        outlines: "rgb(90,79,96)",
        accentBg: "rgb(124,29,183)",
        accentFg: "rgb(255,255,255)",
        secondaryType: "rgb(115,117,125)",
        cardBg: "rgb(53,45,67)",
        widgetBg: "rgb(36,29,51)",
      },
      banner: {
        gradientStart: "rgb(147,183,255)",
        gradientEnd: "rgb(52,59,82)",
        imageUrl:
          "https://imagedelivery.net/QI2wC6Vls23vJd9vFFaomg/62581c93-c814-4e9c-f313-aa62435cb300/public",
        type: "image",
      },
      customCss: "",
    },
  },
  {
    name: "Cyber Pink",
    type: "dark",
    themeObject: {
      colors: {
        primaryType: "rgb(255,255,255)",
        outlines: "rgb(97,97,109)",
        accentBg: "rgb(222,71,128)",
        accentFg: "rgb(251,251,251)",
        secondaryType: "rgb(144,137,185)",
        cardBg: "rgb(55,44,76)",
        widgetBg: "rgb(40,40,51)",
      },
      banner: {
        gradientStart: "rgb(147,183,255)",
        gradientEnd: "rgb(63,105,255)",
        imageUrl:
          "https://imagedelivery.net/QI2wC6Vls23vJd9vFFaomg/d24d4bd1-d6a3-44e9-d538-0a51cb4eee00/public",
        type: "image",
      },
      customCss: " .corner-widget .rounded-lg{border-radius:100px !important;}",
    },
  },
  {
    name: "Key Lime Pie",
    type: "dark",
    themeObject: {
      colors: {
        primaryType: "rgb(241,255,137)",
        outlines: "rgb(97,97,109)",
        accentBg: "rgb(217,255,54)",
        accentFg: "rgb(18,15,15)",
        secondaryType: "rgb(144,137,185)",
        cardBg: "rgb(48,49,40)",
        widgetBg: "rgb(41,41,41)",
      },
      banner: {
        gradientStart: "rgb(147,183,255)",
        gradientEnd: "rgb(63,105,255)",
        imageUrl:
          "https://imagedelivery.net/QI2wC6Vls23vJd9vFFaomg/bbcd8567-0925-45e5-611e-cd1a1254fe00/public",
        type: "image",
      },
      customCss: "",
    },
  },
  {
    name: "Lemonade",
    type: "light",
    themeObject: {
      colors: {
        primaryType: "rgb(57,61,19)",
        outlines: "rgb(189,197,178)",
        accentBg: "rgb(253,215,103)",
        accentFg: "rgb(86,77,45)",
        secondaryType: "rgb(123,131,80)",
        cardBg: "rgb(255,252,238)",
        widgetBg: "rgb(255,255,255)",
      },
      banner: {
        gradientStart: "rgb(147,183,255)",
        gradientEnd: "rgb(63,105,255)",
        imageUrl:
          "https://imagedelivery.net/QI2wC6Vls23vJd9vFFaomg/ad025384-beb1-4140-f373-3acd7041a000/public",
        type: "image",
      },
      customCss: " .corner-widget .rounded-lg{border-radius:100px !important;}",
    },
  },
  {
    name: "Nile",
    type: "dark",
    themeObject: {
      colors: {
        primaryType: "rgb(253,225,194)",
        outlines: "rgb(100,98,94)",
        accentBg: "rgb(188,127,64)",
        accentFg: "rgb(255,255,255)",
        secondaryType: "rgb(168,158,150)",
        cardBg: "rgb(27,53,35)",
        widgetBg: "rgb(38,65,45)",
      },
      banner: {
        gradientStart: "rgb(147,183,255)",
        gradientEnd: "rgb(52,59,82)",
        imageUrl:
          "https://imagedelivery.net/QI2wC6Vls23vJd9vFFaomg/a0ec8023-a951-453e-aef0-d41bae303b00/public",
        type: "image",
      },
      customCss: "",
    },
  },
  {
    name: "Lavender",
    type: "light",
    themeObject: {
      colors: {
        primaryType: "rgb(59,62,86)",
        outlines: "rgb(189,197,178)",
        accentBg: "rgb(153,139,221)",
        accentFg: "rgb(255,255,255)",
        secondaryType: "rgb(156,168,180)",
        cardBg: "rgb(255,255,255)",
        widgetBg: "rgb(241,244,255)",
      },
      banner: {
        gradientStart: "rgb(147,183,255)",
        gradientEnd: "rgb(63,105,255)",
        imageUrl:
          "https://imagedelivery.net/QI2wC6Vls23vJd9vFFaomg/51d563dc-ccd7-4bec-ac93-2d97aff0d300/public",
        type: "image",
      },
      customCss:
        ".widgets-by-corner .bg-cowi-accent-bg{box-shadow:1px -10px 20px -8px #9a8de2bf!important;} .corner-widget .rounded-lg{border-radius:100px !important;} .widgets-by-corner .rounded-xl {border-radius: 1.95rem !important;} .widgets-by-corner .drop-shadow {box-shadow: 4px 5px 5px 0px #bbb7d140, -4px -4px 8px #ffffff63 !important;}",
    },
  },
  {
    name: "Magma Lake",
    type: "dark",
    themeObject: {
      colors: {
        primaryType: "rgb(255,254,254)",
        outlines: "rgb(80,64,70)",
        accentBg: "rgb(226,45,45)",
        accentFg: "rgb(255,255,255)",
        secondaryType: "rgb(251,231,231)",
        cardBg: "rgb(41,25,25)",
        widgetBg: "rgb(10,9,9)",
      },
      banner: {
        gradientStart: "rgb(147,183,255)",
        gradientEnd: "rgb(63,105,255)",
        imageUrl:
          "https://imagedelivery.net/QI2wC6Vls23vJd9vFFaomg/7afc6ada-c6cc-46de-59ec-7c826bf8df00/public",
        type: "image",
      },
      customCss:
        ".widgets-by-corner .bg-cowi-accent-bg{box-shadow: 0px -6px 65px -8px #ff3059!important;}",
    },
  },
  {
    name: "Smooth Silk",
    type: "light",
    themeObject: {
      colors: {
        primaryType: "rgb(86,59,65)",
        outlines: "rgb(189,197,178)",
        accentBg: "rgb(220,166,169)",
        accentFg: "rgb(78,47,48)",
        secondaryType: "rgb(180,156,171)",
        cardBg: "rgb(255,255,255)",
        widgetBg: "rgb(251,243,243)",
      },
      banner: {
        gradientStart: "rgb(147,183,255)",
        gradientEnd: "rgb(63,105,255)",
        imageUrl:
          "https://imagedelivery.net/QI2wC6Vls23vJd9vFFaomg/2e24ce08-d6a5-45f8-5a45-7fa273176e00/public",
        type: "image",
      },
      customCss:
        ".widgets-by-corner .bg-cowi-accent-bg{box-shadow:1px -10px 20px -8px #dca6a9 !important;}  .widgets-by-corner .drop-shadow {box-shadow: 4px 5px 5px 0px rgba(209, 183, 191, .25), -4px -4px 8px #ffffff !important}",
    },
  },
];

export {
  langOptions,
  latestLangVersions,
  suportedCurrencies,
  cornerWidgetThemes,
};
