import React, { useState, useEffect } from "react";

import CocaSession from "../session";
/* AuthContext's primary responsibility is to supply
 * JWT tokens needed by the app to authenticate calls
 * to our graphql endpoint.
 */

const defaultData = {};
const AuthenticationContext = React.createContext(defaultData);

const AuthenticationProvider = (props) => {
  const urlParams = new URLSearchParams(window.location.search);
  const [authState, setAuthState] = useState({
    status: "loading",
    token: "",
    reason: "",
  });

  const parseParams = () => {
    // parse query string
    const params = new URLSearchParams(window.location.search);

    const obj = {};

    // iterate over all keys
    for (const key of params.keys()) {
      if (params.getAll(key).length > 1) {
        obj[key] = params.getAll(key);
      } else {
        obj[key] = params.get(key);
      }
    }

    return obj;
  };
  console.log("host -------------------->", parseParams());

  useEffect(() => {
    let customToken = urlParams.get("token") || CocaSession.getJWTToken();
    const refreshToken =
      urlParams.get("rf_token") || CocaSession.getRefreshToken();
    console.log(`customToken ----> `, customToken);
    console.log(`refreshToken ----> `, refreshToken);
    if (!customToken || !refreshToken) {
      setAuthState({ status: "out", token: "", reason: "NO_TOKEN" });
    } else {
      const tenantId = urlParams.get("tenant_id");
      const isHasuraClaimExist = CocaSession.isHasuraClaimsExists(customToken);
      console.log(`isHasuraClaimExist ----> `, isHasuraClaimExist);
      if (customToken && isHasuraClaimExist && refreshToken) {
        CocaSession.setJWTToken(customToken);
        CocaSession.setRefreshToken(refreshToken);
        setAuthState({
          status: "in",
          token: customToken,
          reason: "",
          host: urlParams.get("host"),
        });
      } else {
        setAuthState({ status: "out", token: "", reason: "NO_TOKEN" });
      }
    }
  }, []);
  console.log("authStat", authState);
  return <AuthenticationContext.Provider value={authState} {...props} />;
};
const useAuth = () => React.useContext(AuthenticationContext);

export { AuthenticationProvider, useAuth };
