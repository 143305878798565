import React, { useEffect } from "react";
import { Switch, Route, useRouteMatch, Redirect } from "react-router-dom";
import LanguageSelector from "./LanguageSelector";
import PersonalInfo from "./PersonalInfo";
import ColorSelect from "./ColorSelect";
import FinalStep from "./FinalStep";
import useInitialCurrencyManager from "../../../utilities/initialCurrencyManager";

export default function CocaOnboarding() {
  let { path } = useRouteMatch();
  useInitialCurrencyManager();

  return (
    <div className="flex bg-gray-100 h-screen items-center justify-center">
      <div className=" shrink-0  px-7 py-8 flex  justify-center ">
        <Switch>
          <Route exact path={`${path}/`}>
            <Redirect to={`${path}/1/`} />
          </Route>
          <Route exact path={`${path}/1/`} component={LanguageSelector} />
          <Route exact path={`${path}/2/`} component={PersonalInfo} />
          <Route exact path={`${path}/3/`} component={ColorSelect} />
          <Route exact path={`${path}/4/`} component={FinalStep} />
        </Switch>
      </div>
    </div>
  );
}
