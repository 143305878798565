import React, { useState, useEffect } from "react";
import { useQuery, useMutation, useQueryCache } from "react-query";
import {
  RiCheckDoubleFill,
  RiCheckboxCircleFill,
  RiShoppingCartFill,
} from "react-icons/ri";
import { FaChessQueen, FaChessKnight, FaChessPawn } from "react-icons/fa";
import Modal, { ModalTransition } from "@atlaskit/modal-dialog";
import Button from "./Button";
import { useGraphQl } from "../contexts/GraphqlClient";
import { useWorkspace } from "../contexts/WorkspaceContext";
import { queryBillingInfo, activateFreePlan, choosePlan } from "../api/billing";
import toaster from "toasted-notes";

const SucoPricingModal = (props) => {
  const workspace = useWorkspace();
  const graphQL = useGraphQl();
  const queryCache = useQueryCache();
  const { data } = useQuery("queryBillingInfoSuco", () =>
    graphQL(queryBillingInfo, {
      appId: `${1}`, // hardcoded app because this particular modal only needs SUCO billing info
      sourceId: `${workspace.data?.sourceid}`,
    }),
    { enabled: workspace.data.installedApps.find((item) => item.appid === 1) }
  );

  const freePlanActivation = async (input) => {
    await graphQL(activateFreePlan, {
      appId: `${1}`,
      planId: `${input.id}`,
      sourceId: `${workspace.data?.sourceid}`,
    });
  };

  const paidPlanActivation = async (input) => {
    const returnData = await graphQL(choosePlan, {
      appId: `${1}`,
      planId: `${input.id}`,
      sourceId: `${workspace.data?.sourceid}`,
    });

    return returnData;
  };

  const [freePlanActivationMutation] = useMutation(freePlanActivation, {
    onError: (err) => {
      console.error("Error in suco freePlanActivationMutation ", err);
      window.Rollbar.error("Error in suco freePlanActivationMutation ", err);
    },
    onSuccess: () => {
      toaster.notify("Updated successfully", {
        duration: 2000,
      });
      queryCache.invalidateQueries("queryBillingInfoSuco");
      location.reload();
    },
  });

  const PlanCard = (cardProps) => {
    const [paidPlanActivationMutation] = useMutation(paidPlanActivation, {
      onError: (err) => {
        console.log("error", err);
        window.Rollbar.error("Error in socu paidPlanActivationMutation ", err);
      },
      onSuccess: (data) => {
        window.location.href = data?.choosePlan?.confirmationUrl;
      },
      onMutate: () => {
        setIsLoading(true);
      },
    });

    const isActive = cardProps.planId === data?.billingInfo?.planId;
    const [isLoading, setIsLoading] = useState(false);
    const handleButtonClick = () => {
      if (cardProps.type === "free")
        freePlanActivationMutation({
          id: cardProps.planId,
        });
      else
        paidPlanActivationMutation({
          id: cardProps.planId,
        });
    };
    return (
      <div
        className={`border p-5 rounded-md mr-3 w-1/3 ${
          isActive && "opacity-50"
        } ${cardProps.hightlight && "relative shadow-2xl"}`}
      >
        <p className="font-bold flex items-center mb-5 text-center">
          <span className="p-2 border border-violet-700 text-violet-700 rounded-md mr-3 text-sm ">
            {cardProps.icon}
          </span>
          {cardProps.planName}
        </p>
        <div className="flex mt-3 items-baseline">
          <h1 className="text-5xl font-thin text-violet-700 leading-10 ">
            {cardProps.type === "free" ? "Free" : `$${cardProps.amount}`}
          </h1>
          <p className="text-xs text-gray-500">
            {cardProps.type === "free" ? "for life" : "/ month"}
          </p>
        </div>
        <Button
          className="mt-5"
          onClick={() => {
            handleButtonClick();
          }}
          loading={isLoading}
          disabled={isActive}
          type={isActive ? "link" : "primary"}
          icon={isActive ? <RiCheckboxCircleFill /> : <RiShoppingCartFill />}
        >
          {isActive ? "Active Plan" : cardProps.cta}
        </Button>
        {cardProps.freeTrial && !isActive && (
          <div>
            <small className="text-sm">
              With {cardProps.freeTrial} days free trial
            </small>
          </div>
        )}
        <div className="mt-6 pt-6 border-t">
          {cardProps.features.map((feature) => {
            return (
              <div key={feature} className="flex items-center mt-2 ">
                <span className="text-xl text-violet-400 mr-2">
                  <RiCheckDoubleFill />
                </span>
                <p>{feature}</p>{" "}
              </div>
            );
          })}
        </div>
        {cardProps.hightlight && (
          <div className="animate-pulse pointer-events-none absolute top-0 right-0 border-4 border-violet-700 rounded-md h-full w-full opacity-75" />
        )}
      </div>
    );
  };

  return (
    <ModalTransition>
      {data && props.isOpen && (
        <Modal
          width="x-large"
          actions={[
            {
              isDisabled: props.nonClosable,
              text: "Cancel",
              onClick: () => {
                props.onClose();
              },
            },
          ]}
          onClose={() => {
            props.nonClosable && props.onClose(false);
          }}
          heading={props.title ? props.title : "Select a plan"}
        >
          <p>Select a plan from the options below</p>
          <div className="flex w-full justify-center  mt-4 ">
            <PlanCard
              icon={<FaChessPawn />}
              planName="Starter"
              planId={2}
              type="free"
              cta="Get Started"
              features={[
                "3 Categories",
                "4 Articles Per Category",
                "Unlimited Articles",
                "Rich text Editor",
                "FAQ Widget",
                "FAQ Page",
                "Language Support",
              ]}
            />
            <PlanCard
              hightlight={props.hightlightPlan === 5}
              icon={<FaChessKnight />}
              planName="Standard Plan"
              freeTrial={14}
              planId={5}
              amount={5.99}
              type="monthly"
              cta="Start Free trial"
              features={[
                "10 Categories",
                "20 Articles Per Category",
                "Facebook Messenger Integration",
                "Whatsapp Integration",
                "Unlock 14 FAQ Page banner themes",
                "Unlock 4 more category icon packs (400+ icons)",
                "Remove Corner Branding",
                "Add video and images to articles",
                "Plus everything in free",
              ]}
            />
            <PlanCard
              hightlight={props.hightlightPlan === 3}
              icon={<FaChessQueen />}
              planName="Pro Plan"
              freeTrial={14}
              planId={3}
              type="monthly"
              cta="Start Free trial"
              amount={11.99}
              features={[
                "Unlimited Categories",
                "Unlimited Articles",
                "Custom CSS suport for FAQ pages",
                "HTML editor & markdown editor",
                "Custom Category Images",
                "Custom Help page banner/themes",
                "Plus everything in Free & Standard plan",
              ]}
            />
          </div>
        </Modal>
      )}
    </ModalTransition>
  );
};

export default SucoPricingModal;
