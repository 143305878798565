import React from "react";

{
  /* interface Props { */
}
{
  /*   pageView: React.ReactNode; */
}
{
  /*   navigationBar: React.ReactNode; */
}
{
  /* } */
}
const Section = ({ pageView, navigationBar }) => {
  return (
    <div className="h-full flex row-start-2 row-end-3 laptop:col-start-2  laptop:shadow-2xl overflow-hidden mobile:col-start-1 mobile:col-end-3 z-20">
      {navigationBar}
      {pageView}
    </div>
  );
};

export default Section;
