import React, { useState } from "react";
import CustomiserUnit from "../../../../../../components/CustomiserUnit";
import PaywallElement from "../../../../../../components/PaywallElement";
import Select from "@atlaskit/select";
import { useWorkspace } from "../../../../../../contexts/WorkspaceContext";
import { langOptions } from "../../../../../../utilities/variables";
import Textfield from "@atlaskit/textfield";

import { Link } from "react-router-dom";

/**
 * Customise the language settings of sticky baar
 * @component
 * @param {Object} values - current values inside the form
 * @param {Function} setFieldValue - method to set values in each field
 * @param {string} currentDefaultLang - value of the current default language
 */
export default function StickyBarLanguageSettings({
  values,
  setFieldValue,
  currentDefaultLang,
}) {
  const langArray = Object.keys(values.sticky_atc_lang);
  // to remove "version" from language array
  const filteredLangArray = langArray.filter(
    (arrayElement) => arrayElement !== "version"
  );

  const [selectedLanguage, setSelectedLanguage] = useState(currentDefaultLang);

  return (
    <>
      <div className=" p-2 desktop:px-8 desktop:py-3 border-b">
        <p className="font-bold mb-1 desktop:mb-4 text-center ">
          Select Language to Edit
        </p>
        <div className="flex justify-center">
          <Select
            isSearchable={false}
            value={langOptions.find(
              (option) => option.value === selectedLanguage
            )}
            onChange={(e) => {
              setSelectedLanguage(e.value);
            }}
            className="w-48 mr-2"
            classNamePrefix="react-select"
            options={langOptions.filter((item) =>
              filteredLangArray.includes(item.value)
            )}
          />
        </div>
        <p className="text-sm  text-center my-2 desktop:mt-4">
          <Link to={`/settings/language`} className="text-violet-700">
            {" "}
            Click here
          </Link>{" "}
          to add/edit the language list{" "}
        </p>
      </div>
      <div>
        {values.stickybar_config.general.ctaOnClick === "CHECKOUT" ? (
          <CustomiserUnit
            title="Call to Action Button Text (Buy Now)"
            description="The text displayed inside the CTA Button"
          >
            <Textfield
              value={values.sticky_atc_lang[`${selectedLanguage}`].bar_checkout_cta}
              onChange={(e) => {
                setFieldValue(
                  `sticky_atc_lang["${selectedLanguage}"].bar_checkout_cta`,
                  e.target.value
                );
              }}
              name="basic"
            />
          </CustomiserUnit>
        ) : (
          <CustomiserUnit
            title="Call to Action Button Text (Add to cart)"
            description="The text displayed inside the CTA Button"
          >
            <Textfield
              value={values.sticky_atc_lang[`${selectedLanguage}`].bar_atc_cta}
              onChange={(e) => {
                setFieldValue(
                  `sticky_atc_lang["${selectedLanguage}"].bar_atc_cta`,
                  e.target.value
                );
              }}
              name="basic"
            />
          </CustomiserUnit>
        )}
        <CustomiserUnit
          title="CTA Button Success Text"
          description="Text displayed when CTA button is clicked successfully"
        >
          <Textfield
            value={
              values.sticky_atc_lang[`${selectedLanguage}`].bar_cta_success
            }
            onChange={(e) => {
              setFieldValue(
                `sticky_atc_lang["${selectedLanguage}"].bar_cta_success`,
                e.target.value
              );
            }}
            name="basic"
          />
        </CustomiserUnit>

        <CustomiserUnit
          title="Sold Out Text"
          description="Text displayed when a line item is sold out"
        >
          <Textfield
            value={
              values.sticky_atc_lang[`${selectedLanguage}`].bar_cta_sold_out
            }
            onChange={(e) => {
              setFieldValue(
                `sticky_atc_lang["${selectedLanguage}"].bar_cta_sold_out`,
                e.target.value
              );
            }}
            name="basic"
          />
        </CustomiserUnit>
      </div>
    </>
  );
}
