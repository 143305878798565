import React, { useState } from "react";
import toaster from "toasted-notes";
import { Prompt } from "react-router";
import VisibilityOptions from "./VisibilityOptions";
import SEOOptions from "./SEOOptions";
import PageViewContainer from "../../../../components/structure/PageViewContainer";
import TextEditor from "../../../../components/TextEditor/index";
import Button from "../../../../components/Button";
import LoadingSpinner from "../../../../components/LoadingSpinner";
import List from "../../../../components/List";
import Select from "@atlaskit/select";
import Textfield from "@atlaskit/textfield";
import Modal, { ModalTransition } from "@atlaskit/modal-dialog";
import TextareaAutosize from "react-textarea-autosize";
import Popup from "@atlaskit/popup";
import {
  queryAssistantArticle,
  updateAssistantArticle,
  insertAssistantArticle,
  insertAssistantCategory,
  deleteAssistantArticle,
} from "../../../../api/support";
import { queryWidgetSettings } from "../../../../api/widgets";
import { useGraphQl } from "../../../../contexts/GraphqlClient";
import { useWorkspace } from "../../../../contexts/WorkspaceContext";
import { useQuery, useMutation, useQueryCache } from "react-query";
import {
  RiCheckboxBlankCircleFill,
  RiArrowDropDownLine,
  RiArrowDownSLine,
  RiFileAddLine,
  RiPencilFill,
  RiCheckboxCircleFill,
  RiDeleteBin2Line,
  RiLinksLine,
  RiStarLine,
  RiStarFill,
  RiFileWarningFill,
} from "react-icons/ri";
import InlineEdit from "@atlaskit/inline-edit";
import { Formik } from "formik";

const ArticleEdit = (historyProps) => {
  const [isCategoryModalOpen, setIsCategoryModalOpen] = useState(false);
  const [isVisibilityOptionsOpen, setIsVisibilityOptionsOpen] = useState(false);
  const [isMoreOptionsOpen, setIsMoreOptionsOpen] = useState(false);
  const [isSEOOptionsOpen, setIsSEOOptionsOpen] = useState(false);
  const [categoryCreator, setCategoryCreator] = useState("");
  const [errorFlag, setErrorFlag] = useState(false);
  const [saveWarning, setSaveWarning] = useState(false);
  const [promptLocation, setPromptLocation] = useState("");
  const [isDeleteWarningOpen, setIsDeleteWarningOpen] = useState(false);
  const graphQL = useGraphQl();
  const workspace = useWorkspace();
  const queryCache = useQueryCache();
  const { data: widgetData, refetch } = useQuery("queryWidgetSettings", () =>
    graphQL(queryWidgetSettings)
  );
  const { data } = useQuery(`queryAssistantArticle${historyProps.match.params.id}`, () =>
    graphQL(queryAssistantArticle, { article_id: historyProps.match.params.id })
  );
  const updateArticle = async (input) => {
    await graphQL(updateAssistantArticle, {
      articleId: { id: input.id },
      articleInput: input.values,
    });
  };
  const insertArticleCategory = async (input) => {
    await graphQL(insertAssistantCategory, {
      categoryInput: { ...input },
    });
  };
  const deleteArticle = async (input) => {
    await graphQL(deleteAssistantArticle, {
      articleId: input.id,
    });
  };

  let duplicateArticleValue = {
    title:
      data &&
      data.assistant_articles[0] &&
      `[copy of] ${data.assistant_articles[0].title}`,
    body: data && data.assistant_articles[0] && data.assistant_articles[0].body,
    is_published: false,
    is_featured: true,
    lang: data && data.assistant_articles[0] && data.assistant_articles[0].lang,
    statistics: { likes: 0, views: 0 },
    accountid: workspace.data && workspace.data.accountid,
    sourceid: workspace.data && workspace.data.sourceid,
    categoryid:
      data &&
      data.assistant_articles[0] &&
      data.assistant_articles[0].categoryid,
    position_rank: 0,
    visibility_rules:
      data &&
      data.assistant_articles[0] &&
      data.assistant_articles[0].visibility_rules,
    seo_options:
      data &&
      data.assistant_articles[0] &&
      data.assistant_articles[0].seo_options,
  };

  const [deleteArticleMutation] = useMutation(deleteArticle, {
    onSuccess: () => {
      toaster.notify("Article deleted", {
        duration: 2000,
      });
      historyProps.history.push(`/support/faq/articles/`);
    },
  });

  const [updateArticleMutation] = useMutation(updateArticle, {
    onSuccess: () => {
      toaster.notify("Article updated", {
        duration: 2000,
      });
      queryCache.invalidateQueries(`queryAssistantArticle${historyProps.match.params.id}`);

      // This handles redirection after the unsave prompt
      if (promptLocation.length > 0) window.location.href = promptLocation;
    },
  });
  const [insertArticleCategoryMutatiion] = useMutation(insertArticleCategory, {
    onSuccess: () => queryCache.invalidateQueries(`queryAssistantArticle${historyProps.match.params.id}`),
  });

  const insertArticle = async () => {
    return await graphQL(insertAssistantArticle, {
      articleInput: duplicateArticleValue,
    });
  };

  const [insertArticleMutation] = useMutation(insertArticle, {
    onSuccess: (data) =>
      {historyProps.history.push(
        `/support/faq/articles/${data.insert_assistant_articles_one.id}/edit`
      )
      queryCache.invalidateQueries(`queryAssistantArticle${historyProps.match.params.id}`)
    },
  });

  const handleCategoryCreation = () => {
    insertArticleCategoryMutatiion({
      name: categoryCreator,
      accountid: workspace.data && workspace.data.accountid,
      icon_url:
        "https://assets.cornercart.io/coca-v2/images/category-icons/color/1.svg",
      meta: {},
      sourceid: workspace.data && workspace.data.sourceid,
    });
  };
  const widgetStatus = [
    {
      label: (
        <div className="flex items-center">
          <RiCheckboxBlankCircleFill className="flex-none text-emerald-400 mr-2 text-lg" />{" "}
          Publish{" "}
        </div>
      ),
      value: true,
    },
    {
      label: (
        <div className="flex items-center">
          <RiCheckboxBlankCircleFill className="flex-none text-amber-400 mr-2 text-lg" />{" "}
          Draft{" "}
        </div>
      ),
      value: false,
    },
  ];
  const FeatureStatus = [
    {
      label: (
        <div className="flex items-center">
          <RiStarFill className="text-amber-500 text-xl" />
          Feature{" "}
        </div>
      ),
      value: true,
    },
    {
      label: (
        <div className="flex items-center">
          <RiStarLine className="text-gray-500 text-xl" />
          Not Featured{" "}
        </div>
      ),
      value: false,
    },
  ];

  const initialValues = {
    id: data && data.assistant_articles[0] && data.assistant_articles[0].id,
    is_published:
      data &&
      data.assistant_articles[0] &&
      data.assistant_articles[0].is_published,
    is_featured:
      data &&
      data.assistant_articles[0] &&
      data.assistant_articles[0].is_featured,
    categoryid:
      data &&
      data.assistant_articles[0] &&
      data.assistant_articles[0].categoryid,
    title:
      data && data.assistant_articles[0] && data.assistant_articles[0].title,
    body: data && data.assistant_articles[0] && data.assistant_articles[0].body,
    visibility_rules:
      data &&
      data.assistant_articles[0] &&
      data.assistant_articles[0].visibility_rules,
    seo_options:
      data &&
      data.assistant_articles[0] &&
      data.assistant_articles[0].seo_options,
  };

  const getCategoryName = (categoryId) => {
    const categoryItem = data.assistant_categories.find(
      (category) => category.id === categoryId
    );
    return (
      <span className="flex mr-4  max-h-5 text-sm desktop:text-base">
        <img className="mr-2 w-9 desktop:w-12" src={categoryItem.icon_url} alt="" />{" "}
        <b>{categoryItem.name}</b>
      </span>
    );
  };
  if (
    parseInt(historyProps.match.params.id, 10) ===
    data?.assistant_articles[0]?.id
  )
    return (
      <Formik
        initialValues={initialValues}
        enableReinitialize
        onSubmit={(values) => {
          updateArticleMutation({
            id: values.id,
            values: {
              is_published: values.is_published,
              is_featured: values.is_featured,
              body: values.body,
              title: values.title,
              categoryid: values.categoryid,
              lang: "en",
              visibility_rules: values.visibility_rules,
              seo_options: values.seo_options,
            },
          });
        }}
      >
        {(props) => {
          return (
            <PageViewContainer
              title="Articles"
              action={
                <div className="flex">
                  <Button
                    onClick={() => {
                      setIsDeleteWarningOpen(true);
                    }}
                    type="danger-ghost"
                    className="mr-2"
                    icon={<RiDeleteBin2Line />}
                  >
                    <span className="mobile:hidden laptop:block">
                      Delete article
                    </span>
                  </Button>
                  <Button
                    className="relative"
                    type="primary"
                    onClick={async () => {
                      props.submitForm();
                    }}
                    icon={<RiFileAddLine />}
                    disabled={!props.dirty}
                  >
                    Save Article
                    {props.dirty && (
                      <>
                        <span className=" absolute inline-flex rounded-full bg-red-400 opacity-75 w-4 h-4 -top-1 -right-1"></span>
                        <span className=" absolute inline-flex rounded-full bg-red-600  w-4 h-4 -top-1 -right-1 "></span>
                      </>
                    )}
                  </Button>
                  <ModalTransition>
                    {isDeleteWarningOpen && (
                      <Modal
                        actions={[
                          {
                            text: "Delete it",
                            onClick: () => {
                              deleteArticleMutation({
                                id: historyProps.match.params.id,
                              });
                              setIsDeleteWarningOpen(false);
                            },
                          },
                          {
                            text: "No, keep it",
                            onClick: () => {
                              setIsDeleteWarningOpen(false);
                            },
                          },
                        ]}
                        onClose={() => {
                          setIsDeleteWarningOpen(false);
                        }}
                        heading="You’re about to delete this article"
                        appearance="danger"
                      >
                        <p>
                          This process cannot be reversed, are you sure you want
                          to conitnue with the deletion?
                        </p>
                      </Modal>
                    )}
                  </ModalTransition>
                </div>
              }
            >
              {data && data.assistant_articles[0] ? (
                <div className="overflow-y-auto h-full flex flex-col">
                  {!props.values.is_published && (
                    <div className="bg-amber-300 rounded-t-lg text-amber-800 text-center py-3 flex items-center justify-center">
                      <RiFileWarningFill className="text-xl mr-3" />
                      <p>
                        This Article is in draft mode. Publish it to make it
                        available in your store
                      </p>
                    </div>
                  )}
                  <Prompt
                    when={props.dirty}
                    message={(location) => {
                      setSaveWarning(true);
                      setPromptLocation(location.pathname);
                      return false;
                    }}
                  />
                  <div className="p-3 desktop:p-4 bg-violet-50 border rounded-t-lg flex justify-center">
                    <div className="max-w-4xl flex-1 text-left">
                      <div className="mb-1 desktop:mb-4 flex items-center justify-between w-full">
                        <div className="flex items-center">
                          <div className="max-w-md ">
                            <InlineEdit
                              defaultValue={props.values.is_published}
                              hideActionButtons
                              editView={() => (
                                <div className="w-40">
                                  <Select
                                  isSearchable={false}
                                    options={widgetStatus}
                                    onChange={(e) =>
                                      props.setFieldValue(
                                        "is_published",
                                        e.value
                                      )
                                    }
                                    autoFocus
                                    openMenuOnFocus
                                  />
                                </div>
                              )}
                              readView={() =>
                                props.values.is_published ? (
                                  <div className="flex items-center py-3 px-4 rounded-md cursor-pointer ">
                                    <RiCheckboxBlankCircleFill className="flex-none text-emerald-400 mr-2 text-lg" />{" "}
                                    Published{" "}
                                    <RiArrowDropDownLine className="flex-none ml-2 text-lg" />{" "}
                                  </div>
                                ) : (
                                  <div className="flex items-center py-3 px-4 rounded-md cursor-pointer ">
                                    <RiCheckboxBlankCircleFill className="flex-none text-amber-400 mr-2 text-lg" />{" "}
                                    Draft{" "}
                                    <RiArrowDropDownLine className="flex-none ml-2 text-lg" />{" "}
                                  </div>
                                )
                              }
                              onConfirm={(e) => console.log("is_published", e)}
                            />
                          </div>
                          <div className="max-w-md ">
                            <InlineEdit
                              defaultValue={props.values.is_featured}
                              hideActionButtons
                              editView={() => (
                                <div className="w-40">
                                  <Select
                                  isSearchable={false}
                                    options={FeatureStatus}
                                    onChange={(e) =>
                                      props.setFieldValue(
                                        "is_featured",
                                        e.value
                                      )
                                    }
                                    autoFocus
                                    openMenuOnFocus
                                  />
                                </div>
                              )}
                              readView={() =>
                                props.values.is_featured ? (
                                  <div className="flex items-center py-3 px-4 rounded-md cursor-pointer ">
                                    <RiStarFill className="text-amber-500 text-xl mr-2" />{" "}
                                    Featured{" "}
                                    <RiArrowDropDownLine className="flex-none ml-2 text-lg" />{" "}
                                  </div>
                                ) : (
                                  <div className="flex items-center py-3 px-4 rounded-md cursor-pointer ">
                                    <RiStarLine className="text-gray-500 text-xl mr-2" />{" "}
                                    Not Featured{" "}
                                    <RiArrowDropDownLine className="flex-none ml-2 text-lg" />{" "}
                                  </div>
                                )
                              }
                              onConfirm={(e) => console.log("is_published", e)}
                            />
                          </div>
                        </div>

                        <Popup
                          isOpen={isMoreOptionsOpen}
                          placement="bottom-start"
                          onClose={() => setIsMoreOptionsOpen(false)}
                          content={() => (
                            <div className="p-4">
                              <Button
                                type="link"
                                onClick={() => {
                                  setIsVisibilityOptionsOpen(true);
                                }}
                              >
                                Link to a product
                              </Button>
                              <Button
                                type="link"
                                onClick={() => {
                                  setIsSEOOptionsOpen(true);
                                }}
                              >
                                SEO Options
                              </Button>
                              <Button
                                disabled={props.dirty}
                                type="link"
                                onClick={() => {
                                  insertArticleMutation();
                                }}
                              >
                               <p className="text-left">
                                  Duplicate Article
                                 </p>
                                 {props.dirty && 
                                 <small className="text-xs text-gray-300 font-light">(Save changes before duplicating)</small>
                                 }
                                
                              </Button>
                            </div>
                          )}
                          trigger={(triggerProps) => (
                            <button
                              {...triggerProps}
                              className="flex items-center font-bold text-gray-700"
                              type="link"
                              onClick={() =>
                                setIsMoreOptionsOpen(!isMoreOptionsOpen)
                              }
                              style={{ background: props.color }}
                            >
                              <RiArrowDownSLine className="mr-3 text-xl" />
                              <span>More Options</span>
                            </button>
                          )}
                        />
                      </div>
                      <TextareaAutosize
                        value={props.values.title}
                        onChange={(e) =>
                          props.setFieldValue("title", e.target.value)
                        }
                        className="text-2xl desktop:text-3xl p-3 font-light text-gray-700 border-b border-dashed cursor-text border-gray-400 w-full  bg-violet-50"
                        placeholder="Article Title"
                      />
                      <div className="mt-3 flex flex-wrap">
                        <div className="px-8">
                          <div className="text-xs">CATEGORY</div>
                          <div className="flex justify-between items-center">
                            <p className="text-gray-600 flex items-center">
                              {props.values.categoryid
                                ? getCategoryName(props.values.categoryid)
                                : "No category"}
                              <Button
                                type="link"
                                icon={<RiPencilFill />}
                                onClick={() => setIsCategoryModalOpen(true)}
                              >
                                <small>Edit</small>
                              </Button>
                            </p>
                          </div>
                        </div>
                        <div className="px-6 desktop:px-12 border-l">
                          <div className="text-xs">FAQ PAGE URL</div>
                          <div className="flex justify-between items-center">
                            {widgetData && workspace && (
                              <a
                                href={`https://${workspace.data.storeUrl}/a/${widgetData.widgets_cornerwidget[0].faq_config.appProxyUrl}/article/${historyProps.match.params.id}`}
                                target="_blank"
                                className="my-2 py-2 px-4 font-semibold rounded-md bg-violet-100 text-gray-500 hover:shadow-lg  "
                              >
                                <span className="laptop:block mobile:hidden">
                                  {workspace.data.storeUrl}/a/
                                  {
                                    widgetData.widgets_cornerwidget[0]
                                      .faq_config.appProxyUrl
                                  }
                                  /article/{historyProps.match.params.id}
                                </span>
                                <span className="laptop:hidden mobile:block">
                                  <RiLinksLine />
                                </span>
                              </a>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className=" flex grow justify-center">
                    <div className="max-w-4xl flex-1 flex-col leading-9">
                      {/* The formik keeps its initialValues of a previous formik instace for a fraction of a second before its reintialized 
                    with the values of new instance. So the following condition makes sure the articleId in the initialValue is equal to 
                    the articleId in the URL. And inturn makes sure the text editor is initialized with right Article */}
                      {props.values.id ===
                        parseInt(historyProps.match.params.id, 10) && (
                        <TextEditor
                          content={props.values.body}
                          onChange={(htmlValue) =>
                            props.setFieldValue("body", htmlValue)
                          }
                        />
                      )}
                    </div>
                  </div>
                  <ModalTransition>
                    {isVisibilityOptionsOpen && (
                      <Modal
                        width="large"
                        onClose={() => {
                          setIsVisibilityOptionsOpen(false);
                        }}
                      >
                        <VisibilityOptions
                          value={props.values.visibility_rules}
                          onClose={(e) => {
                            setIsVisibilityOptionsOpen(false);
                          }}
                          onSubmit={(e) => {
                            props.setFieldValue("visibility_rules", e);
                          }}
                        />
                      </Modal>
                    )}

                    {isSEOOptionsOpen && (
                      <Modal
                        width="large"
                        onClose={() => {
                          setIsSEOOptionsOpen(false);
                        }}
                      >
                        <SEOOptions
                          value={props.values.seo_options}
                          onClose={(e) => {
                            setIsSEOOptionsOpen(false);
                          }}
                          onSubmit={(e) => {
                            props.setFieldValue("seo_options", e);
                          }}
                        />
                      </Modal>
                    )}

                    {saveWarning && (
                      <Modal
                        actions={[
                          {
                            text: "Save & continue",
                            onClick: () => {
                              setSaveWarning(false);
                              props.submitForm();
                            },
                          },
                          {
                            text: "Discard Changes",
                            onClick: () => {
                              setSaveWarning(false);
                              props.resetForm();
                              window.location.href = promptLocation;
                            },
                          },
                          {
                            text: "Continue Editing",
                            onClick: () => {
                              setPromptLocation("");
                              setSaveWarning(false);
                            },
                          },
                        ]}
                        onClose={() => {
                          setSaveWarning(false);
                        }}
                        heading="You've unsaved changes"
                        appearance="warning"
                      >
                        <p>
                          You haven't saved your changes. Are you sure you want
                          to discard changes and go to another page?
                        </p>
                      </Modal>
                    )}
                    {isCategoryModalOpen && (
                      <Modal
                        actions={[
                          {
                            text: "Confirm",
                            onClick: () => setIsCategoryModalOpen(false),
                          },
                        ]}
                        onClose={() => setIsCategoryModalOpen(false)}
                        heading="Select Categories"
                      >
                        <List
                          disableSelectAll
                          header={
                            <div className="grid grid-cols-12 items-center justify-center ">
                              <div className="px-3 col-span-12 flex justify-end" />
                            </div>
                          }
                          items={data.assistant_categories}
                          render={(item) => (
                            <div className="grid grid-cols-12 items-center justify-center py-5">
                              <div className="col-span-1 flex justify-center">
                                <img width="46px" src={item.icon_url} alt="" />
                              </div>
                              <div className="col-span-8 px-3 ">
                                <p className="font-bold text-gray-700">
                                  {item.name}
                                </p>
                              </div>
                              <div className="col-span-3 px-3 text-center">
                                {item.id === props.values.categoryid ? (
                                  <h2>
                                    <RiCheckboxCircleFill />
                                  </h2>
                                ) : (
                                  <Button
                                    type="outline"
                                    onClick={() =>
                                      props.setFieldValue("categoryid", item.id)
                                    }
                                  >
                                    Select
                                  </Button>
                                )}
                              </div>
                            </div>
                          )}
                        />
                        <div className="flex justify-center my-3">
                          <div className="flex items-center">
                            <Textfield
                              name="basic"
                              defaultValue={categoryCreator}
                              onChange={(e) =>
                                setCategoryCreator(e.target.value)
                              }
                            />
                            <div className="flex-none ml-2">
                              <Button
                                type="ghost"
                                onClick={() => handleCategoryCreation()}
                              >
                                Add Category
                              </Button>
                            </div>
                          </div>
                        </div>
                        <div></div>
                      </Modal>
                    )}
                  </ModalTransition>
                </div>
              ) : (
                <div className="h-full flex flex-col justify-center items-center">
                  <div className="grow-0">
                    <LoadingSpinner />
                  </div>
                  {/* renders an error message after 3 seconds */}
                  {setTimeout(() => {
                    setErrorFlag(true);
                  }, 3000) &&
                    errorFlag && (
                      <p className="text-gray-500 text-center max-w-2xl">
                        Something went wrong and we couldn't fetch this article.
                        Please go back and select the article again, and if
                        error persists contact us
                      </p>
                    )}
                </div>
              )}
            </PageViewContainer>
          );
        }}
      </Formik>
    );
  else return <LoadingSpinner />;
};

export default ArticleEdit;
