import React, { useState } from "react";
import { useWorkspace } from "../../../../../contexts/WorkspaceContext";
import ThemeSelector from "./ThemeSelector";
import GradientPicker from "../../../../../components/GradientPicker";
import ColorPicker from "../../../../../components/ColorPicker";
import CustomiserUnit from "../../../../../components/CustomiserUnit";
import TakeOverDrawer from "../../../../../components/TakeOverDrawer";
import Button from "../../../../../components/Button";
import Textfield from "@atlaskit/textfield";
import TabRadioButton from "../../../../../components/TabRadioButton";
import {
  RiImageAddFill,
  RiArrowDropUpLine,
  RiArrowDropDownLine,
} from "react-icons/ri";

/**
 * Customise the color palette of the widget
 * @component
 * @param {Object} values - current values inside the form
 * @param {Function} setFieldValue - Function to set values in each field
 */
export default function MiniCartColorSettings({ values, setFieldValue }) {
  const workspace = useWorkspace();
  const [isColorCustomisationVisible, setIsColorCustomisationVisible] =
    useState(false);
  const convertRGB = (rgb) => `rgb(${rgb.r},${rgb.g},${rgb.b})`;
  const bannerTypeSelect = [
    { label: "Color", value: "gradient" },
    { label: "Image", value: "image" },
  ];

  return (
    <>
      <ThemeSelector values={values} setFieldValue={setFieldValue} />
      {/* Accent Color */}
      <CustomiserUnit
        title="Primary Widget Colors"
        description="These color combinations are used for the primary call to action buttons and texts."
      >
        <div className="flex flex-wrap border  rounded-lg py-3">
          <div className="max-w-sm flex border-r  px-4 items-center w-1/2 ">
            <ColorPicker
              color={values.cart_config.colors.accentBg}
              onChange={(color) => {
                setFieldValue(
                  "cart_config.colors.accentBg",
                  convertRGB(color.rgb)
                );
              }}
            />
            <p className="ml-3 text-sm desktop:text-base">Background Color</p>
          </div>
          <div className="max-w-sm flex px-4 items-center w-1/2">
            <ColorPicker
              color={values.cart_config.colors.accentFg}
              onChange={(color) => {
                setFieldValue(
                  "cart_config.colors.accentFg",
                  convertRGB(color.rgb)
                );
              }}
            />
            <p className="ml-3 text-sm desktop:text-base">Text/Icon Color</p>
          </div>
        </div>
      </CustomiserUnit>

      {/* Banner Settings */}
      <CustomiserUnit
        title="Cart Widget Banner"
        description="Choose between solid/gradient colors or custom images for the banner"
      >
        <div className="border  rounded-lg ">
          {!workspace.data?.feature_flag.apps?.cornercart?.cornerwidget
            ?.cornerwidget_banner_image && (
            <div className="bg-violet-100 justify-center px-2 py-1  text-violet-700 flex items-center">
              <span className="font-bold text-lg mr-1">
                <RiImageAddFill />
              </span>
              <span className="font-bold text-xs">
                Upgrade to unlock image banners
              </span>
            </div>
          )}
          {workspace.data?.feature_flag.apps?.cornercart?.cornerwidget
            ?.cornerwidget_banner_image && (
            <div className="flex items-center  px-3 py-2 border-b">
              <p className="font-bold mr-4 shrink-0">Banner type</p>
              <TabRadioButton
                defaultValue={values.cart_config.banner.type}
                options={bannerTypeSelect}
                onChange={(selectedValue) => {
                  setFieldValue("cart_config.banner.type", selectedValue);
                }}
              />
            </div>
          )}
          <div className="flex items-center justify-center  p-2">
            {values.cart_config.banner.type === "image" &&
            workspace.data?.feature_flag.apps?.cornercart?.cornerwidget
              ?.cornerwidget_banner_image ? (
              <div className="w-full">
                <p className="text-sm font-bold">Image Url <span className="text-gray-600 text-xs">(Ideal size: 435x210 px  )</span> </p>
                <Textfield
                  defaultValue={values.cart_config.banner.imageUrl}
                  onChange={(e) => {
                    setFieldValue(
                      "cart_config.banner.imageUrl",
                      e.target.value
                    );
                  }}
                  name="basic"
                />
              </div>
            ) : (
              <div className="w-full">
                <GradientPicker
                  startColor={values.cart_config.banner.gradientStart}
                  endColor={values.cart_config.banner.gradientEnd}
                  onChange={(startColor, endColor) => {
                    setFieldValue(
                      "cart_config.banner.gradientStart",
                      startColor
                    );
                    setFieldValue("cart_config.banner.gradientEnd", endColor);
                  }}
                />
              </div>
            )}
          </div>
        </div>
      </CustomiserUnit>
      <div className="flex justify-center">
        <Button
          type="link"
          onClick={() => {
            setIsColorCustomisationVisible(!isColorCustomisationVisible);
          }}
          icon={
            isColorCustomisationVisible ? (
              <RiArrowDropUpLine />
            ) : (
              <RiArrowDropDownLine />
            )
          }
        >
          {isColorCustomisationVisible
            ? "Hide Advanced Color Customisations"
            : "Show Advanced Color Customisations"}
        </Button>
      </div>
      {isColorCustomisationVisible && (
        <div>
          {/* Background Color [Pro-feature] */}
          <CustomiserUnit
            paywall={
              !workspace.data?.feature_flag.apps?.cornercart?.cornerwidget
                ?.cornerwidget_advanced_color_customisation
            }
            paywallPlan={13}
            title="Background Colors Inside Widget"
            description="Choose the background colors used inside the cart widget."
          >
            <div className="flex flex-wrap border  rounded-lg py-3 ">
              <div className="max-w-sm flex border-r px-4 items-center w-1/2">
                <ColorPicker
                  color={values.cart_config.colors.widgetBg}
                  onChange={(color) => {
                    setFieldValue(
                      "cart_config.colors.widgetBg",
                      convertRGB(color.rgb)
                    );
                  }}
                />
                <p className="ml-3 text-sm desktop:text-base">Widget Background</p>
              </div>
              <div className="max-w-sm flex px-4 items-center w-1/2">
                <ColorPicker
                  color={values.cart_config.colors.cardBg}
                  onChange={(color) => {
                    setFieldValue(
                      "cart_config.colors.cardBg",
                      convertRGB(color.rgb)
                    );
                  }}
                />
                <p className="ml-3 text-sm desktop:text-base">Card Background</p>
              </div>
            </div>
          </CustomiserUnit>

          {/* Type Color [Pro-feature] */}
          <CustomiserUnit
            paywall={
              !workspace.data?.feature_flag.apps?.cornercart?.cornerwidget
                ?.cornerwidget_advanced_color_customisation
            }
            paywallPlan={13}
            title="Text Colors Inside Widget"
            description="Choose the colors for the UI text inside the cart widget."
          >
            <div className="flex flex-wrap border  rounded-lg py-3">
              <div className="max-w-sm flex border-r px-4 items-center w-1/2">
                <ColorPicker
                  color={values.cart_config.colors.primaryType}
                  onChange={(color) => {
                    setFieldValue(
                      "cart_config.colors.primaryType",
                      convertRGB(color.rgb)
                    );
                  }}
                />
                <p className="ml-3 text-sm desktop:text-base">Primary Texts</p>
              </div>
              <div className="max-w-sm flex px-4 items-center w-1/2">
                <ColorPicker
                  color={values.cart_config.colors.secondaryType}
                  onChange={(color) => {
                    setFieldValue(
                      "cart_config.colors.secondaryType",
                      convertRGB(color.rgb)
                    );
                  }}
                />
                <p className="ml-3 text-sm desktop:text-base">Secondary Texts</p>
              </div>
            </div>
          </CustomiserUnit>

          {/* Outline Color [Pro-feature] */}
          <CustomiserUnit
            paywall={
              !workspace.data?.feature_flag.apps?.cornercart?.cornerwidget
                ?.cornerwidget_advanced_color_customisation
            }
            paywallPlan={13}
            title="Outline Color Inside Widget"
            description="This color will be used for all the borders and outlines in the widget"
          >
            <div className="flex flex-wrap border justify-center rounded-lg py-3">
              <div className="max-w-sm flex items-center">
                <ColorPicker
                  color={values.cart_config.colors.outlines}
                  onChange={(color) => {
                    setFieldValue(
                      "cart_config.colors.outlines",
                      convertRGB(color.rgb)
                    );
                  }}
                />
                <p className="ml-3 text-sm desktop:text-base">Outline color</p>
              </div>
            </div>
          </CustomiserUnit>
        </div>
      )}
    </>
  );
}
