import React, { useState, useEffect } from "react";
import toaster from "toasted-notes";
import { useQuery, useMutation, useQueryCache } from "react-query";
import CustomiserUnit from "../../../../components/CustomiserUnit";
import Select from "@atlaskit/select";
import Textfield from "@atlaskit/textfield";
import Button from "../../../../components/Button";
import LoadingSpinner from "../../../../components/LoadingSpinner";
import { Formik } from "formik";
import { useGraphQl } from "../../../../contexts/GraphqlClient";
import { useWorkspace } from "../../../../contexts/WorkspaceContext";
import {
  queryWidgetSettings,
  updateWidgetSettings,
} from "../../../../api/widgets";
import { langOptions } from "../../../../utilities/variables";

export default function OfferWidget() {
  const graphQL = useGraphQl();
  const workspace = useWorkspace();
  const queryCache = useQueryCache();

  const [currentLanguages, setCurrentLanguages] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = useState();
  const [initialLanguage, setInitialLanguage] = useState();
  const [viewState, setViewState] = useState("simple-close");

  const { data, refetch } = useQuery("queryWidgetSettings", () =>
    graphQL(queryWidgetSettings)
  );

  const updateWidget = async (values) => {
    await graphQL(updateWidgetSettings, {
      widgetSettingsId: values.id,
      widgetSettingsInput: values.config,
    });
    refetch();
  };

  const [updateWidgetMutation] = useMutation(updateWidget, {
    onSuccess: () => {
      queryCache.invalidateQueries("queryWidgetSettings");
      toaster.notify("Widget settings saved successfully", {
        duration: 2000,
      });
    },
  });

  // This useEffect triggers when selected language is changed and when data is updated after saving
  // And this combines the data from db and the default values saved in i18n so that even if user doesnt have certain language values in his db it will be filled from the default values

  useEffect(async () => {
    if (selectedLanguage && data) {
      let currentLangJSON =
        data &&
        data.widgets_cornerwidget[0].offer_widget_lang[`${selectedLanguage}`];
      let defaultValues =
        await require(`../../../../i18n/default-values/offer-widget/${selectedLanguage}.json`);
      setInitialLanguage({ ...defaultValues, ...currentLangJSON });
    }
  }, [data, selectedLanguage]);

  useEffect(() => {
    if (data) {
      let langConfig = data.widgets_cornerwidget[0].offer_widget_lang;
      let langArray = Object.keys(langConfig);
      setCurrentLanguages(langArray);
      setSelectedLanguage(data.workspaces[0].source.default_language);
    }
  }, [data]);

  if (initialLanguage)
    return (
      <Formik
        enableReinitialize
        initialValues={initialLanguage}
        onSubmit={(values) => {
          const offer_widget_lang = {
            offer_widget_lang: {
              ...data.widgets_cornerwidget[0].offer_widget_lang,
              [`${selectedLanguage}`]: values,
            },
          };

          updateWidgetMutation({
            id: { id: data && data.widgets_cornerwidget[0].id },
            config: offer_widget_lang,
          });
        }}
      >
        {(props) => (
          <div className=" h-full overflow-y-auto">
            <div className=" px-8 py-3 sticky top-0 flex border-b bg-white items-center justify-between">
              <div>
                {!props.dirty ? (
                  <div className="flex items-center">
                    <p className="font-semibold">Select Language</p>
                    {data && (
                      <Select
                        value={langOptions.find(
                          (option) => option.value === selectedLanguage
                        )}
                        onChange={(e) => {
                          setSelectedLanguage(e.value);
                        }}
                        isDisabled={props.dirty}
                        className="w-48 ml-2"
                        classNamePrefix="react-select"
                        options={langOptions.filter((item) =>
                          currentLanguages.includes(item.value)
                        )}
                      />
                    )}
                  </div>
                ) : (
                  <div className=" rounded-md">
                    <small>
                      <b>
                        {
                          langOptions.filter(
                            (item) => item.value === selectedLanguage
                          )[0].label
                        }
                        :
                      </b>{" "}
                      Please save or discard the current changes before
                      switching languages.
                    </small>
                  </div>
                )}
              </div>
              <div className="mr-4 flex shrink-0">
                {props.dirty && (
                  <Button
                    type="link"
                    onClick={() => {
                      props.resetForm();
                    }}
                  >
                    Discard changes
                  </Button>
                )}
                <Button
                  onClick={() => props.submitForm()}
                  type="primary"
                  disabled={!props.dirty}
                >
                  {" "}
                  Save settings
                </Button>
              </div>
            </div>
            <div className="flex justify-center border-b ">
              <button
                onClick={() => setViewState("simple-close")}
                className={`flex-1 text-center py-4 focus:outline-none ${
                  viewState === "simple-close" &&
                  "border-b-2 border-violet-700 text-violet-700 font-bold"
                } `}
              >
                Simple referral closed state
              </button>
              <button
                onClick={() => setViewState("viral-close")}
                className={`flex-1 text-center py-4 focus:outline-none ${
                  viewState === "viral-close" &&
                  "border-b-2 border-violet-700 text-violet-700 font-bold"
                } `}
              >
                Viral referral closed state
              </button>
              <button
                onClick={() => setViewState("widget-open")}
                className={`flex-1 text-center py-4 focus:outline-none ${
                  viewState === "widget-open" &&
                  "border-b-2 border-violet-700 text-violet-700 font-bold"
                } `}
              >
                Widget Opened State
              </button>
            </div>
            <div className="desktop:p-8 px-4 py-2 max-w-3xl overflow-y-auto">
              {viewState === "simple-close" && (
                <div>
                  <h2>Simple referral widget (Closed State)</h2>

                  {/* simple widget Line 1 ( Reward Type: offer) */}
                  <CustomiserUnit title="Simple Referral Widget Line 1 ( Reward Type: offer) ">
                    <div className="border rounded-md bg-gray-100 text-sm p-3 mb-3">
                      <div>
                        <span className="font-mono font-bold bg-gray-300 px-2 py-1 rounded-md">
                          &lt;maximum_reward&gt;
                        </span>{" "}
                        : Will be replaced to show the discount coupon someone
                        can get for reffering their friend
                      </div>
                    </div>
                    <Textfield
                      value={props.values.simple_coupon_widget_line1}
                      onChange={(e) => {
                        props.setFieldValue(
                          "simple_coupon_widget_line1",
                          e.target.value
                        );
                      }}
                      name="basic"
                    />
                  </CustomiserUnit>

                  {/* simple widget Line 1 ( Reward Type: cashback) */}
                  <CustomiserUnit title="Simple Referral Widget Line 1 ( Reward Type: cashback) ">
                    <div className="border rounded-md bg-gray-100 text-sm p-3 mb-3">
                      <div>
                        <span className="font-mono font-bold bg-gray-300 px-2 py-1 rounded-md">
                          &lt;maximum_reward&gt;
                        </span>{" "}
                        : Will be replaced to show the discount coupon someone
                        can get for reffering their friend
                      </div>
                    </div>
                    <Textfield
                      value={props.values.simple_cashback_widget_line1}
                      onChange={(e) => {
                        props.setFieldValue(
                          "simple_cashback_widget_line1",
                          e.target.value
                        );
                      }}
                      name="basic"
                    />
                  </CustomiserUnit>

                  {/* simple widget Line 2 */}
                  <CustomiserUnit title="Simple Referral Widget Line 2">
                    <Textfield
                      value={props.values.simple_widget_line2}
                      onChange={(e) => {
                        props.setFieldValue(
                          "simple_widget_line2",
                          e.target.value
                        );
                      }}
                      name="basic"
                    />
                  </CustomiserUnit>

                  {/* Simple refferals pirmary CTA */}
                  <CustomiserUnit title="Simple Referral Thank You Page Widget CTA ">
                    <Textfield
                      value={props.values.simple_widget_cta}
                      onChange={(e) => {
                        props.setFieldValue(
                          "simple_widget_cta",
                          e.target.value
                        );
                      }}
                      name="basic"
                    />
                  </CustomiserUnit>

                  {/* What friends get Line 1*/}
                  <CustomiserUnit title="What Friends Get Line 1 ">
                    <Textfield
                      value={props.values.simple_they_get_title}
                      onChange={(e) => {
                        props.setFieldValue(
                          "simple_they_get_title",
                          e.target.value
                        );
                      }}
                      name="basic"
                    />
                  </CustomiserUnit>

                  {/* What friends get Line 2 */}
                  <CustomiserUnit title="What Friends Get Line 2 ">
                  <div className="border rounded-md bg-gray-100 text-sm p-3 mb-3">
                      <span className="font-mono font-bold bg-gray-300 px-2 py-1 rounded-md">
                        &lt;gift_offer&gt;
                      </span>{" "}
                      : Will be replaced to show the gift offer
                    </div>
                    <Textfield
                      value={props.values.simple_they_get_content}
                      onChange={(e) => {
                        props.setFieldValue(
                          "simple_they_get_content",
                          e.target.value
                        );
                      }}
                      name="basic"
                    />
                  </CustomiserUnit>

                  {/* What You get Line 1 */}
                  <CustomiserUnit title="What You Get Line 1 ">
                    <Textfield
                      value={props.values.simple_you_get_title}
                      onChange={(e) => {
                        props.setFieldValue(
                          "simple_you_get_title",
                          e.target.value
                        );
                      }}
                      name="basic"
                    />
                  </CustomiserUnit>

                  {/* What you get Line 2 (Coupon) */}
                  <CustomiserUnit title="What You Get Line 2 ( Reward Type: coupon) ">
                    <Textfield
                      value={props.values.simple_you_get_content_coupon}
                      onChange={(e) => {
                        props.setFieldValue(
                          "simple_you_get_content_coupon",
                          e.target.value
                        );
                      }}
                      name="basic"
                    />
                  </CustomiserUnit>

                  {/* What friends get Line 2 (cashback)*/}
                  <CustomiserUnit title="What You Get Line 2 ( Reward Type: cashback) ">
                    <Textfield
                      value={props.values.simple_you_get_content_cashback}
                      onChange={(e) => {
                        props.setFieldValue(
                          "simple_you_get_content_cashback",
                          e.target.value
                        );
                      }}
                      name="basic"
                    />
                  </CustomiserUnit>
                </div>
              )}
              {viewState === "viral-close" && (
                <div>
                  <h2>Viral referral widget (Closed State)</h2>

                  {/* Thank you Page Line 1  */}
                  <CustomiserUnit title="Thank You Page Widget Line 1 ">
                    <div className="border rounded-md bg-gray-100 text-sm p-3 mb-3">
                      <span className="font-mono font-bold bg-gray-300 px-2 py-1 rounded-md">
                        &lt;gift_offer&gt;
                      </span>{" "}
                      : Will be replaced to show the gift offer
                    </div>
                    <Textfield
                      value={props.values.thank_widget_line1}
                      onChange={(e) => {
                        props.setFieldValue(
                          "thank_widget_line1",
                          e.target.value
                        );
                      }}
                      name="basic"
                    />
                  </CustomiserUnit>

                  {/* Thank you Page Line 2 (offer)  */}
                  <CustomiserUnit title="Thank You Page Widget Line 2 ( Reward Type: offer) ">
                    <div className="border rounded-md bg-gray-100 text-sm p-3 mb-3">
                      <div>
                        <span className="font-mono font-bold bg-gray-300 px-2 py-1 rounded-md">
                          &lt;maximum_reward&gt;
                        </span>{" "}
                        : Will be replaced to show the maximum discount a
                        referrer can get
                      </div>
                    </div>
                    <Textfield
                      value={props.values.thank_widget_offer_line2}
                      onChange={(e) => {
                        props.setFieldValue(
                          "thank_widget_offer_line2",
                          e.target.value
                        );
                      }}
                      name="basic"
                    />
                  </CustomiserUnit>

                  {/* Thank you Page Line 2 (cashback)  */}
                  <CustomiserUnit title="Thank You Page Widget Line 2 ( Reward Type: cashback) ">
                    <div className="border rounded-md bg-gray-100 text-sm p-3 mb-3">
                      <div>
                        <span className="font-mono font-bold bg-gray-300 px-2 py-1 rounded-md">
                          &lt;maximum_reward&gt;
                        </span>{" "}
                        : Will be replaced to show the maximum discount a
                        referrer can get
                      </div>
                    </div>
                    <Textfield
                      value={props.values.thank_widget_cashback_line2}
                      onChange={(e) => {
                        props.setFieldValue(
                          "thank_widget_cashback_line2",
                          e.target.value
                        );
                      }}
                      name="basic"
                    />
                  </CustomiserUnit>

                  {/* Viral refferals pirmary CTA */}
                  <CustomiserUnit title="Viral Referral Thank You Page Widget CTA ">
                    <Textfield
                      value={props.values.thank_widget_cta}
                      onChange={(e) => {
                        props.setFieldValue("thank_widget_cta", e.target.value);
                      }}
                      name="basic"
                    />
                  </CustomiserUnit>
                </div>
              )}

              {viewState === "widget-open" && (
                <div>
                  <h2>Widget opened state</h2>
                  {/* Offer View Primary title (offer reward)*/}
                  <CustomiserUnit title="Offer View Primary title (Reward Type: Coupon) ">
                    <div className="border rounded-md bg-gray-100 text-sm p-3 mb-3">
                      <div className="mb-2">
                        <span className="font-mono font-bold bg-gray-300 px-2 py-1 rounded-md">
                          &lt;gift_offer&gt;
                        </span>{" "}
                        : Will be replaced to show the gift offer
                      </div>
                      <div>
                        <span className="font-mono font-bold bg-gray-300 px-2 py-1 rounded-md">
                          &lt;maximum_reward&gt;
                        </span>{" "}
                        : Will be replaced to show the maximum discount a
                        referrer can get
                      </div>
                    </div>
                    <Textfield
                      value={props.values.offer_view_title}
                      onChange={(e) => {
                        props.setFieldValue("offer_view_title", e.target.value);
                      }}
                      name="basic"
                    />
                  </CustomiserUnit>

                  {/* Offer View Primary title (Cashback reward)*/}
                  <CustomiserUnit title="Offer View Primary title (Reward Type: Cashback) ">
                    <div className="border rounded-md bg-gray-100 text-sm p-3 mb-3">
                      <div className="mb-2">
                        <span className="font-mono font-bold bg-gray-300 px-2 py-1 rounded-md">
                          &lt;gift_offer&gt;
                        </span>{" "}
                        : Will be replaced to show the gift offer
                      </div>
                      <div>
                        <span className="font-mono font-bold bg-gray-300 px-2 py-1 rounded-md">
                          &lt;maximum_reward&gt;
                        </span>{" "}
                        : Will be replaced to show the maximum discount a
                        referrer can get
                      </div>
                    </div>
                    <Textfield
                      value={props.values.cashback_view_title}
                      onChange={(e) => {
                        props.setFieldValue(
                          "cashback_view_title",
                          e.target.value
                        );
                      }}
                      name="basic"
                    />
                  </CustomiserUnit>

                  {/* Viral Referral Primary subtitle */}
                  <CustomiserUnit title="Viral Referral Primary subtitle ">
                    <Textfield
                      value={props.values.offer_view_subtitle}
                      onChange={(e) => {
                        props.setFieldValue(
                          "offer_view_subtitle",
                          e.target.value
                        );
                      }}
                      name="basic"
                    />
                  </CustomiserUnit>

                  {/* Simple Referral How it works title */}
                  <CustomiserUnit title="Simple Referral How it works title ">
                    <Textfield
                      value={props.values.simple_how_it_work_title}
                      onChange={(e) => {
                        props.setFieldValue(
                          "simple_how_it_work_title",
                          e.target.value
                        );
                      }}
                      name="basic"
                    />
                  </CustomiserUnit>

                  {/* Simple Referral How it works line1(reward type Cashback) */}
                  <CustomiserUnit title="Simple Referral How it works Line 1 (Reward Type: cashback) ">
                    <Textfield
                      value={props.values.simple_how_it_work_line1_cashback}
                      onChange={(e) => {
                        props.setFieldValue(
                          "simple_how_it_work_line1_cashback",
                          e.target.value
                        );
                      }}
                      name="basic"
                    />
                  </CustomiserUnit>

                  {/* Simple Referral How it works line2 (reward type Cashback) */}
                  <CustomiserUnit title="Simple Referral How it works line 2 (Reward Type: cashback) ">
                    <div className="border rounded-md bg-gray-100 text-sm p-3 mb-3">
                      <div className="mb-2">
                        <span className="font-mono font-bold bg-gray-300 px-2 py-1 rounded-md">
                          &lt;reward&gt;
                        </span>{" "}
                        : Will be replaced to show the reward
                      </div>
                    </div>
                    <Textfield
                      value={props.values.simple_how_it_work_line2_cashback}
                      onChange={(e) => {
                        props.setFieldValue(
                          "simple_how_it_work_line2_cashback",
                          e.target.value
                        );
                      }}
                      name="basic"
                    />
                  </CustomiserUnit>

                  {/* Simple Referral How it works line3 (reward type cashback) */}
                  <CustomiserUnit title="Simple Referral How it works line 3 (Reward Type: cashback) ">
                    <div className="border rounded-md bg-gray-100 text-sm p-3 mb-3">
                      <div className="mb-2">
                        <span className="font-mono font-bold bg-gray-300 px-2 py-1 rounded-md">
                          &lt;maximum_reward&gt;
                        </span>{" "}
                        : Will be replaced to show maximum cashback limit
                      </div>
                    </div>
                    <Textfield
                      value={props.values.simple_how_it_work_line3_cashback}
                      onChange={(e) => {
                        props.setFieldValue(
                          "simple_how_it_work_line3_cashback",
                          e.target.value
                        );
                      }}
                      name="basic"
                    />
                  </CustomiserUnit>

                  {/* Simple Referral How it works line1(reward type Coupon) */}
                  <CustomiserUnit title="Simple Referral How it works Line 1 (Reward Type: coupon) ">
                    <Textfield
                      value={props.values.simple_how_it_work_line1_coupon}
                      onChange={(e) => {
                        props.setFieldValue(
                          "simple_how_it_work_line1_coupon",
                          e.target.value
                        );
                      }}
                      name="basic"
                    />
                  </CustomiserUnit>

                  {/* Simple Referral How it works line2 (reward type Coupon) */}
                  <CustomiserUnit title="Simple Referral How it works line 2 (Reward Type: coupon) ">
                    <div className="border rounded-md bg-gray-100 text-sm p-3 mb-3">
                      <div className="mb-2">
                        <span className="font-mono font-bold bg-gray-300 px-2 py-1 rounded-md">
                          &lt;reward&gt;
                        </span>{" "}
                        : Will be replaced to show the reward
                      </div>
                    </div>
                    <Textfield
                      value={props.values.simple_how_it_work_line2_coupon}
                      onChange={(e) => {
                        props.setFieldValue(
                          "simple_how_it_work_line2_coupon",
                          e.target.value
                        );
                      }}
                      name="basic"
                    />
                  </CustomiserUnit>

                  {/* Simple Referral How it works line3 (reward type Coupon) */}
                  <CustomiserUnit title="Simple Referral How it works line 3 (Reward Type: coupon ) ">
                    <div className="border rounded-md bg-gray-100 text-sm p-3 mb-3">
                      <div className="mb-2">
                        <span className="font-mono font-bold bg-gray-300 px-2 py-1 rounded-md">
                          &lt;maximum_reward&gt;
                        </span>{" "}
                        : Will be replaced to show maximum cashback limit
                      </div>
                    </div>
                    <Textfield
                      value={props.values.simple_how_it_work_line3_coupon}
                      onChange={(e) => {
                        props.setFieldValue(
                          "simple_how_it_work_line3_coupon",
                          e.target.value
                        );
                      }}
                      name="basic"
                    />
                  </CustomiserUnit>

                  {/* Initial Offer Text */}
                  <CustomiserUnit title="Initial Offer Text">
                    <Textfield
                      value={props.values.initial_offer_text}
                      onChange={(e) => {
                        props.setFieldValue(
                          "initial_offer_text",
                          e.target.value
                        );
                      }}
                      name="basic"
                    />
                  </CustomiserUnit>

                  {/* Reward Stage Text */}
                  <CustomiserUnit title="Reward Stage Text ">
                    <div className="border rounded-md bg-gray-100 text-sm p-3 mb-3">
                      <div className="mb-2">
                        <span className="font-mono font-bold bg-gray-300 px-2 py-1 rounded-md">
                          &lt;reward_requirement&gt;
                        </span>{" "}
                        : Will be replaced to show the reward requirement
                        required to unlock that stage
                      </div>
                    </div>
                    <Textfield
                      value={props.values.reward_stage_text}
                      onChange={(e) => {
                        props.setFieldValue(
                          "reward_stage_text",
                          e.target.value
                        );
                      }}
                      name="basic"
                    />
                  </CustomiserUnit>

                  {/* Viral refferals pirmary CTA */}
                  <CustomiserUnit title="Viral refferals pirmary CTA ">
                    <Textfield
                      value={props.values.offer_view_cta}
                      onChange={(e) => {
                        props.setFieldValue("offer_view_cta", e.target.value);
                      }}
                      name="basic"
                    />
                  </CustomiserUnit>

                  {/* Text above email form */}
                  <CustomiserUnit title="Text above email form">
                    <Textfield
                      value={props.values.email_form_text}
                      onChange={(e) => {
                        props.setFieldValue("email_form_text", e.target.value);
                      }}
                      name="basic"
                    />
                  </CustomiserUnit>

                  {/* Placeholder text on name input box */}
                  <CustomiserUnit title="Placeholder text on name input box">
                    <Textfield
                      value={props.values.email_form_name_placeholder}
                      onChange={(e) => {
                        props.setFieldValue(
                          "email_form_name_placeholder",
                          e.target.value
                        );
                      }}
                      name="basic"
                    />
                  </CustomiserUnit>

                  {/* Placeholder text on emial id input box */}
                  <CustomiserUnit title="Placeholder text on email id input box">
                    <Textfield
                      value={props.values.email_form_email_placeholder}
                      onChange={(e) => {
                        props.setFieldValue(
                          "email_form_email_placeholder",
                          e.target.value
                        );
                      }}
                      name="basic"
                    />
                  </CustomiserUnit>

                  {/* Email form call to action button */}
                  <CustomiserUnit title="Email form call to action button">
                    <Textfield
                      value={props.values.email_form_cta}
                      onChange={(e) => {
                        props.setFieldValue("email_form_cta", e.target.value);
                      }}
                      name="basic"
                    />
                  </CustomiserUnit>

                  {/* Gift Page Title */}
                  <CustomiserUnit title="Gift Page Title">
                    <div className="border rounded-md bg-gray-100 text-sm p-3 mb-3">
                      <div className="mb-2">
                        <span className="font-mono font-bold bg-gray-300 px-2 py-1 rounded-md">
                          &lt;gift_offer&gt;
                        </span>{" "}
                        : Will be replaced to show the gift offer
                      </div>
                    </div>
                    <Textfield
                      value={props.values.gift_offer_title}
                      onChange={(e) => {
                        props.setFieldValue("gift_offer_title", e.target.value);
                      }}
                      name="basic"
                    />
                  </CustomiserUnit>

                  {/* Text above gift URL */}
                  <CustomiserUnit title="Text above gift URL">
                    <Textfield
                      value={props.values.gift_offer_share_url_title}
                      onChange={(e) => {
                        props.setFieldValue(
                          "gift_offer_share_url_title",
                          e.target.value
                        );
                      }}
                      name="basic"
                    />
                  </CustomiserUnit>

                  {/* Text above gift code (this is disabled for now)*/}
                  {/* <CustomiserUnit title="Text above gift code">
                    <Textfield
                      value={props.values.gift_offer_share_code_title}
                      onChange={(e) => {
                        props.setFieldValue(
                          "gift_offer_share_code_title",
                          e.target.value
                        );
                      }}
                      name="basic"
                    />
                  </CustomiserUnit> */}

                  {/* Text on Copy Button*/}
                  <CustomiserUnit title="Text on Copy Button">
                    <Textfield
                      value={props.values.copy}
                      onChange={(e) => {
                        props.setFieldValue("copy", e.target.value);
                      }}
                      name="basic"
                    />
                  </CustomiserUnit>

                  {/* Other share options button */}
                  <CustomiserUnit title="Other share options button ">
                    <Textfield
                      value={props.values.other_share_options_text}
                      onChange={(e) => {
                        props.setFieldValue(
                          "other_share_options_text",
                          e.target.value
                        );
                      }}
                      name="basic"
                    />
                  </CustomiserUnit>

                  {/* Facebook share button  */}
                  <CustomiserUnit title="Facebook share button">
                    <Textfield
                      value={props.values.share_on_facebook_text}
                      onChange={(e) => {
                        props.setFieldValue(
                          "share_on_facebook_text",
                          e.target.value
                        );
                      }}
                      name="basic"
                    />
                  </CustomiserUnit>

                  {/* Twitter share button */}
                  <CustomiserUnit title="Twitter share button">
                    <Textfield
                      value={props.values.share_on_twitter_text}
                      onChange={(e) => {
                        props.setFieldValue(
                          "share_on_twitter_text",
                          e.target.value
                        );
                      }}
                      name="basic"
                    />
                  </CustomiserUnit>

                  {/* Whatsapp share button */}
                  <CustomiserUnit title="Whatsapp share button">
                    <Textfield
                      value={props.values.share_on_whatsapp_text}
                      onChange={(e) => {
                        props.setFieldValue(
                          "share_on_whatsapp_text",
                          e.target.value
                        );
                      }}
                      name="basic"
                    />
                  </CustomiserUnit>

                  {/* offer terms button */}
                  <CustomiserUnit title="Learn how this works button">
                    <Textfield
                      value={props.values.offer_terms}
                      onChange={(e) => {
                        props.setFieldValue("offer_terms", e.target.value);
                      }}
                      name="basic"
                    />
                  </CustomiserUnit>
                </div>
              )}
            </div>
          </div>
        )}
      </Formik>
    );
  else return <LoadingSpinner />;
}
