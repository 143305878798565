import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { queryWorkspaceProps } from "../api/workspace";
import { useGraphQl } from "./GraphqlClient";
import { useAuth } from "./AuthenticationContext";
import Cookies from "js-cookie";

/* WorkspaceContext's primary responsibility is to fetch the intial
 * data needed to render the workspce like feature flag, account, source and user ids.
 * And provide this global context to all the branches in the app tree
 * App will not even render without this data.
 */

const defaultWorkspaceProps = {
  isAuthorised: false,
  status: "loading",
  data: {},
};
const WorkspaceContext = React.createContext(defaultWorkspaceProps);

const WorkspaceProvider = (props) => {
  const graphQL = useGraphQl();
  const authState = useAuth();

  const [currentAppID, setCurrentAppID] = useState();
  const [installedApps, setInstalledApps] = useState([]);

  const urlParams = new URLSearchParams(window.location.search);
  const { isLoading, isError, data, error, refetch } = useQuery(
    "queryWorkspaceProps",
    () => {
      return graphQL(queryWorkspaceProps);
    },
    { enabled: authState.token ? true : false }
  );

  const changeCurrentApp = (appId) => {
    setCurrentAppID(appId);
    Cookies.set("currentAppId", appId);
  };

  // This use effect initialize which os the current app and also which all app use have installed
  useEffect(() => {
    let appId = parseInt(urlParams.get("app_id"), 10);
    if (data && data.installations) {
      setInstalledApps(
        data.installations.filter((app) => (app.status = "installed"))
      );
      if (appId) {
        changeCurrentApp(appId);
      } else if (Cookies.get("currentAppId")) {
        setCurrentAppID(parseInt(Cookies.get("currentAppId"), 10));
      } else {
        setCurrentAppID(
          data.installations.filter((app) => (app.status = "installed"))[0]
            .appid
        );
        Cookies.set(
          "currentAppId",
          data.installations.filter((app) => (app.status = "installed"))[0]
            .appid
        );
      }
    }
  }, [data]);

  if (isError) {
    window.Rollbar.error("Error in WorkspaceContext query", error);
  }

  let wsdata = {};

  if (data ) {
    const [workspace] = data.workspaces;
    wsdata = {
      data: {
        accountid: workspace.accountid,
        userId: workspace.userid,
        feature_flag: data.feature_access[0].feature_flag,
        sourceid: workspace.sourceid,
        storeUrl: workspace.source.source_meta.domain,
        suco_onboarding_progress: workspace.source.onboarding_progress,
        ofco_onboarding_progress: workspace.source.offer_onboarding,
        coca_onboarding_progress: workspace.source.cornercart_onboarding,
        user: workspace.user,
        currentAppID: currentAppID,
        changeCurrentAppID: changeCurrentApp,
        installedApps: installedApps,
        default_language: workspace.source.default_language,
        storeCurrency: workspace.source.source_meta.currency,
        currencyFormat:workspace.source.currency_format,
        storeLocale: workspace.source.source_meta.locale,
        storeName: workspace.source.source_meta.shopName,
        storeMyShopifyUrl: workspace.source.source_domain,
        allAvailablePlans: data.billing_plans,
        otherDetails: workspace.source.source_details,
      },
      refreshWorkspace: refetch
    };
  }
  return <WorkspaceContext.Provider value={wsdata} {...props} />;
};

const useWorkspace = () => React.useContext(WorkspaceContext);

export { WorkspaceProvider, useWorkspace };
