import React, { useCallback, useState } from "react";
import { RadioGroup } from "@atlaskit/radio";
import ColorPicker from "./ColorPicker";

const GradientPicker = (props) => {
  const [gradientToggle, setGradientToggle] = useState(
    props.startColor === props.endColor ? "solid" : "gradient"
  );

  const convertRGB = (rgb) => `rgb(${rgb.r},${rgb.g},${rgb.b})`;

  const options = [
    { name: "colorType", value: "solid", label: "Solid" },
    { name: "colorType", value: "gradient", label: "Gradient" },
  ];

  const GradientButton = (btnProps) => {
    return (
      <button
        onClick={() => {
          props.onChange(btnProps.startColor,btnProps.endColor)
          // props.onEndColorChange(btnProps.endColor);
          // props.onStartColorChange(btnProps.startColor);
        }}
        style={{
          background: `linear-gradient(180deg, ${btnProps.startColor} 0%, ${btnProps.endColor} 100%)`,
        }}
        className="rounded-full w-5 h-5 desktop:w-6 desktop:h-6 bg-amber-300"
      />
    );
  };

  return (
    <div className="py-2 desktop:py-3 border rounded-lg">
      <div className="flex items-center justify-center">
        <RadioGroup
          defaultValue={gradientToggle}
          options={options}
          onChange={useCallback((event) => {
            if (event.currentTarget.value === "solid") {
              props.onChange(props.endColor,props.endColor)
              // props.onStartColorChange(props.endColor);
              // props.onEndColorChange(props.endColor);
            }
            setGradientToggle(event.currentTarget.value);
          }, [])}
        />
      </div>
      {gradientToggle === "solid" ? (
        <div className="flex items-center py-2 desktop:py-3 justify-center ">
          <ColorPicker
            color={props.endColor}
            onChange={(color) => {
              props.onChange(convertRGB(color.rgb),convertRGB(color.rgb))
              // props.onStartColorChange(convertRGB(color.rgb));
              // props.onEndColorChange(convertRGB(color.rgb));
            }}
          />
          <small className="ml-3">Pick a Color</small>
        </div>
      ) : (
        <div>
          <div className="flex py-2 desktop:py-3 justify-evenly ">
            <div className="flex mr-3 items-center">
              <ColorPicker
                color={props.startColor}
                onChange={(color) => {
                  props.onChange(convertRGB(color.rgb),props.endColor)
                }}
              />
              <small className="mx-3">Start Color</small>
            </div>
            <div className="flex items-center">
              <ColorPicker
                color={props.endColor}
                onChange={(color) => {
                  props.onChange(props.startColor,convertRGB(color.rgb))
                }}
              />
              <small className="ml-3">End Color</small>
            </div>
          </div>
          <div className="flex items-center justify-evenly border-t pt-2 desktop:pt-4">
            <GradientButton
              startColor="rgb(252, 190, 3)"
              endColor="rgb(252, 78, 3)"
            />
            <GradientButton
              startColor="rgb(62,161,219)"
              endColor="rgb(93,52,236)"
            />
            <GradientButton
              startColor="rgb(96,221,142)"
              endColor="rgb(24,138,141)"
            />
            <GradientButton
              startColor="rgb(0,84,166)"
              endColor="rgb(132,189,201)"
            />
            <GradientButton
              startColor="rgb(182,255,139)"
              endColor="rgb(8,88,127)"
            />
            <GradientButton
              startColor="rgb(255,206,149)"
              endColor="rgb(247,92,92)"
            />
            <GradientButton
              startColor="rgb(249,185,255)"
              endColor="rgb(98,203,255)"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default GradientPicker;
