import React from "react";
import { RiArrowLeftLine } from "react-icons/ri";
import { useHistory } from "react-router-dom";
import { useAppBridge } from "@shopify/app-bridge-react";

/* interface Props { */
/*   title: string; */
/*   customBackButton: React.ReactNode; */
/*   action?: React.ReactNode; */
/*   children: React.ReactNode; */
const PageViewContainer = ({ title, action, children, customBackButton }) => {
  const app = useAppBridge();
  let history = useHistory();
  return (
    <div className="flex flex-col h-full min-w-0 grow">
      {/*  <div className="grid grid-rows-shrinked-page-row desktop:grid-rows-page-row grid-cols-page-col  "> */}
      <div className="flex shrink-0 items-center justify-between mx-3 h-20 desktop:h-24">
        <div className="flex flex-none items-center">
          {customBackButton || (
            <button
              onClick={() => history.goBack()}
              className="grow-0 bg-white p-2 desktop:p-3  mr-3 border  text-violet-700 hover:border-violet-700 transition-all rounded-lg"
            >
              <RiArrowLeftLine />
            </button>
          )}
          <div className="">
            <h1 className="font-extrabold text-gray-700 leading-5 desktop:leading-6 text-xl desktop:text-2xl">
              {title}
            </h1>
          </div>
        </div>
        {action && <div>{action}</div>}
      </div>
      <div className="grow h-2 bg-white mx-3 mb-3 rounded-lg shadow-md grow overflow-hidden">
        {children}
      </div>
    </div>
  );
};
export default PageViewContainer;
